
var defaultState = {
  exams: {
    completed: false,
    list: []
  },
  subjects: {
    completed: false,
    list: []
  },
  organizations: {
    completed: false,
    list: []
  },
  countryCodes: {
    completed: false,
    lst: []
  },
  productSubTypes: {
    completed: false,
    list: []
  }
}

export default function config (state = defaultState, action) {
  switch (action.type) {
    case 'FETCH_CONFIGS':
      const list = action.data
      if (action.key === 'exams') {
        const subjects = state.subjects.list
        list.forEach(exam => {
          exam.subjects = subjects.filter(i => i.examinationId === exam.id)
        })
      }
      return Object.assign({}, state, {
        [action.key]: {
          completed: action.completed,
          list
        }
      })
  }

  return state
}
