import io from 'socket.io-client'
import * as socketHelper from 'helpers/socket'

const { WEBSOCKET_PASSWORD, WEBSOCKET_URL } = process.env
const socket = io(
  `${WEBSOCKET_URL}/apollon`,
  {
    query: `role=client&password=${WEBSOCKET_PASSWORD}`,
    path: '/websocket'
  }
)
socket.on('connect', () => {
  socketHelper.updateSocketAuthorization()
})

socket.on('reconnect', () => {
  socketHelper.updateSocketAuthorization()
})

export default socket
