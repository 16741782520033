
import { combineReducers, Reducer } from 'redux'

import common from './common'
import platform from './platform'
import student from './student'
import school from './school'
import config from './config'
import user from './user'
import profile from './profile'
import permission from './permission'
import message from './message'
import version from './version'
import timeSlots from './time_slots'

const rootReducer: Reducer<any> = combineReducers({
  platform,
  school,
  common,
  config,
  user,
  profile,
  permission,
  student,
  message,
  version,
  timeSlots
})
export default rootReducer
