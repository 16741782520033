import * as React from 'react'

import Radio from './radio'
const createReactClass = require('create-react-class')

// 组件
const CheckBox = createReactClass({
  // 获取初始状态
  getInitialState () {
    return {
      values: (this.props.defaultValue && this.props.defaultValue.join(',').split(',')) || []
    }
  },
  UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    if (this.props.defaultValue != nextProps.defaultValue) {
      this.setState({
        values: nextProps.defaultValue.join(',').split(',')
      })
    }
  },

  // 渲染
  render () {
    let self = this
    let options = this.props.options
    if (!this.props.max) {
      return (
        <div
          className='component-common-form-checkbox'
        >
          {Object.keys(options).map(function (key) {
            if (!key || parseInt(key) === 0) return ''
            return (
              <Radio
                key={key}
                title={options[key]}
                value={key}
                active={self.state.values.indexOf(key) !== -1}
                select={self.select(key)}
              />
            )
          })}
        </div>
      )
    }
    let cnt = 0
    let rows = []
    let radios = []

    for (let key in options) {
      if (!key || parseInt(key) === 0) continue

      radios.push(
        <Radio
          key={key}
          title={options[key]}
          value={key}
          active={self.state.values.indexOf(key) !== -1}
          select={self.select(key)}
        />
      )

      if (cnt % this.props.max === this.props.max - 1) {
        rows.push(<div key={cnt} className='row'>{radios}</div>)
        radios = []
      }

      cnt++
    }

    if (radios.length !== 0) {
      rows.push(<div key={cnt} className='row'>{radios}</div>)
    }

    return (<div className='component-common-form-checkbox'>{rows}</div>)
  },

  // 选择元素
  select (key) {
    let self = this
    if (this.props.readOnly) return null
    return function () {
      if (self.state.values.indexOf(key) === -1) {
        self.state.values.push(key)
        self.setState({
          values: self.state.values
        })
      } else {
        self.state.values.splice(self.state.values.indexOf(key), 1)
        self.setState({
          values: self.state.values
        })
      }
    }
  },

  // 获取值
  getValue () {
    return this.state.values.join(',')
  }
})

export default CheckBox
