import * as utils from 'common/utils'
import * as React from 'react'
import { Modal, Input, Checkbox, Row, Col, Button } from 'antd'
import ControlledTextarea from 'components/common/controlled_textarea'

const redTextStyle = {
  color: 'red'
}

class Feedback extends React.Component<any, any> {
  state = {
    textNum: 0,
    error: '',
    contactInfo: this.props.userAccount,
    detail: '',
    clicked: false
  }

  handleClose = () => {
    //
    this.props.onClose()
  }

  handleSubmit = async () => {
    const { error, detail, contactInfo } = this.state
    const { currentTime } = this.props
    this.setState({
      clicked: true
    })
    if (!error) {
      return false
    }
    await this.props.onSubmit(error, detail, contactInfo, String(currentTime))
    this.setState({
      clicked: false
    })
  }

  handleChooseError = async values => {
    const sortedValues = values.sort()
    let str = ''
    for (let i in sortedValues) {
      str += `${Number(i) == 0 ? '' : ','}${sortedValues[i]}`
    }
    this.setState({
      error: str
    })
  }

  handleInputError = e => {
    if (e.target.value.length > 120) {
      return false
    }
    this.setState({
      detail: e.target.value,
      textNum: e.target.value.length
    })
  }

  render () {
    const { visible, title, currentTime } = this.props
    const { contactInfo, detail, error, clicked, textNum } = this.state
    return (
      <Modal
        visible={visible}
        title='我要反馈问题'
        wrapClassName='online-feedback-modal single-btn-modal'
        okText='提交反馈'
        onOk={this.handleSubmit}
        onCancel={() => this.props.onClose()}
        footer={
          <div className='footer'>
            <div className='btn-container'>
              <Button onClick={this.handleSubmit} type='primary'>提交反馈</Button>
            </div>
            { !error && clicked ? <p className='err-msg'>请选择错误类型</p> : null }
          </div>
        }
      >
        <p className='title'>
          问题描述
        </p>
        <p className='info'>
          {`${title}  ${utils.machineTime(currentTime)}`}
        </p>
        <p className='title'>
          错误类型
          <span style={redTextStyle}>*</span>
        </p>
        <Checkbox.Group onChange={this.handleChooseError}>
          <Row>
            <Col span={5}><Checkbox value='1'>quiz错误</Checkbox></Col>
            <Col span={5}><Checkbox value='2'>字幕错误</Checkbox></Col>
            <Col span={6}><Checkbox value='3'>视频内容缺失</Checkbox></Col>
            <Col span={6}><Checkbox value='4'>老师讲解错误</Checkbox></Col>
            <Col span={4}><Checkbox value='5'>其它</Checkbox></Col>
          </Row>
        </Checkbox.Group>
        <ControlledTextarea
          placeholder='请输入您要反馈的问题'
          limit={120}
          currentNum={textNum}
          onChange={this.handleInputError}
          onFocus={() => {}}
          content={detail}
        />
        <p className='title'>
          联系方式
        </p>
        <div className=''>
          <Input
            value={contactInfo}
            onChange={e => {
              this.setState({
                contactInfo: e.target.value
              })
            }}
          />
          <span>留下您的联系方式（手机，邮箱等）以便我们通知处理结果</span>
        </div>
      </Modal>
    )
  }
}

export default Feedback
