import * as utils from 'common/utils'
import config from 'common/config'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import * as socketHelper from 'helpers/socket'
import Dipper from 'components/common/dipper'
import * as rest from 'common/rest'
import constants from 'common/constants'
import { fetch as userFetch } from 'actions/user'
import { fetchProfile } from 'actions/profile'
import { fetchOrganization } from 'actions/organization'
import Loading from 'components/common/loading'
import AreaCodeSelect from 'components/common/area_code_input'
import { message, Icon as OrigIcon, Radio, Input, Button } from 'antd'
import * as _ from 'lodash'
import OrganizationPicker from 'components/common/organization_picker'
import LanguageChange from 'components/common/language_change'
import intl from 'react-intl-universal'
import { loginApi } from '../apis/user'

const RadioGroup = Radio.Group
const Icon: any = OrigIcon

class Home extends React.Component<any, any> {
  phone: any

  captcha = React.createRef<any>()
  password = React.createRef<any>()
  forgetPswd = React.createRef<any>()
  _reactInternalInstance: any

  userTypeId = utils.getUserTypeId() || 1
  student = this.props.location.pathname === '/' || this.props.location.pathname === '/student'
  teacher = this.props.location.pathname === '/teacher'
  admin = this.props.location.pathname === '/admin'
  others = this.props.location.pathname === '/uskid' || this.props.location.pathname === '/liuxue' || this.props.location.pathname === '/uskid2018'
  data = [
    'Learning is the eye of the mind.',
    'God helps those who help themselves.',
    'One for all, all for one.',
    'The way to learn a language is to practice speaking it as often as possible.',
    "If you don't learn to think when you are young, you may never learn.",
    'No success in life merely happens.',
    'All things are difficult before they are easy.',
    "Learning any language takes a lot of effort，but don't give up.",
    'Nothing is impossible for a willing heart.',
    "Rome wasn't built in a day，work harder and practice more.",
    'Follow your own course，and let people talk.',
    'Never put off until tomorrow what may be done today.',
    'Adversity reveals genius，fortune conceals it.',
    'Without hard work，nothing grows but weeds.',
    'The most important kind of freedom is to be what you really are.'
  ]

  state = {
    clock: null,
    loging: false,
    loged: false,
    siteId: 0,
    userToken: null,
    role: parseInt(localStorage.getItem('apol_role')) || 1,
    studentOrgLists: [],
    studentOrgShow: false,
    phone: '',
    password: '',
    studentLoading: false,
    insideOrg: this.props.organizations.find(org => org.domain === document.domain.split('.')[0]) || null,
    insideRole: sessionStorage.getItem('inside_auth') === 'teacher' ? 1 : 2,
    insideUser: '',
    insidePassword: ''
  }

  constructor (props) {
    super(props)
    // 这部分是判断inside站点url是否合法
    const domain = document.domain.split('.')[0]
    const insideOrg = props.organizations.find(org => org.domain === domain) || null
    if (global.IS_INSIDE && !insideOrg && props.location.pathname !== '/admin') {
      message.error('请输入正确的站点域名')
    }
  }

  componentDidMount () {
    const orgs = this.getOrganizations()
    const siteId = _.get(orgs, [0, 'id']) || 0
    this.setState({ siteId })
    // 如果是从定向到当前页，则替换路由记录
    if (['', '/', '/liuxue', '/uskid', '/uskid2018', '/teacher', '/admin', '/student'].indexOf(location.pathname) < 0) {
      return utils.redirect('/', true)
    }

    // 如果已经登录过，则直接进入系统
    if (utils.isLogined()) {
      const userType = utils.getUserTypeId()
      if (userType === 3) {
        utils.redirect(`/platform/${global.IS_INSIDE ? 'clients' : 'organizations'}`)
      } else {
        this.fetchOrganizationInfo()
        this.redirect()()
      }
    }
    if (this.admin) {
      utils.setLocalStorage('org', 0)
      this.setState({ siteId: 0 })
    }
  }

  componentDidUpdate () {
    let input = this.captcha.current
    input && input.focus()
  }

  componentWillUnmount () {
    sessionStorage.removeItem('inside_auth')
  }

  getOrganizations () {
    let organization = []
    if (window.location.pathname == '/liuxue') {
      organization = this.props.organizations.filter(org => org.statusId !== 2 && org.business == 3)
    } else if (window.location.pathname == '/uskid') {
      organization = this.props.organizations.filter(org => org.statusId !== 2 && org.business == 2)
    } else if (window.location.pathname == '/uskid2018') {
      organization = this.props.organizations.filter(org => org.statusId !== 2 && org.business == 4)
    } else {
      organization = this.props.organizations.filter(org => org.statusId !== 2 && org.business != 2 && org.business != 3 && org.business != 4)
    }
    return organization
  }

  isNeedCaptcha () {
    var org = this.getOrganizations().find(item => {
      return item.id === this.state.siteId
    })
    return !org || org.useCaptcha !== 0
  }

  handleOrgChange = val => {
    utils.setLocalStorage('org', val)
    this.setState({ siteId: val })
  }

  handleRoleChange = (e) => {
    localStorage.setItem('apol_role', e.target.value)
    this.setState({ role: e.target.value - 0 })
  }

  forgetPassword =async () => {
    this.forgetPswd.current.setAttribute('href', `${config.url.www}/find?smartRedirect=${encodeURIComponent(location.href)}`)
  }

  fetchOrganizationInfo = () => {
    if (+this.userTypeId !== constants.USER_TYPE.ADMIN
    ) {
      this.props.dispatch(fetchOrganization())
    } else {
      return Promise.resolve('')
    }
  }

  showAllOrg = async () => {
    // TODO: 获取机构列表并展示
    const phone = this.phone.getValue()
    const password = this.password.current.value
    if (!phone || phone == 'emptyNum') return message.error('请输入您的账号')
    this.setState({
      loading: true
    })

    const params = {
      phone,
      password,
      role: this.student ? 2 : 1,
      from: 2,
    }
    const res = await loginApi(params)

    if (res.code) return message.error({ text: '登录失败', msg: res.msg })
    if (!this.teacher) {
      await this.fetchOrganizationInfo()
    }
    if (!res.data.organization || res.data.organization.length < 2) {
      this.setState({
        phone,
        password,
        userToken: res.data.userToken
      })
      await this.loginProcess(res, phone, password)
      return
    }
    this.setState({
      studentOrgLists: res.data.organization,
      studentOrgShow: true,
      phone,
      password,
      loading: false,
      userToken: res.data.userToken
    })
  }

  selectOrg = async (id) => {
    this.setState({
      // TODO: 获取选中机构ID并保存在state
      siteId: id,
      studentLoading: true,
      role: 2 // 在/student模式下默认学生的state
    }, this.login)
  }

  loginProcess = (res, phone, password) => {
    if (res.data && res.data.account) {
      utils.setLocalStorage('userAccount', res.data.account)
    }
    const userTypeId = utils.getUserTypeId()
    this.props.changeParentState({userTypeId})
    if (userTypeId === 1) {
      this.props.dispatch(userFetch())
      if (utils.isOnline()) {
        // 非在线站点考虑一下要不要清掉数据
        const id = utils.getUserId()
        this.props.dispatch(fetchProfile(id))
      }
      return this.redirect()()
    }
    if (this.admin) {
      this.setState({
        phone,
        password,
        loged: true,
        clock: 30,
        studentLoading: false
      })

      setTimeout(() => {
        this.sendCaptcha()
        this.tick()
      }, 1000)
    } else {
      this.fetchOrganizationInfo()
      this.onChecked(res.data)
    }
  }

  backInput = async () => {
    this.setState({
      studentOrgShow: false
    })
  }

  changeUrl = async () => {
    const origin = window.location.origin
    if (this.teacher) {
      window.open(origin, '_self')
    } else {
      window.open(`${origin}/teacher`, '_self')
    }
  }

  render () {
    // 检查数据是否完成
    if (!utils.isCompleted(this.props)) return <Loading />
    return <div style={{ height: '100%' }}>
      { this.renderBody() }
    </div>
  }

  renderCaptcha () {
    return (
      <div className='container-home'>
        <Dipper />
        <div
          className='wrapper'
          style={{ marginTop: -(document.body.offsetHeight - 400) * 0.2, zIndex: 10 }}
        >
          <img className='logo' src={require('images/logoSign.png')} />
          <div className='form'>
            <input
              ref={this.captcha}
              type='text'
              placeholder='手机验证码'
              onKeyDown={this.keyDownCaptcha}
            />
            <div
              className='btn'
              style={this.state.clock ? { background: '#aaa' } : {}}
            >
              {this.state.clock ? `${this.state.clock}秒后` : ''}重新发送
              <span className='fa fa-arrow-circle-right' />
            </div>
            <div className='btn' onClick={this.checkCaptcha}>
              确认登录
              <span className='fa fa-arrow-circle-right' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSigninForm () {
    // 获取登录平台选项
    var orgs = this.getOrganizations()
    let siteId = _.get(orgs, [0, 'id'])
    orgs.forEach(i => {
      if (i.id == this.state.siteId) {
        siteId = this.state.siteId
      }
    })
    return <div className={`form ${this.student ? 'student-form' : this.teacher || this.admin ? 'teacher-form' : ''}`}>
      {
        this.student || this.teacher
          ? <span className='login-text'>密码登录</span>
          : ''
      }
      <div className='input-collection'>
        {
          this.others
            ? <div
              style={{ display: this.student || this.teacher ? 'none' : 'block' }}
              className='container-home-org-select'
            >
              <OrganizationPicker
                style={{ width: '100%', height: 50 }}
                defaultValue={siteId}
                onChange={this.handleOrgChange}
                className='organization-picker'
                organizations={orgs}
                value={siteId}
                required
              />
            </div>
            : ''
        }
        <AreaCodeSelect noOnChange noBorder isIndex ref={(ref) => (this.phone = ref)} onKeyDown={this.keyDown} codes={this.props.codes} />
        <div className={`password-container ${this.others ? 'otehrs-password' : ''}`}><input ref={this.password} type='password' placeholder='密码' onKeyDown={this.keyDown} autoComplete='new-password' /></div>
        {
          this.others
            ? <div
              style={{ display: this.student || this.teacher ? 'none' : 'block' }}
              className='container-home-role-select'
            >
              <RadioGroup value={this.state.role} onChange={this.handleRoleChange} size='large'>
                <Radio value={1}><span className={this.state.role === 1 ? 'active' : ''}>教师</span></Radio>
                <Radio value={2}><span className={this.state.role === 2 ? 'active' : ''}>学员</span></Radio>
              </RadioGroup>
            </div> : ''
        }
      </div>
      <span className='btn' onClick={this.teacher || this.student ? this.showAllOrg : this.login}>
        {this.state.loging ? '登录中...' : '登录'}
        <span className='fa fa-arrow-circle-right' />
      </span>
      <a className='forget-pswd' onClick={() => this.forgetPassword()} ref={this.forgetPswd} target='_blank'>
        忘记密码?
      </a>
    </div>
  }

  renderSelectOrganizationForm () {
    const { studentLoading, studentOrgLists } = this.state
    return <div className={`form ${this.student ? 'student-form' : this.teacher || this.admin ? 'teacher-form' : ''}`}>
      <p>
        <span className='login-text'>选择学习中心</span>
        <span onClick={this.backInput} className='back-input'>返回</span>
      </p>
      <div className='orgs'>
        {
          studentOrgLists.map(item => (
            <p key={item.organizationId} onClick={() => this.selectOrg(item.organizationId)} className='org-list'>
              <span className='org-build' />
              <span>{utils.isOnline(item.organizationId) ? '智课网学习中心' : item.organizationName}</span>
              <Icon type='right' theme='outlined' />
            </p>
          ))
        }
      </div>
      {
        studentLoading ? <div className='student-loading'><Icon type='loading' theme='outlined' /></div> : ''
      }
    </div>
  }

  renderFooter () {
    return this.student || this.teacher
      ? <footer className='student-container-footer'>
        <span>@2011~2019 北京创新伙伴科技有限公司|<a href="https://beian.miit.gov.cn/" target="_blank">{ !global.IS_INSIDE ? '京ICP备13047080号' : '京ICP备13047080号-11' }</a>|<img style={{ backgroundImage: 'none' }} src='//media8.smartstudy.com/zhikewang/images/beian.png' />{ !global.IS_INSIDE ? '京公网安备11010802028010号' : '京公网安备11010802029497号' }</span>
      </footer>
      : ''
  }

  handleChangeRole = role => {
    this.setState({
      insideRole: role
    })
  }

  handleInsideSubmit = async () => {
    const { insideUser, insidePassword, insideRole, insideOrg } = this.state
    if (!insideUser) {
      return message.error('请填写您的账号')
    }
    if (!insidePassword) {
      return message.error('请填写您的密码')
    }
    if (this.state.loging) {
      return
    }
    this.setState({ loging: true })

    utils.setCookie('userId', '', 0, '', '')
    utils.setCookie('userTypeId', '', 0, '', '')
    utils.setCookie('originUserId', '', 0, '', '')
    utils.setCookie('token', '', 0, '', '')

    let data = {
      siteId: insideOrg.id,
      role: insideRole,
      phone: insideUser,
      password: insidePassword,
      from: 2
    }
    const res = await loginApi(data)
    if (res.code !== 0) {
      message.error({ text: '登录失败', msg: res.msg })
      return this.setState({ loging: false })
    }
    await this.fetchOrganizationInfo()
    await this.loginProcess(res, insideUser, insidePassword)
  }

  renderInsideInput () {
    const { insideRole } = this.state
    const isTeacher = insideRole === 1
    return (
      <div className='input-container'>
        <p>{isTeacher ? intl.get('passwordLogin') : '密码登录'}</p>
        <div className='user-type-choose'>
          <div className={insideRole === 1 ? 'active' : ''} onClick={() => this.handleChangeRole(1)}>
            <img src={require('images/teacher-login-avatar.png')} alt='' />
            <span>{isTeacher ? intl.get('teacher') : '我是老师'}</span>
          </div>
          <div className={insideRole === 2 ? 'active' : ''} onClick={() => this.handleChangeRole(2)}>
            <img src={require('images/student-login-avatar.png')} alt='' />
            <span>{isTeacher ? intl.get('student') : '我是学生'}</span>
          </div>
        </div>
        <div className='inputs'>
          <Input autoComplete='new-password' onChange={event => this.setState({ insideUser: event.target.value })} type='text' placeholder={isTeacher ? intl.get('loginPlaceholder') : '学号/手机号码/电子邮箱'} />
          <Input autoComplete='new-password' onChange={event => this.setState({ insidePassword: event.target.value })} type='password' placeholder={isTeacher ? intl.get('password') : '密码'} />
        </div>
        <div className='buttons'>
          <Button onClick={this.handleInsideSubmit}>{isTeacher ? intl.get('login') : '登录'}</Button>
        </div>
      </div>
    )
  }

  renderBody () {
    const { studentOrgShow, insideOrg, insideRole } = this.state
    const isTeacher = insideRole === 1
    // 选择验证码
    if (this.state.loged && this.isNeedCaptcha()) return this.renderCaptcha()
    let logoUrl = _.get(insideOrg, ['logoBig', 'url']) || require('images/logo_zhike.png')
    let bgPicture = _.get(insideOrg, ['bgPicture', 'url'])
    return (
      <div style={{ height: '100%' }}>
        {
          insideOrg || this.student || this.teacher
            ? <header className='student-container-header'>
              { insideOrg &&
                <Fragment>
                  <img className='inside-logo' src={logoUrl} alt='' />
                  <span className='line' />
                  <span className='inside-name'>{isTeacher ? intl.get('smartTitle') : 'SMART智能学习系统'}</span>
                  { insideRole === 1 && <LanguageChange /> }
                  <span className='site-name'>{isTeacher ? intl.get('currentSite') : '当前所在站点'}：{insideOrg.name}</span>
                </Fragment>
              }
              { !insideOrg &&
                <Fragment>
                  <span className='logo' />
                  <a href={`${config.url.www}`} target='_blank' rel='noopener noreferrer'>进入智课网</a>
                  <a className='change-url' onClick={this.changeUrl}>{this.teacher ? '学生登录' : '教师登录'}</a>
                </Fragment>
              }
            </header>
            : ''
        }
        {
          insideOrg ? <div className={`container-home inside-container-home ${bgPicture ? 'with-background-image' : ''}`}>
            { bgPicture && <div className='image-wrapper'><img className='inside-bg-img' src={bgPicture} /></div> }
            <div className={`wrapper ${bgPicture ? 'width-background-wrapper' : ''}`}>
              { !bgPicture && <img src={require('images/inside-home-paint.png')} alt='' />}
              { this.renderInsideInput() }
            </div>
          </div> : <div className={`container-home ${this.student ? 'student-container-home' : this.teacher ? 'teacher-container-home' : ''}`}>
            { !this.student && !this.teacher ? <Dipper /> : '' }
            <div
              className='wrapper'
              style={{ marginTop: !this.student && !this.teacher ? -(document.body.offsetHeight - 400) * 0.2 : 48, zIndex: 10 }}
            >
              { !this.student && !this.teacher ? <img className='logo' src={require('images/sign-logo.png')} /> : '' }
              {
                this.student && !insideOrg
                  ? <div className='english-text'>
                    <span className='quotation-mark' />
                    <div className='text'>{this.data[Math.floor(15 * Math.random())]}</div>
                  </div>
                  : ''
              }
              { !studentOrgShow ? this.renderSigninForm() : this.renderSelectOrganizationForm() }
              { !this.student && !this.teacher ? <div className='logoBottom' /> : '' }
            </div>
          </div>
        }
        {this.renderFooter()}
      </div>
    )
  }

  // 键入
  keyDown = e => {
    if (e.keyCode == 13) {
      if (this.student || this.teacher) {
        this.showAllOrg()
      } else {
        this.login()
      }
    }
  }

  // 键入
  keyDownCaptcha = e => {
    e.keyCode === 13 && this.checkCaptcha()
  }

  tick () {
    if (!this._reactInternalInstance) return
    if (this.state.clock === 1) return this.setState({ clock: 0 })
    this.setState({ clock: this.state.clock - 1 })

    const self = this
    setTimeout(function () {
      self.tick()
    }, 1000)
  }

  onChecked (data) {
    if (utils.isTeacher()) {
      // 写权限信息
      utils.setLocalStorage('permissionModules', data.permissionModules)
      utils.setLocalStorage('permissionOperations', data.permissionOperations)
      utils.setLocalStorage('visibleOrganizations', data.visibleOrganizations)
      utils.setLocalStorage('isTeacherSupervisor', !!data.isTeacherSupervisor)
      utils.setCookie('crossTeacherVisible', data.crossTeacherVisible ? 1 : 0, -1, '', '')

      this.props.dispatch(userFetch())

      this.redirect()()
    } else {
      // 平台层管理员
      if (data && data.account) {
        const userTypeId = utils.getUserTypeId()
        this.props.changeParentState({userTypeId})
        utils.setLocalStorage('userAccount', data.account)
      }
      if (data && data.business) {
        utils.setLocalStorage('business', data.business)
      }
      utils.redirect(`/platform/${global.IS_INSIDE ? 'clients' : 'organizations'}`)
    }
  }
  // 登录
  login = async () => {
    // const self = this
    const phone = this.student || this.teacher ? this.state.phone : this.phone.getValue()
    const password = this.student || this.teacher ? this.state.password : this.password.current.value

    if (phone === 'invalidNum') {
      message.error('请检查手机号中是否有非法字符')
      return this.setState({ login: false })
    }
    if (phone === 'emptyNum') {
      message.error('请检查手机号是否为空')
      return this.setState({ login: false })
    }

    if (this.state.loging) return
    this.setState({ loging: true })

    utils.setCookie('userId', '', 0, '', '')
    utils.setCookie('userTypeId', '', 0, '', '')
    utils.setCookie('originUserId', '', 0, '', '')
    utils.setCookie('token', '', 0, '', '')
    let data: any = {
      siteId: this.state.siteId,
      role: this.student ? 2 : 1
    }
    if (this.student || this.teacher) {
      data.userToken = this.state.userToken
      data.from = 2
    } else {
      data.phone = phone
      data.password = password
      data.role = 1
      data.siteId = this.admin ? 0 : this.state.siteId
    }
    const res = await loginApi(data)
    if (res.code) {
      message.error({ text: '登录失败', msg: res.msg })
      return this.setState({ loging: false })
    }
    await this.loginProcess(res, phone, password)
  }

  sendCaptcha () {
    const phone = this.state.phone
    rest.post('/api/user/sms', { phone })
  }

  resendCaptcha = () => {
    if (this.state.clock) return
    this.setState({ clock: 60 })
    setTimeout(() => {
      this.sendCaptcha()
      this.tick()
    }, 1000)
  }

  // 校验验证码
  checkCaptcha = async () => {
    const params = {
      siteId: this.state.siteId,
      phone: this.state.phone,
      password: this.state.password,
      role: this.state.role,
      captcha: this.captcha.current.value || ''
    }
    const res = await loginApi(params)
    if (!this.captcha.current.value) return message.error('验证码不能为空')
    if (res.code) return message.error({ text: '校验码生成失败', msg: res.msg })
    this.onChecked(res.data)
  }

  // 切换系统
  redirect = (type?: any) => () => {
    if (utils.isLogined()) {
      const redirecturl = this.props.location.query.redirecturl
      if (redirecturl) {
        if (redirecturl.startsWith('/')) {
          return utils.redirect(redirecturl)
        }
        return (location.href = this.props.location.query.redirecturl)
      }
    }
    if (type === 'crm') {
      location.href = `${config.url.crm}`
    } else {
      socketHelper.updateSocketAuthorization()
      let modules = utils.getModules()
      let userTypeId = utils.getUserTypeId()
      let isOnline = utils.isOnline()

      let redirectModule
      if (userTypeId === 1) {
        if (isOnline) {
          modules = utils.getStudentPermissionModules()
          redirectModule = modules.find(el => el.key === 'study') || modules[0]
        } else {
          redirectModule = modules.find(el => el.url === 'tasks')
        }
        return utils.redirect(`/student/${redirectModule.url}?spread=1`)
      }

      if (userTypeId === 3) {
        return utils.redirect(`/student/${modules[0].submenus[0].url}?spread=1`)
      }

      // 获取用户可用的第一个模块默认页面地址
      const permissionModules = utils.getPermissionModules()
      if (!permissionModules.length) return utils.redirect('/school/my-homepage')
      for (let i = 0; i < modules.length; i++) {
        if (!modules[i].children) {
          return utils.redirect(`/school/${modules[i].path}`)
        }
        for (let j = 0; j < modules[i].children.length; j++) {
          if (permissionModules.indexOf(modules[i].children[j].key) !== -1) {
            return utils.redirect(`/school/${modules[i].path}/${modules[i].children[j].path}`)
          }
        }
      }
      return utils.redirect('/school')
    }
  }
}

// 映射
export default connect(store => ({
  organizations: store.config.organizations.list,
  organization: store.common.organization,
  codes: store.config.countryCodes.list || []
}))(Home)
