import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import MyLoadable from 'components/loadable'
import ErrorBoundary from 'components/common/error'
// 学员层的base container

const routes = [
  {
    path: 'organizations',
    isExact: true,
    component: MyLoadable({
      loader: () => import('containers/platform/organization_list')
    })
  },
  {
    path: 'organizations/create',
    component: MyLoadable({
      loader: () => import('containers/platform/organization_upsert')
    })
  },
  {
    path: 'organizations/update/:id',
    component: MyLoadable({
      loader: () => import('containers/platform/organization_upsert')
    })
  },
  {
    path: 'clients',
    isExact: true,
    component: MyLoadable({
      loader: () => import('containers/platform/clients_list')
    })
  },
  {
    path: 'clients/create',
    component: MyLoadable({
      loader: () => import('containers/platform/client_detail')
    })
  },
  {
    path: 'clients/edit/:id',
    component: MyLoadable({
      loader: () => import('containers/platform/client_detail')
    })
  },
  {
    path: 'lovs/:filter?',
    component: MyLoadable({
      loader: () => import('containers/platform/lov_list')
    })
  },
  {
    path: 'roles',
    isExact: true,
    component: MyLoadable({
      loader: () => import('containers/platform/role_list')
    })
  },
  {
    path: 'roles/create',
    component: MyLoadable({
      loader: () => import('containers/platform/role_upsert')
    })
  },
  {
    path: 'roles/update/:id',
    component: MyLoadable({
      loader: () => import('containers/platform/role_upsert')
    })
  },
  {
    path: 'admins/:filter?',
    component: MyLoadable({
      loader: () => import('containers/platform/admin_list')
    })
  },
  {
    path: 'exam-type-conf',
    component: MyLoadable({
      loader: () => import('containers/platform/exam_type_conf')
    })
  }
]

export default class PlarformRoutes extends React.Component<any, any> {
  render () {
    const { match } = this.props
    // path中的可选参数不再是(/:xxx)形式，而是直接在参数后加一个问号
    return <Switch>
      {
        routes.map(route => {
          return <Route
            exact={route.isExact}
            path={`${match.path}/${route.path}`}
            key={`${match.path}/${route.path}`}
            render={
              props => (
                <ErrorBoundary>
                  <route.component
                    {...props}
                    params={props.match.params}
                  />
                </ErrorBoundary>
              )
            }
          />
        })
      }
    </Switch>
  }
}
