import * as utils from 'common/utils'
import * as React from 'react'
import { Select } from 'antd'
import * as selectCacheHelper from 'helpers/add_select_cache'
const Option: any = Select.Option

// 容器
export interface OrganizationPickerProps {
  organizations: any[];
  value: number;
  filterOption?: (input, option) => boolean;
  onChange: (organizationId: number) => void;
  required?: boolean;
  style?: object;
  disabled?: boolean;
  className?: string;
  defaultValue?: number;
  dropdownMatchSelectWidth?: boolean;
  renderOptions?: (item, index) => any;
}

export class OrganizationPicker extends React.Component<OrganizationPickerProps, any> {
  onChange = (...rest) => {
    let [id] = rest
    if (Number(id)) {
      selectCacheHelper.setLatestSelectData('organization', id)
    }
    this.props.onChange(id)
  }
  // 渲染
  render () {
    const { organizations, value, filterOption, required, style = {}, disabled, className, defaultValue, dropdownMatchSelectWidth, renderOptions } = this.props
    const visibleOrganizations = utils.getLocalStorage('visibleOrganizations')
    const sortedOrganizations = selectCacheHelper.getOrderData('organization', organizations)
    const options = sortedOrganizations
      .filter(i => !visibleOrganizations || visibleOrganizations.indexOf(i.id) != -1)
      .map((item, index) => renderOptions ? renderOptions(item, index) : <Option key={`${item.id}`} value={item.id} pinyin={item.namePinyin}>{item.name}</Option>)
    if (!required) options.unshift(<Option key='0' value={0} pinyin='buxian'>不限</Option>)

    const defaultStyle = { width: 180 }

    return (
      <Select
        style={Object.assign({}, defaultStyle, style)}
        value={value}
        showSearch
        notFoundContent='无匹配选项'
        placeholder='选择登录机构'
        optionFilterProp='children'
        filterOption={filterOption || utils.filterByPinyin}
        className={className}
        onChange={this.onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      >
        {options}
      </Select>
    )
  }
}

export default OrganizationPicker
