import * as utils from 'common/utils'
import * as React from 'react'
import { Checkbox, Button, Icon, message } from 'antd'
import ControlledTextarea from 'components/common/controlled_textarea'

class Quizzing extends React.Component<any, any> {
  serveType = utils.getStudentServeType() || 0

  state = {
    content: '',
    addArrestPoint: true,
    currentNum: 0,
    limit: this.props.type === 'questions' ? 120 : 500,
    isAbsencing: this.props.isAbsencing || false,
    isPaused: false
  }

  handleRecordingText = async () => {
    const { isPaused } = this.state
    await this.props.onInput(isPaused)
  }

  handleSave = async () => {
    const { type, currentTime, onCheckIsAbsencing, isOnline } = this.props
    if (isOnline) {
      await onCheckIsAbsencing()
      const { isAbsencing } = this.props
      if (isAbsencing) {
        return this.props.onVacation()
      }
    }
    const { content } = this.state
    if (type === 'questions') {
      if (content.length > 120) {
        return message.error('请输入不超过120字')
      }
    } else {
      if (content.length > 500) {
        return message.error('请输入不超过500字')
      }
    }
    let data: any = { content }
    if (this.state.addArrestPoint) {
      data.time = Math.round(currentTime)
    }
    this.props.onSubmit(data)
    this.setState({ content: '', currentNum: 0 })
  }

  handleChangeContent = e => {
    const { limit } = this.state
    if (e.target.value.length > limit) return false
    this.setState({
      content: e.target.value,
      currentNum: e.target.value.length
    })
  }

  render () {
    const { type, currentTime } = this.props
    const { addArrestPoint, content, currentNum, limit, isPaused } = this.state
    return (
      <div className='quiz-container'>
        <ControlledTextarea
          placeholder={type === 'questions' ? '在此提出你的问题' : '在此记录你的想法'}
          limit={limit}
          currentNum={currentNum}
          onFocus={this.handleRecordingText}
          onChange={this.handleChangeContent}
          content={content}
        />
        <div className='operations'>
          <span className='time-point'>
            <Checkbox
              checked={addArrestPoint}
              onChange={e => this.setState({
                addArrestPoint: e.target.checked,
                time: e.target.checked ? currentTime : 0
              })}
            >
              <span className='add-arrest-point-text'>添加时间驻点</span>
            </Checkbox>
          </span>
          {
            this.state.addArrestPoint && currentTime ? <span className='time'>
              <Icon type='play-circle' />
              <span>{utils.machineTime(currentTime)}</span>
            </span> : null
          }
          <span className='time-point'>
            <Checkbox
              checked={isPaused}
              onChange={e => this.setState({
                isPaused: e.target.checked
              })}
            >
              <span className='add-arrest-point-text'>是否暂停播放</span>
            </Checkbox>
          </span>
        </div>
        <div className='submit-button'>
          {
            this.props.disable
              ? <Button
                type='primary'
                onClick={this.handleSave}
              >
            提交
              </Button>
              : <Button
                type='primary'
                disabled
              >
            提交
              </Button>
          }
        </div>
      </div>
    )
  }
}

export default Quizzing
