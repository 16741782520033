import * as utils from 'common/utils'
import * as React from 'react'
import Sider from 'components/common/sider'
import LangContext from 'helpers/context'

class Panel extends React.Component<any, any> {
  modules

  constructor (props) {
    super(props)
    this.modules = []
  }
  UNSAFE_componentWillMount () {
    this.getModules(this.props)
  }
  componentDidMount () {
    this.getModules(this.props)
  }
  componentWillUpdate (nextProps) {
    this.getModules(nextProps)
  }
  getModules (props) {
    const business = utils.getLocalStorage('business')

    this.modules = props.modules.map(module => {
      if (module.children) {
        module.children = module.children.filter(item => {
          return item.insideVisiable == undefined || item.insideVisiable == global.IS_INSIDE
        })
      }
      return module
    })
    if (business && business != '1' && props.modules[0].children) {
      this.modules = props.modules[0].children
        .filter(i => ['organization', 'role'].includes(i.path))
    }
  }
  render() {
    console.log('-----this.modules---', this.modules)
    return (
      <LangContext.Consumer>
        {(val) => {
          let sign = val.lang == 'zh-CN'
          return <Sider
          // appName='Smart智能学习系统
          // appLogo={require('images/logo.png')} // TODO: 这里需要重新设计一下样式
            className='component-common-panel'
            menuData={this.modules}
            level={this.props.topLevel}
            width={sign ? 200 : 270}
            pathname={this.props.location.pathname}
          />
        }
        }
      </LangContext.Consumer>
    )
  }
}

export default Panel
