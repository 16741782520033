import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import constants from 'common/constants'
import * as rest from 'common/rest'
import { connect } from 'react-redux'
import { Modal, Popover } from 'antd'
import WechatAccountContant from './content'

const TITLE = '微信扫码关注“智课VIP”服务号'
const KEY = 'isWechatAccountFollowed'

// TODO: 弹窗关闭的时候，动画向“关注服务号”方向收缩
class WechatAccountContainer extends React.Component<any, any> {

  ORD_ID: number
  USER_TYPE: number
  USER_ID: number
  
  constructor(props){
    super(props)
    this.ORD_ID = utils.getOrganizationId()
    this.USER_TYPE = utils.getUserTypeId()
    this.USER_ID = utils.getUserId()
  }

  validateVisible = props => {
    
    const org = props.organizationMap[this.ORD_ID] || {}

    const isHeadquarter = !!utils.isHeadquarter(this.ORD_ID) // 总部层
    const isFromZhike = config.url.www.endsWith(document.referrer.split('/')[2]) // 从智课网跳转过来
    if (isHeadquarter || isFromZhike) return false

    const isOnlineOrg = this.ORD_ID === config.onlineOrganizationId // 在线机构
    const isStudent = this.USER_TYPE === constants.USER_TYPE.STUDENT // 学生
    const isInvalid = utils.getStudentServeType() !== 4 // 无转案学员
    if (isOnlineOrg && isStudent && isInvalid) return false

    const isYupei = org.business === constants.BUSINESS.YUPEI // 语培行业
    const isDirectOrg = org.typeId === config.directOrganizationTypeId // 直营机构

    return isYupei && isDirectOrg
  }

  state = {
    visible: false,
    showModal: false
  }
  followed = false

  componentWillMount() {
    this.setState({
      visible: this.validateVisible(this.props)
    })
  }

  async componentDidMount () {
    const date = new Date().toLocaleDateString()
    const shown = localStorage.lastShowWechatModalDate === date

    if (this.state.visible && this.USER_TYPE && this.USER_ID && !shown) {
      const res = await rest.get(`/api/common-config/key/${KEY}_${this.USER_TYPE}_${this.USER_ID}?business=smart`)

      if (res.code !== 0 || !res.data || res.data.value !== 'checked') {
        this.setState({ showModal: true })
        localStorage.lastShowWechatModalDate = date
      }
    }
  }

  renderContent = style => (
    <WechatAccountContant
      type={this.props.type}
      style={style}
      title={TITLE}
      toggleFollowed={checked => { this.followed = checked }}
    />
  )

  onCancel = () => {
    this.setState({
      showModal: false
    }, async () => {
      if (this.followed) {
        const data = { value: 'checked', business: 'smart' }
        const url = utils.genUrl(data, `/api/common-config/key/${KEY}_${this.USER_TYPE}_${this.USER_ID}`)
        await rest.put(url, data)
      }
    })
  }

  render () {
    const { type } = this.props
    const { visible, showModal } = this.state

    return (
      !visible
        ? null
        : <div className='wechat-account-container'>
          <Popover content={this.renderContent('popover')} title={null} placement='bottom' trigger='hover'>
            <div className='popover-content'>
              <img className='follow-icon' src={require(`images/wechat_account_promotion/follow_${type}.png`)} />
              关注服务号
            </div>
          </Popover>

          <Modal visible={showModal} title={TITLE} footer={null} width={600} onCancel={this.onCancel} centered maskClosable keyboard className='wechat-account-modal'>
            {this.renderContent('modal')}
          </Modal>
        </div>
    )
  }
}

export default connect(store => ({
  organizationMap: utils.getOrganizationsObj(store.config.organizations.list)
}))(WechatAccountContainer)
