import * as React from 'react'
import { changeTimeSlotsModalVisible } from 'actions/time_slots'
import TimeSlotsTable from 'components/common/time_slots_table'
import { Modal } from 'antd'
class TimeSlotsModal extends React.Component<any, any> {
  render () {
    const { timeSlots: slots } = this.props
    return (
      <Modal
        visible
        title='时段详情'
        wrapClassName='time-slots-modal'
        onCancel={() => changeTimeSlotsModalVisible(false)}
        onOk={() => changeTimeSlotsModalVisible(false)}
      >
        <TimeSlotsTable sundayFirst timeSlots={slots} />
      </Modal>
    )
  }
}

export default TimeSlotsModal
