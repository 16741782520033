import request from 'common/request'
import * as utils from 'common/utils'
import * as React from 'react'
import { message, Button as OrigButton } from 'antd'
import * as avatarHelper from 'helpers/avatar'
import intl from 'react-intl-universal'

const Button: any = OrigButton

// 选择器
export default class Component extends React.Component<any, any> {
  listRef = React.createRef<any>()
  state = {
    answers: [],
    isSubmitting: false,
    answer: ''
  }

  // 加载
  componentDidMount () {
    // 不知道setConfirm的作用
    if (typeof this.props.setConfirm === 'function') {
      this.props.setConfirm(this.confirm)
    }
    // this.props.setConfirm(this.confirm());
    this.fetchAnswers(this.props.id)
  }

  async UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    this.fetchAnswers(nextProps.id)
  }

  handleInputAnswer = event => {
    this.setState({
      answer: event.target.value
    })
  }

  // 渲染
  render () {
    const isStudent = utils.isStudent()
    return (
      <div className='component-school-modal-question-answer'>
        <div className='list' ref={this.listRef}>
          <div className='left-bg' />

          {this.state.answers.map(function (item) {
            if (!isStudent) {
              if (item.userTypeId === 1) {
                return (
                  <div key={item.id} className='item student-reply-item'>
                    <img className='info-left' src={require('images/teacherImgDefault.png')} />
                    <div className='info-right'>
                      <span className='name'>{item['student.name']}</span>
                      <span className='studentArrow arrow' />
                      <p className='student-content'>
                        <span dangerouslySetInnerHTML={{ __html: item.content }} />
                      </p>
                    </div>
                  </div>
                )
              }
              return (
                <div key={item.id} className='item teacher-reply-item'>
                  <div>
                    <div className='name'><span className='align-right'>{item['teacher.name']}</span></div>
                    <p className='teacher-content'>
                      <span dangerouslySetInnerHTML={{ __html: item.content }} />
                    </p>
                    <span className='teacherArrow arrow' />
                  </div>
                  <img className='info-right' src={require('images/teacherImgDefault.png')} />
                </div>
              )
            }
            if (item.userTypeId === 1) {
              return (
                <div key={item.id} className='item teacher-reply-item'>
                  <div>
                    <div className='name'><span className='align-right'>{item['student.name']}</span></div>
                    <p className='teacher-content'>
                      <span dangerouslySetInnerHTML={{ __html: item.content }} />
                    </p>
                    <span className='teacherArrow arrow' />
                  </div>
                  <img className='info-right' src={require('images/teacherImgDefault.png')} />
                </div>
              )
            }
            return (
              <div key={item.id} className='item student-reply-item'>
                <img className='info-left' src={require('images/teacherImgDefault.png')} />
                <div className='info-right'>
                  <span className='name'>{item['teacher.name']}</span>
                  <span className='studentArrow arrow' />
                  <p className='student-content'>
                    <span dangerouslySetInnerHTML={{ __html: item.content }} />
                  </p>
                </div>
              </div>
            )
          })}
        </div>

        <div className='reply' style={utils.isTeacher() && !utils.checkPermissionOperation('question-answer') ? { display: 'none' } : {}}>
          <div className='textarea'>
            <textarea value={this.state.answer} onInput={this.handleInputAnswer} placeholder='请输入内容...' />
            <Button disabled={this.state.isSubmitting} type='primary' onClick={this.reply}>{intl.get('Reply')}</Button>
          </div>
        </div>
      </div>
    )
  }

  // 获取问题
  fetchAnswers (propsId) {
    let self = this
    request({
      type: 'get',
      url: '/api/question/answers',
      data: { id: propsId }
    }, function (err, res) {
      if (err) {
        message.error(err)
        return
      }

      self.setState({
        answers: res
      })
    })
  }

  // 回答问题
  reply = async () => {
    this.setState({ isSubmitting: true })
    if (this.props.needCheckingIsAbsencing) {
      await this.props.onCheckIsAbsencing()
      const { isAbsencing } = this.props
      if (isAbsencing) {
        this.setState({ isSubmitting: false, answer: '' })
        return this.props.onVacation()
      }
    }
    let self = this
    const value = this.state.answer
    const len = value.split('').length
    if (!value) {
      this.setState({ isSubmitting: false })
      message.error('内容不能为空')
      return false
    }
    if (value && len > 2047) {
      this.setState({ isSubmitting: false })
      message.error(`内容过长，应该少于2048字，您输入了${len}个字`)
      return false
    }

    request({
      type: 'post',
      url: '/api/question/answer',
      data: { questionId: this.props.id, content: value }
    }, function (err, res) {
      if (err) {
        message.error(err)
        return
      }

      let answers = self.state.answers
      let answer = {
        id: res,
        userTypeId: utils.getUserTypeId(),
        avatar: avatarHelper.getAvatar(),
        content: value.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />')
      }
      if (answer.userTypeId === 1) {
        answer['student.name'] = utils.getUserName()
      } else {
        answer['teacher.name'] = utils.getUserName()
      }
      answers.push(answer)

      self.setState({
        isSubmitting: false,
        answer: '',
        answers
      })
      self.props.refresh()

      setTimeout(function () {
        self.listRef.current.scrollTop = self.listRef.current.scrollHeight
      }, 50)
    })
  }

  // 确认
  confirm () {
    return function (onResult) {
      onResult()
    }
  }
}
