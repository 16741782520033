import moment from 'moment-timezone'
import * as React from 'react'
import constants from 'common/constants'

export default class TimeTable extends React.Component<any, any> {
  renderHeader = futureDaysTimeStamps => {
    const arr = [<div key='-1' className='empty' />]
    const days = futureDaysTimeStamps.map((item, index) => {
      const dateString = moment(item).format('MM月DD日')
      return <div key={index}>{dateString}</div>
    })
    return arr.concat(days)
  }

  renderBoxes = data => {
    return data.map((item, index) => {
      return <div key={index} className='row-container'>
        {
          this.renderBoxItems(item, index)
        }
      </div>
    })
  }
  handleSelect = item => {
    if (!item.available || item.selected) {
      return false
    }
    this.props.onSelect([item.day, item.timeSlot])
  }
  renderBoxItems = (item, parentIndex) => {
    const boxes: any[] = Object.values(item)
    return boxes[0].map((i, index) => {
      return <div
        key={`${parentIndex}-${index}`}
        onClick={() => this.handleSelect(i)}
        className={i.selected ? 'blue' : i.available ? 'white' : 'gray'}
      />
    })
  }
  render () {
    const { futureDaysTimeStamps, data } = this.props
    return <div className='trial-time-table-container'>
      <div className='header'>
        {this.renderHeader(futureDaysTimeStamps)}
      </div>
      <div className='body'>
        <div className='time-values'>
          {
            constants.TRIAL_TIME_STRINGS.map((item, index) => {
              return <div key={index} className='item'>{item}</div>
            })
          }
        </div>
        <div className='time-boxes'>
          {this.renderBoxes(data)}
        </div>
      </div>
    </div>
  }
}
