import moment from 'moment-timezone'
import * as utils from 'common/utils'

const serialize = function (obj) {
  var str = []
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }
  }
  return str.join('&')
}

export default function (options, callback) {
  callback = callback || function () {}
  let ajaxOptions: any = {
    method: options.type,
    credentials: options.credentials || 'include',
    headers: options.headers || {}
  }

  if (!options.file) {
    ajaxOptions.headers['Content-Type'] = 'application/json; charset=utf-8'
  }

  // 跨域加上cors, 默认设置未form data(某些服务没有开启json支持)
  // 为了向前兼容，默认开启这个替换，但按需关闭
  options.corsDisable = options.corsDisable || false
  if (!options.corsDisable && options.url.includes('//')) {
    // 由于我们的多数的后台服务的跨域的origion设置为*，client端不能携带cookie过去
    delete ajaxOptions.credentials
    ajaxOptions.mode = 'cors'
    ajaxOptions.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  if (['POST', 'post', 'PUT', 'put', 'DELETE', 'delete'].includes(options.type)) {
    ajaxOptions.body = JSON.stringify(options.data)
  }
  if (['GET', 'get'].includes(options.type)) {
    const urlParams = serialize(options.data)
    urlParams && (options.url += `?${urlParams}`)
  }
  if (options.file) {
    let formData = new FormData()
    for (var key in options.data) {
      if (typeof options.data[key] === 'object' && !(options.data[key] instanceof File)) {
        formData.append(key, JSON.stringify(options.data[key]))
      } else {
        formData.append(key, options.data[key])
      }
    }
    ajaxOptions.body = formData
  }
  const timezone = moment().utcOffset() / 60
  const fingerprint = utils.getLocalStorage('fingerprint')
  const isAinAPI = options.url.startsWith('/api')
  ajaxOptions.headers = isAinAPI ? {
    ...ajaxOptions.headers,
    timezone,
    'X-APOL-VERSION': global.CLIENT_VERSION,
    'X-APOL-CLIENT': global.CLIENT_NAME,
    'X-APOL-BUILD-TIME': global.BUILD_TIME,
    fingerprint
  } : {}
  fetch(options.url, ajaxOptions)
    .then(function (response) {
      if (response.status >= 200 && response.status <= 304) {
        return response
      } else if (response.status === 0) {
        callback('请求超时，请重新再试，或者更换网络')
      } else if (response.status === 502) {
        callback('服务器错误，请联系系统管理员')
      } else {
        callback(`HTTP错误(${response.status})`)
      }
    })
    .then(function (response) {
      return response && response.json ? response.json() : {} as any
    })
    .then(function (res) {
      if (res.code === 0 || options.url.includes('//')) {
        callback(null, options.url.includes('//') ? res : res.data)
        // callback(null, options.url.indexOf('http://') !== -1 ? res.data : res.data);
      } else {
        if (typeof res !== 'object') {
          location.href = '/'
        } else {
          callback(res.code ? `${res.msg}(${res.code})` : '未知错误(1)')
        }
      }
    })
    .catch(function (err) {
      throw (err.message)
    })
}
