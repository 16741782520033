import * as React from 'react'

const createReactClass = require('create-react-class')
// 组件
const Tip = createReactClass({
  // 获取初始状态
  getInitialState () {
    return {
      top: -70,
      opacity: 0
    }
  },

  // 组件加载
  componentDidMount () {
    let self = this

    setTimeout(function () {
      self.setState({
        top: 0,
        opacity: 1
      })
    }, 50)

    setTimeout(function () {
      self.close()
    }, 2000)
  },

  // 渲染
  render () {
    let icon = this.props.type === 'correct' ? 'check-circle-o' : 'exclamation-circle'

    return (
      <div className='component-common-tip' style={{ top: this.state.top, opacity: this.state.opacity }}>
        <div className={`wrapper ${this.props.type === 'correct' ? 'correct' : ''}`}>
          <span className={`fa fa-${icon}`} />
          <span>{this.props.text}</span>
          <span className='fa fa-times' onClick={this.close} />
        </div>
      </div>
    )
  },

  // 关闭
  close () {
    this.setState({
      top: -70,
      opacity: 0
    })

    let self = this
    setTimeout(function () {
      self.props.close()
    }, 150)
  }
})

export default Tip
