import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import constants from 'common/constants'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

class StudentPanel extends React.Component<any, any> {
  state = {
    showSAExams: false
  }

  handleReturn () {
    // 先拿logo当作实验的按钮
    utils.redirect('/')
  }

  handleSwitchMenu = async () => {
    const { spread } = this.props.query
    if (spread === '1') {
      await this.props.pushHistory({ spread: '0' })
    } else {
      await this.props.pushHistory({ spread: '1' })
    }
  }

  handleIdentifyModule = (url) => {
    utils.redirect(url)
  }

  renderNewFeatureImage = tab => {
    const { key } = tab
    const newFeaturesConfig = constants.NEW_FEATURES
    if (newFeaturesConfig.includes(key)) {
      return <span className='hot-icon' />
    }
    return null
  }

  handleClick = tab => {
    if (tab.openNewTab) {
      window.open(tab.tabUrl, '_blank')
      return
    }
    this.handleIdentifyModule(`/student/${tab.url}?spread=${this.props.query.spread}`)
  }

  renderImg = (tab, selectedKey, spread) => {
    return <div><img
    src={require(`images/student_icons/${tab.icon}_${selectedKey === tab.url ? 'dark' : 'light'}.png`)}
    className={`img ${selectedKey === tab.url ? 'dark-img' : 'light-img'}`}
    alt=''
  />
  { spread ? <span className='txt'>{tab.text}</span> : ''}</div>
  }

  getStudentPanel () {
    const spreadBoolean = this.props.query.spread
    if (this.props.topLevel !== 'student' ||
      !this.props.modules ||
      !this.props.modules.length) return null
    let orgId = utils.getOrganizationId()
    let modules = this.props.modules
    if (config.demoOrganizationIds.indexOf(orgId) === -1) {
      modules = modules.filter(el => el.key !== 'plans')
    }
    // const { secondLevel } = this.props
    let spread = 0
    if (spreadBoolean === '1') {
      spread = 1
    }
    if (spreadBoolean === '0') {
      spread = 0
    }
    const { pathname } = location
    const selectedKey = pathname.split('/')[2]
    return (
      <Menu
        selectedKeys={[selectedKey]}
        className='student-panel-container'
        mode='inline'
      >
        <Menu.Item key='key'>
          <img onClick={this.handleReturn} className={spread ? 'logo' : 'logo-short'} src={require(`images/${spread ? 'logoPanel' : 'logo-short'}.png`)} />
        </Menu.Item>
        {
          modules.map(tab => {
            const { openNewTab } = tab
            return <Menu.Item
              key={tab.url}
            >
              {
                openNewTab ? <a onClick={() => this.handleClick(tab)}>
                {this.renderImg(tab, selectedKey, spread)}
                </a> 
                :
                 <Link
                to={`/student/${tab.url}?spread=${this.props.query.spread}`}
                onClick={() => this.handleClick(tab)}
              >
                {this.renderImg(tab, selectedKey, spread)}
              </Link>
              }
              {
                this.renderNewFeatureImage(tab)
              }
            </Menu.Item>
          })
        }
      </Menu>
    )
  }

  // 渲染
  render () {
    const { spread } = this.props.query
    return (
      <div className={`${spread === '1' ? 'component-common-panel' : 'component-common-panel-fold'}`}>
        <div className='wrapper'>
          {this.getStudentPanel()}
        </div>
      </div>
    )
  }
}

export default StudentPanel
