import * as React from 'react'
import Quizzing from './basics/quizzing'
import ListItem from './basics/list_item'

class Questions extends React.Component<any, any> {
  state = {
    onSubmit: this.props.onSubmit,
    questions: this.props.questions,
    callback: this.props.callback,
    disable: this.props.disable
  }

  async UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    this.setState({
      onSubmit: nextProps.onSubmit,
      questions: nextProps.questions,
      callback: nextProps.callback,
      disable: nextProps.disable
    })
  }

  handleSubmit = async data => {
    const url = '/api/question'
    this.props.onSubmit(data, url, 'questions')
  }

  handleDelete = async (id, type) => {
    const url = '/api/question'
    this.props.onDelete(id, url, 'questions')
  }

  handleReply = async id => {
    this.props.onReply(id)
  }

  handleVacation = () => this.props.onVacation();

  render () {
    const { questions, callback } = this.state
    const { isAbsencing } = this.props
    return (
      <div className='questions-list'>
        <Quizzing
          type='questions'
          onSubmit={this.handleSubmit}
          onPlayerPause={this.props.onPlayerPause}
          onInput={this.props.onInput}
          currentTime={this.props.currentTime}
          isAbsencing={isAbsencing}
          onVacation={this.handleVacation}
          disable={this.state.disable}
          isOnline={this.props.isOnline}
          onCheckIsAbsencing={this.props.onCheckIsAbsencing}
        />
        <div className='questions-list-outer-container'>
          <div className='questions-list-container'>
            {
              questions.map(item => <ListItem
                key={item.id}
                data={item}
                type='questions'
                onDelete={this.handleDelete}
                onReply={this.handleReply}
                callback={callback}
                isAbsencing={isAbsencing}
                onVacation={this.handleVacation}
                isOnline={this.props.isOnline}
                onSetTime={this.props.onSetTime}
                onCheckIsAbsencing={this.props.onCheckIsAbsencing}
              />
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Questions
