
import * as _ from 'lodash'
import * as utils from 'common/utils'
import * as rest from 'common/rest'

async function loginApi(data) {
  const res = await rest.get(utils.genUrl(data, '/api/user'))
  if (res && res.code === 0) {
    const smartToken = _.get(res, 'data.apollonToken') || ''
    setSmartToken(smartToken)
  }
  return res
}

const SMART_TOKEN_KEY = 'smart_token'

function setSmartToken(token) {
    localStorage.setItem(SMART_TOKEN_KEY, token)
}

function getSmartToken() {
    return localStorage.getItem(SMART_TOKEN_KEY)
}

export {
  loginApi,
  setSmartToken,
  getSmartToken,
}
