import * as _ from 'lodash'
const CITIES = [
  {
    name: '北京市',
    id: 110000,
    children: [{
      name: '北京市',
      id: 110000
    }]
  },
  {
    name: '天津市',
    id: 120000,
    children: [{
      name: '天津市',
      id: 120000
    }]
  },
  {
    name: '河北省',
    id: 130000,
    children: [
      {
        name: '石家庄市',
        id: 130100
      },
      {
        name: '唐山市',
        id: 130200
      },
      {
        name: '秦皇岛市',
        id: 130300
      },
      {
        name: '邯郸市',
        id: 130400
      },
      {
        name: '邢台市',
        id: 130500
      },
      {
        name: '保定市',
        id: 130600
      },
      {
        name: '张家口市',
        id: 130700
      },
      {
        name: '承德市',
        id: 130800
      },
      {
        name: '沧州市',
        id: 130900
      },
      {
        name: '廊坊市',
        id: 131000
      },
      {
        name: '衡水市',
        id: 131100
      },
      {
        name: '省直辖县级行政区划',
        id: 139000
      }
    ]
  },
  {
    name: '山西省',
    id: 140000,
    children: [
      {
        name: '太原市',
        id: 140100
      },
      {
        name: '大同市',
        id: 140200
      },
      {
        name: '阳泉市',
        id: 140300
      },
      {
        name: '长治市',
        id: 140400
      },
      {
        name: '晋城市',
        id: 140500
      },
      {
        name: '朔州市',
        id: 140600
      },
      {
        name: '晋中市',
        id: 140700
      },
      {
        name: '运城市',
        id: 140800
      },
      {
        name: '忻州市',
        id: 140900
      },
      {
        name: '临汾市',
        id: 141000
      },
      {
        name: '吕梁市',
        id: 141100
      }
    ]
  },
  {
    name: '内蒙古自治区',
    id: 150000,
    children: [
      {
        name: '呼和浩特市',
        id: 150100
      },
      {
        name: '包头市',
        id: 150200
      },
      {
        name: '乌海市',
        id: 150300
      },
      {
        name: '赤峰市',
        id: 150400
      },
      {
        name: '通辽市',
        id: 150500
      },
      {
        name: '鄂尔多斯市',
        id: 150600
      },
      {
        name: '呼伦贝尔市',
        id: 150700
      },
      {
        name: '巴彦淖尔市',
        id: 150800
      },
      {
        name: '乌兰察布市',
        id: 150900
      },
      {
        name: '兴安盟',
        id: 152200
      },
      {
        name: '锡林郭勒盟',
        id: 152500
      },
      {
        name: '阿拉善盟',
        id: 152900
      }
    ]
  },
  {
    name: '辽宁省',
    id: 210000,
    children: [
      {
        name: '沈阳市',
        id: 210100
      },
      {
        name: '大连市',
        id: 210200
      },
      {
        name: '鞍山市',
        id: 210300
      },
      {
        name: '抚顺市',
        id: 210400
      },
      {
        name: '本溪市',
        id: 210500
      },
      {
        name: '丹东市',
        id: 210600
      },
      {
        name: '锦州市',
        id: 210700
      },
      {
        name: '营口市',
        id: 210800
      },
      {
        name: '阜新市',
        id: 210900
      },
      {
        name: '辽阳市',
        id: 211000
      },
      {
        name: '盘锦市',
        id: 211100
      },
      {
        name: '铁岭市',
        id: 211200
      },
      {
        name: '朝阳市',
        id: 211300
      },
      {
        name: '葫芦岛市',
        id: 211400
      }
    ]
  },
  {
    name: '吉林省',
    id: 220000,
    children: [
      {
        name: '长春市',
        id: 220100
      },
      {
        name: '吉林市',
        id: 220200
      },
      {
        name: '四平市',
        id: 220300
      },
      {
        name: '辽源市',
        id: 220400
      },
      {
        name: '通化市',
        id: 220500
      },
      {
        name: '白山市',
        id: 220600
      },
      {
        name: '松原市',
        id: 220700
      },
      {
        name: '白城市',
        id: 220800
      },
      {
        name: '延边朝鲜族自治州',
        id: 222400
      }
    ]
  },
  {
    name: '黑龙江省',
    id: 230000,
    children: [
      {
        name: '哈尔滨市',
        id: 230100
      },
      {
        name: '齐齐哈尔市',
        id: 230200
      },
      {
        name: '鸡西市',
        id: 230300
      },
      {
        name: '鹤岗市',
        id: 230400
      },
      {
        name: '双鸭山市',
        id: 230500
      },
      {
        name: '大庆市',
        id: 230600
      },
      {
        name: '伊春市',
        id: 230700
      },
      {
        name: '佳木斯市',
        id: 230800
      },
      {
        name: '七台河市',
        id: 230900
      },
      {
        name: '牡丹江市',
        id: 231000
      },
      {
        name: '黑河市',
        id: 231100
      },
      {
        name: '绥化市',
        id: 231200
      },
      {
        name: '大兴安岭地区',
        id: 232700
      }
    ]
  },
  {
    name: '上海市',
    id: 310000,
    children: [{
      name: '上海市',
      id: 310000
    }]
  },
  {
    name: '江苏省',
    id: 320000,
    children: [
      {
        name: '南京市',
        id: 320100
      },
      {
        name: '无锡市',
        id: 320200
      },
      {
        name: '徐州市',
        id: 320300
      },
      {
        name: '常州市',
        id: 320400
      },
      {
        name: '苏州市',
        id: 320500
      },
      {
        name: '南通市',
        id: 320600
      },
      {
        name: '连云港市',
        id: 320700
      },
      {
        name: '淮安市',
        id: 320800
      },
      {
        name: '盐城市',
        id: 320900
      },
      {
        name: '扬州市',
        id: 321000
      },
      {
        name: '镇江市',
        id: 321100
      },
      {
        name: '泰州市',
        id: 321200
      },
      {
        name: '宿迁市',
        id: 321300
      }
    ]
  },
  {
    name: '浙江省',
    id: 330000,
    children: [
      {
        name: '杭州市',
        id: 330100
      },
      {
        name: '宁波市',
        id: 330200
      },
      {
        name: '温州市',
        id: 330300
      },
      {
        name: '嘉兴市',
        id: 330400
      },
      {
        name: '湖州市',
        id: 330500
      },
      {
        name: '绍兴市',
        id: 330600
      },
      {
        name: '金华市',
        id: 330700
      },
      {
        name: '衢州市',
        id: 330800
      },
      {
        name: '舟山市',
        id: 330900
      },
      {
        name: '台州市',
        id: 331000
      },
      {
        name: '丽水市',
        id: 331100
      }
    ]
  },
  {
    name: '安徽省',
    id: 340000,
    children: [
      {
        name: '合肥市',
        id: 340100
      },
      {
        name: '芜湖市',
        id: 340200
      },
      {
        name: '蚌埠市',
        id: 340300
      },
      {
        name: '淮南市',
        id: 340400
      },
      {
        name: '马鞍山市',
        id: 340500
      },
      {
        name: '淮北市',
        id: 340600
      },
      {
        name: '铜陵市',
        id: 340700
      },
      {
        name: '安庆市',
        id: 340800
      },
      {
        name: '黄山市',
        id: 341000
      },
      {
        name: '滁州市',
        id: 341100
      },
      {
        name: '阜阳市',
        id: 341200
      },
      {
        name: '宿州市',
        id: 341300
      },
      {
        name: '六安市',
        id: 341500
      },
      {
        name: '亳州市',
        id: 341600
      },
      {
        name: '池州市',
        id: 341700
      },
      {
        name: '宣城市',
        id: 341800
      }
    ]
  },
  {
    name: '福建省',
    id: 350000,
    children: [
      {
        name: '福州市',
        id: 350100
      },
      {
        name: '厦门市',
        id: 350200
      },
      {
        name: '莆田市',
        id: 350300
      },
      {
        name: '三明市',
        id: 350400
      },
      {
        name: '泉州市',
        id: 350500
      },
      {
        name: '漳州市',
        id: 350600
      },
      {
        name: '南平市',
        id: 350700
      },
      {
        name: '龙岩市',
        id: 350800
      },
      {
        name: '宁德市',
        id: 350900
      }
    ]
  },
  {
    name: '江西省',
    id: 360000,
    children: [
      {
        name: '南昌市',
        id: 360100
      },
      {
        name: '景德镇市',
        id: 360200
      },
      {
        name: '萍乡市',
        id: 360300
      },
      {
        name: '九江市',
        id: 360400
      },
      {
        name: '新余市',
        id: 360500
      },
      {
        name: '鹰潭市',
        id: 360600
      },
      {
        name: '赣州市',
        id: 360700
      },
      {
        name: '吉安市',
        id: 360800
      },
      {
        name: '宜春市',
        id: 360900
      },
      {
        name: '抚州市',
        id: 361000
      },
      {
        name: '上饶市',
        id: 361100
      }
    ]
  },
  {
    name: '山东省',
    id: 370000,
    children: [
      {
        name: '济南市',
        id: 370100
      },
      {
        name: '青岛市',
        id: 370200
      },
      {
        name: '淄博市',
        id: 370300
      },
      {
        name: '枣庄市',
        id: 370400
      },
      {
        name: '东营市',
        id: 370500
      },
      {
        name: '烟台市',
        id: 370600
      },
      {
        name: '潍坊市',
        id: 370700
      },
      {
        name: '济宁市',
        id: 370800
      },
      {
        name: '泰安市',
        id: 370900
      },
      {
        name: '威海市',
        id: 371000
      },
      {
        name: '日照市',
        id: 371100
      },
      {
        name: '莱芜市',
        id: 371200
      },
      {
        name: '临沂市',
        id: 371300
      },
      {
        name: '德州市',
        id: 371400
      },
      {
        name: '聊城市',
        id: 371500
      },
      {
        name: '滨州市',
        id: 371600
      },
      {
        name: '菏泽市',
        id: 371700
      }
    ]
  },
  {
    name: '河南省',
    id: 410000,
    children: [
      {
        name: '郑州市',
        id: 410100
      },
      {
        name: '开封市',
        id: 410200
      },
      {
        name: '洛阳市',
        id: 410300
      },
      {
        name: '平顶山市',
        id: 410400
      },
      {
        name: '安阳市',
        id: 410500
      },
      {
        name: '鹤壁市',
        id: 410600
      },
      {
        name: '新乡市',
        id: 410700
      },
      {
        name: '焦作市',
        id: 410800
      },
      {
        name: '濮阳市',
        id: 410900
      },
      {
        name: '许昌市',
        id: 411000
      },
      {
        name: '漯河市',
        id: 411100
      },
      {
        name: '三门峡市',
        id: 411200
      },
      {
        name: '南阳市',
        id: 411300
      },
      {
        name: '商丘市',
        id: 411400
      },
      {
        name: '信阳市',
        id: 411500
      },
      {
        name: '周口市',
        id: 411600
      },
      {
        name: '驻马店市',
        id: 411700
      },
      {
        name: '省直辖县级行政区划',
        id: 419000
      }
    ]
  },
  {
    name: '湖北省',
    id: 420000,
    children: [
      {
        name: '武汉市',
        id: 420100
      },
      {
        name: '黄石市',
        id: 420200
      },
      {
        name: '十堰市',
        id: 420300
      },
      {
        name: '宜昌市',
        id: 420500
      },
      {
        name: '襄阳市',
        id: 420600
      },
      {
        name: '鄂州市',
        id: 420700
      },
      {
        name: '荆门市',
        id: 420800
      },
      {
        name: '孝感市',
        id: 420900
      },
      {
        name: '荆州市',
        id: 421000
      },
      {
        name: '黄冈市',
        id: 421100
      },
      {
        name: '咸宁市',
        id: 421200
      },
      {
        name: '随州市',
        id: 421300
      },
      {
        name: '恩施土家族苗族自治州',
        id: 422800
      },
      {
        name: '省直辖县级行政区划',
        id: 429000
      }
    ]
  },
  {
    name: '湖南省',
    id: 430000,
    children: [
      {
        name: '长沙市',
        id: 430100
      },
      {
        name: '株洲市',
        id: 430200
      },
      {
        name: '湘潭市',
        id: 430300
      },
      {
        name: '衡阳市',
        id: 430400
      },
      {
        name: '邵阳市',
        id: 430500
      },
      {
        name: '岳阳市',
        id: 430600
      },
      {
        name: '常德市',
        id: 430700
      },
      {
        name: '张家界市',
        id: 430800
      },
      {
        name: '益阳市',
        id: 430900
      },
      {
        name: '郴州市',
        id: 431000
      },
      {
        name: '永州市',
        id: 431100
      },
      {
        name: '怀化市',
        id: 431200
      },
      {
        name: '娄底市',
        id: 431300
      },
      {
        name: '湘西土家族苗族自治州',
        id: 433100
      }
    ]
  },
  {
    name: '广东省',
    id: 440000,
    children: [
      {
        name: '广州市',
        id: 440100
      },
      {
        name: '韶关市',
        id: 440200
      },
      {
        name: '深圳市',
        id: 440300
      },
      {
        name: '珠海市',
        id: 440400
      },
      {
        name: '汕头市',
        id: 440500
      },
      {
        name: '佛山市',
        id: 440600
      },
      {
        name: '江门市',
        id: 440700
      },
      {
        name: '湛江市',
        id: 440800
      },
      {
        name: '茂名市',
        id: 440900
      },
      {
        name: '肇庆市',
        id: 441200
      },
      {
        name: '惠州市',
        id: 441300
      },
      {
        name: '梅州市',
        id: 441400
      },
      {
        name: '汕尾市',
        id: 441500
      },
      {
        name: '河源市',
        id: 441600
      },
      {
        name: '阳江市',
        id: 441700
      },
      {
        name: '清远市',
        id: 441800
      },
      {
        name: '东莞市',
        id: 441900
      },
      {
        name: '中山市',
        id: 442000
      },
      {
        name: '潮州市',
        id: 445100
      },
      {
        name: '揭阳市',
        id: 445200
      },
      {
        name: '云浮市',
        id: 445300
      }
    ]
  },
  {
    name: '广西壮族自治区',
    id: 450000,
    children: [
      {
        name: '南宁市',
        id: 450100
      },
      {
        name: '柳州市',
        id: 450200
      },
      {
        name: '桂林市',
        id: 450300
      },
      {
        name: '梧州市',
        id: 450400
      },
      {
        name: '北海市',
        id: 450500
      },
      {
        name: '防城港市',
        id: 450600
      },
      {
        name: '钦州市',
        id: 450700
      },
      {
        name: '贵港市',
        id: 450800
      },
      {
        name: '玉林市',
        id: 450900
      },
      {
        name: '百色市',
        id: 451000
      },
      {
        name: '贺州市',
        id: 451100
      },
      {
        name: '河池市',
        id: 451200
      },
      {
        name: '来宾市',
        id: 451300
      },
      {
        name: '崇左市',
        id: 451400
      }
    ]
  },
  {
    name: '海南省',
    id: 460000,
    children: [
      {
        name: '海口市',
        id: 460100
      },
      {
        name: '三亚市',
        id: 460200
      },
      {
        name: '三沙市',
        id: 460300
      },
      {
        name: '儋州市',
        id: 460400
      },
      {
        name: '省直辖县级行政区划',
        id: 469000
      }
    ]
  },
  {
    name: '重庆市',
    id: 500000,
    children: [
      {
        name: '重庆市',
        id: 500000
      }
    ]
  },
  {
    name: '四川省',
    id: 510000,
    children: [
      {
        name: '成都市',
        id: 510100
      },
      {
        name: '自贡市',
        id: 510300
      },
      {
        name: '攀枝花市',
        id: 510400
      },
      {
        name: '泸州市',
        id: 510500
      },
      {
        name: '德阳市',
        id: 510600
      },
      {
        name: '绵阳市',
        id: 510700
      },
      {
        name: '广元市',
        id: 510800
      },
      {
        name: '遂宁市',
        id: 510900
      },
      {
        name: '内江市',
        id: 511000
      },
      {
        name: '乐山市',
        id: 511100
      },
      {
        name: '南充市',
        id: 511300
      },
      {
        name: '眉山市',
        id: 511400
      },
      {
        name: '宜宾市',
        id: 511500
      },
      {
        name: '广安市',
        id: 511600
      },
      {
        name: '达州市',
        id: 511700
      },
      {
        name: '雅安市',
        id: 511800
      },
      {
        name: '巴中市',
        id: 511900
      },
      {
        name: '资阳市',
        id: 512000
      },
      {
        name: '阿坝藏族羌族自治州',
        id: 513200
      },
      {
        name: '甘孜藏族自治州',
        id: 513300
      },
      {
        name: '凉山彝族自治州',
        id: 513400
      }
    ]
  },
  {
    name: '贵州省',
    id: 520000,
    children: [
      {
        name: '贵阳市',
        id: 520100
      },
      {
        name: '六盘水市',
        id: 520200
      },
      {
        name: '遵义市',
        id: 520300
      },
      {
        name: '安顺市',
        id: 520400
      },
      {
        name: '毕节市',
        id: 520500
      },
      {
        name: '铜仁市',
        id: 520600
      },
      {
        name: '黔西南布依族苗族自治州',
        id: 522300
      },
      {
        name: '黔东南苗族侗族自治州',
        id: 522600
      },
      {
        name: '黔南布依族苗族自治州',
        id: 522700
      }
    ]
  },
  {
    name: '云南省',
    id: 530000,
    children: [
      {
        name: '昆明市',
        id: 530100
      },
      {
        name: '曲靖市',
        id: 530300
      },
      {
        name: '玉溪市',
        id: 530400
      },
      {
        name: '保山市',
        id: 530500
      },
      {
        name: '昭通市',
        id: 530600
      },
      {
        name: '丽江市',
        id: 530700
      },
      {
        name: '普洱市',
        id: 530800
      },
      {
        name: '临沧市',
        id: 530900
      },
      {
        name: '楚雄彝族自治州',
        id: 532300
      },
      {
        name: '红河哈尼族彝族自治州',
        id: 532500
      },
      {
        name: '文山壮族苗族自治州',
        id: 532600
      },
      {
        name: '西双版纳傣族自治州',
        id: 532800
      },
      {
        name: '大理白族自治州',
        id: 532900
      },
      {
        name: '德宏傣族景颇族自治州',
        id: 533100
      },
      {
        name: '怒江傈僳族自治州',
        id: 533300
      },
      {
        name: '迪庆藏族自治州',
        id: 533400
      }
    ]
  },
  {
    name: '西藏自治区',
    id: 540000,
    children: [
      {
        name: '拉萨市',
        id: 540100
      },
      {
        name: '日喀则市',
        id: 540200
      },
      {
        name: '昌都市',
        id: 540300
      },
      {
        name: '林芝市',
        id: 540400
      },
      {
        name: '山南市',
        id: 540500
      },
      {
        name: '那曲地区',
        id: 542400
      },
      {
        name: '阿里地区',
        id: 542500
      }
    ]
  },
  {
    name: '陕西省',
    id: 610000,
    children: [
      {
        name: '西安市',
        id: 610100
      },
      {
        name: '铜川市',
        id: 610200
      },
      {
        name: '宝鸡市',
        id: 610300
      },
      {
        name: '咸阳市',
        id: 610400
      },
      {
        name: '渭南市',
        id: 610500
      },
      {
        name: '延安市',
        id: 610600
      },
      {
        name: '汉中市',
        id: 610700
      },
      {
        name: '榆林市',
        id: 610800
      },
      {
        name: '安康市',
        id: 610900
      },
      {
        name: '商洛市',
        id: 611000
      }
    ]
  },
  {
    name: '甘肃省',
    id: 620000,
    children: [
      {
        name: '兰州市',
        id: 620100
      },
      {
        name: '嘉峪关市',
        id: 620200
      },
      {
        name: '金昌市',
        id: 620300
      },
      {
        name: '白银市',
        id: 620400
      },
      {
        name: '天水市',
        id: 620500
      },
      {
        name: '武威市',
        id: 620600
      },
      {
        name: '张掖市',
        id: 620700
      },
      {
        name: '平凉市',
        id: 620800
      },
      {
        name: '酒泉市',
        id: 620900
      },
      {
        name: '庆阳市',
        id: 621000
      },
      {
        name: '定西市',
        id: 621100
      },
      {
        name: '陇南市',
        id: 621200
      },
      {
        name: '临夏回族自治州',
        id: 622900
      },
      {
        name: '甘南藏族自治州',
        id: 623000
      }
    ]
  },
  {
    name: '青海省',
    id: 630000,
    children: [
      {
        name: '西宁市',
        id: 630100
      },
      {
        name: '海东市',
        id: 630200
      },
      {
        name: '海北藏族自治州',
        id: 632200
      },
      {
        name: '黄南藏族自治州',
        id: 632300
      },
      {
        name: '海南藏族自治州',
        id: 632500
      },
      {
        name: '果洛藏族自治州',
        id: 632600
      },
      {
        name: '玉树藏族自治州',
        id: 632700
      },
      {
        name: '海西蒙古族藏族自治州',
        id: 632800
      }
    ]
  },
  {
    name: '宁夏回族自治区',
    id: 640000,
    children: [
      {
        name: '银川市',
        id: 640100
      },
      {
        name: '石嘴山市',
        id: 640200
      },
      {
        name: '吴忠市',
        id: 640300
      },
      {
        name: '固原市',
        id: 640400
      },
      {
        name: '中卫市',
        id: 640500
      }
    ]
  },
  {
    name: '新疆维吾尔自治区',
    id: 650000,
    children: [
      {
        name: '乌鲁木齐市',
        id: 650100
      },
      {
        name: '克拉玛依市',
        id: 650200
      },
      {
        name: '吐鲁番市',
        id: 650400
      },
      {
        name: '哈密市',
        id: 650500
      },
      {
        name: '昌吉回族自治州',
        id: 652300
      },
      {
        name: '博尔塔拉蒙古自治州',
        id: 652700
      },
      {
        name: '巴音郭楞蒙古自治州',
        id: 652800
      },
      {
        name: '阿克苏地区',
        id: 652900
      },
      {
        name: '克孜勒苏柯尔克孜自治州',
        id: 653000
      },
      {
        name: '喀什地区',
        id: 653100
      },
      {
        name: '和田地区',
        id: 653200
      },
      {
        name: '伊犁哈萨克自治州',
        id: 654000
      },
      {
        name: '塔城地区',
        id: 654200
      },
      {
        name: '阿勒泰地区',
        id: 654300
      },
      {
        name: '自治区直辖县级行政区划',
        id: 659000
      }
    ]
  },
  {
    name: '台湾省',
    id: 710000,
    children: [{
      name: '台湾省',
      id: 710000
    }]
  },
  {
    name: '香港特别行政区',
    id: 810000,
    children: [{
      name: '香港特别行政区',
      id: 810000
    }]
  },
  {
    name: '澳门特别行政区',
    id: 820000,
    children: [{
      name: '澳门特别行政区',
      id: 820000
    }]
  },
  {
    name: '美国',
    id: 1,
    children: []
  },
  {
    name: '英国',
    id: 2,
    children: []
  },
  {
    name: '加拿大',
    id: 3,
    children: []
  },
  {
    name: '澳大利亚',
    id: 4,
    children: []
  },
  {
    name: '日本',
    id: 5,
    children: []
  },
  {
    name: '香港',
    id: 6,
    children: []
  },
  {
    name: '新加坡',
    id: 7,
    children: []
  },
  {
    name: '其他',
    id: 80,
    children: []
  }
]

function getTreeMap (tree) {
  const dict = {}
  if (!Array.isArray(tree)) {
    return {}
  }
  tree.forEach(el => {
    dict[el.id] = el
    Object.assign(dict, getTreeMap(el.children))
  })
  return dict
}

export const ALL_CITIES = getTreeMap(CITIES)

export function getName (code) {
  return (code && ALL_CITIES[code] && ALL_CITIES[code].name) || ''
}
export function renderAddress (areaCode) {
  if (!areaCode) {
    return '--'
  }
  const cityName = _.get(ALL_CITIES, `${areaCode}.name`)
  if (!(areaCode % 1e4)) {
    return cityName
  }
  const provinceCode = areaCode - (areaCode % 1e4)
  const provinceName = _.get(ALL_CITIES, `${provinceCode}.name`)
  return `${provinceName}/${cityName}`
}

export function getAreaCodes (areaCode) {
  if (!areaCode) {
    return []
  }
  if (areaCode % 1e4 > 0) {
    return [areaCode - (areaCode % 1e4), areaCode]
  }
  return [areaCode]
}

// 根据cities中定义的常量，生成一些用于Cascader的选项数据
function generateCascaderOptions (options) {
  if (!Array.isArray(options)) {
    return []
  }
  return options.map(option => ({
    value: option.id,
    label: option.name,
    key: option.id,
    children: generateCascaderOptions(option.children)
  }))
}

export default CITIES
export const CITIES_OPTS = generateCascaderOptions(CITIES)
