import * as utils from 'common/utils'
import * as React from 'react'

import Select from './form/select'
import Option from './form/option'
import Input from './form/input'
import Datetime from './form/datetime'
import Checkbox from './form/checkbox'
import Textarea from './form/textarea'
import { message } from 'antd'
import intl from 'react-intl-universal'
import SelectForRole from './modal_select_role'
const createReactClass = require('create-react-class')

const ModalForm = createReactClass({
  // 获取初始状态
  getInitialState () {
    return {
      maskOpacity: 0,
      formOpacity: 0,
      formMarginTop: -50 + (this.props.top || 0),
      selects: [],
      selectForRole: []
    }
  },

  // 组件加载
  componentDidMount () {
    let self = this

    setTimeout(function () {
      self.setState({
        maskOpacity: 0.5,
        formOpacity: 1,
        formMarginTop: 0 + (self.props.top || 0)
      })
    }, 50)
  },

  renderItemComponent (item) {
    const self = this
    // 文本框
    if (item.type === 'input' || item.type === 'password') {
      return (
        <Input
          ref={item.id}
          style='form'
          placeholder={item.title}
          defaultValue={item.defaultValue}
          readOnly={item.readOnly}
          type={item.type}
        />
      )
    }

    // 文本框
    if (item.type === 'textarea') {
      return (
        <Textarea
          ref={item.id}
          style='form'
          placeholder={item.title}
          defaultValue={item.defaultValue}
          readOnly={item.readOnly}
        />
      )
    }

    // 选择框
    if (item.type === 'select') {
      // 处理选项
      let options = {}
      if (item.relatedId) {
        options = {}

        let values = self.getValue()
        Object.keys(item.options).forEach(function (key) {
          let option = item.options[key]

          if (typeof option === 'string') {
            options[key] = item.options[key]
          } else
          if (parseInt(option[item.relatedId]) === parseInt(values[item.relatedId]) || (!self.state.selects[item.id] && parseInt(item.defaultValue) === parseInt(key))) {
            options[key] = option.name
          }
        })
      } else {
        options = item.options
      }

      return (
        <Select
          ref={item.id}
          style='form'
          defaultValue={item.defaultValue}
          readOnly={item.readOnly}
          onChange={() => {
            let selects = self.state.selects
            selects[item.id] = true
            self.setState({ selects })
          }}
        >
          {Object.keys(options).map(function (key) {
            return (
              <Option key={key} title={options[key]} value={key} />
            )
          })}
        </Select>
      )
    }

    if(item.type === 'selectForRole'){
      // 处理选项
      let options = {}
      if (item.relatedId) {

        let values = self.getValue()
        Object.keys(item.options).forEach((key) => {
          let option = item.options[key]

          if (typeof option === 'string') {
            options[key] = item.options[key]
          } else
          if (parseInt(option[item.relatedId]) === parseInt(values[item.relatedId]) ||
            (!self.state.selects[item.id] && parseInt(item.defaultValue) === parseInt(key))) {
            options[key] = option.name
          }
        })
      } else {
        options = item.options
      }
      return (
        <SelectForRole
          options={ options }
          addSearchValue={self.addSearchValue}
          removeSearchValue={self.removeSearchValue}
        />
      )
    }

    // 多选框
    if (item.type === 'checkbox') {
      return (
        <Checkbox
          ref={item.id}
          style='form'
          defaultValue={item.defaultValue}
          readOnly={item.readOnly}
          options={item.options}
          max={item.max}
        />
      )
    }

    // 日期区间
    if (item.type === 'date') {
      return (
        <Datetime
          ref={item.id}
          type='date'
          defaultValue={item.defaultValue}
        />
      )
    }
    if (item.type === 'time') {
      return (
        <Datetime
          ref={item.id}
          type='time'
          hideSec={item.hideSec}
          defaultValue={item.defaultValue}
        />
      )
    }
    if (item.type === 'datetime') {
      return (
        <Datetime
          ref={item.id}
          type='datetime'
          hideSec={item.hideSec}
          defaultValue={item.defaultValue}
        />
      )
    }
    // 日期区间
    if (item.type === 'dateInterval' || item.type === 'datetimeInterval') {
      var type = item.type === 'dateInterval' ? 'date' : 'datetime'
      return (
        <div className='date-interval'>
          <Datetime
            ref={`start${utils.toInitialUpperCase(item.id)}`}
            type={type}
            hideSec
          />
          <span className='split'>-</span>
          <Datetime
            ref={`end${utils.toInitialUpperCase(item.id)}`}
            type={type}
            hideSec
          />
        </div>
      )
    }
    return ''
  },

  // 渲染
  render () {
    let self = this
    let components = []

    this.props.items.forEach(function (item) {
      components.push(
        <div
          key={item.id}
          className='item'
          style={{ display: item.hide ? 'none' : 'block', width: self.props.width || 400 }}
        >
          <span
            className='tt'
            style={{ display: item.hideTitle ? 'none' : 'block' }}
          >
            {item.title}
          </span>
          {self.renderItemComponent(item)}
          {item.addonAfter || null}
        </div>
      )
    })

    return (
      <div className='common-modal-form'>
        <div className='wrapper'>
          <div className='form' style={{ opacity: this.state.formOpacity, marginTop: this.state.formMarginTop || 0, width: this.props.width || 400 }}>
            <div className='title'>{this.props.title}</div>
            {components}

            <div className='buttons'>
              <span className='button cancel' onClick={this.close}>{intl.get('cancel')}</span>
              <span className='button submit' onClick={this.submit}>
                {this.state.submitting ? '处理中' : `${intl.get('ok')}`}
              </span>
            </div>
          </div>

          <div className='mask' onClick={this.close} style={{ opacity: this.state.maskOpacity }} />
        </div>
      </div>
    )
  },

  // 关闭
  close () {
    this.setState({
      maskOpacity: 0,
      formOpacity: 0,
      formMarginTop: -50 + (this.props.top || 0)
    }, this.props.close)
  },

  // 提交
  submit () {
    if (this.state.submitting) return

    this.setState({ submitting: true }, () => {
      this.props.submit(this.getValue(), this.onComplete())
    })
  },

  addSearchValue(value) {
    let resultList = this.state.selectForRole
    resultList.push(value)
    this.setState({selectForRole: resultList})
  },

  removeSearchValue(value) {
    let resultList = this.state.selectForRole
    resultList.splice(resultList.indexOf(value), 1)
    this.setState({selectForRole: resultList})
  },

  // 获取值
  getValue () {
    let self = this
    let res = {}

    this.props.items.forEach(function (item) {
      if(item.type === 'selectForRole'){
        res = self.state.selectForRole
      }

      if (item.type !== 'dateInterval' && item.type !== 'datetimeInterval' && !self.refs[item.id]) return

      switch (item.type) {
        case 'input':
        case 'password':
        case 'textarea':
        case 'select':
        case 'date':
        case 'time':
        case 'datetime':
        case 'checkbox':
          res[item.id] = self.refs[item.id].getValue()
          break
        case 'datetimeInterval':
        case 'dateInterval':
          let id = utils.toInitialUpperCase(item.id)
          if (self.refs[`start${id}`].getValue()) {
            res[`start${id}`] = self.refs[`start${id}`].getValue()
          }
          if (self.refs[`end${id}`].getValue()) {
            res[`end${id}`] = self.refs[`end${id}`].getValue()
          }
          break
      }
    })
    return res
  },

  // 处理结果
  onComplete () {
    let self = this

    return function (err, res, showTip = true) {
      if (err) return self.setState({ submitting: false })

      self.close()
      showTip && message.success('操作成功')
      self.props.refresh && self.props.refresh(res)
    }
  }
})

export default ModalForm
