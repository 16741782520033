import * as React from 'react'
import { Select } from 'antd'
import * as Enum from 'common/enum'
import * as utils from 'common/utils'
import intl from 'react-intl-universal'
import LangContext from 'helpers/context'

const Option = Select.Option

export default class LanguageChange extends React.Component<any, any> {
  render () {
    const language = Enum.map.language
    return (
      <LangContext.Consumer>
        {(value) => {
          const ifHasLang = !!utils.getLocalStorage('lang')
          return (
            <span className='language_container'>
              <span className='language_label'>{intl.get('language')}: </span>
              <Select
                defaultValue={ifHasLang ? utils.getLocalStorage('lang') : 'zh-CN'}
                onChange={(val) => value.changeLanguage(val)}
              >
                {
                  Object.keys(language).map(item => <Option value={item} key={item}>{language[item]}</Option>)
                }
              </Select>
            </span>
          )
        }}
      </LangContext.Consumer>
    )
  }
}
