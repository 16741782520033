import * as React from 'react'

const createReactClass = require('create-react-class')
// 组件
const Input = createReactClass({
  // 获取初始状态
  getInitialState () {
    return {
      value: this.props.defaultValue != undefined ? this.props.defaultValue : ''
    }
  },

  UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        value: nextProps.defaultValue
      })
    }
  },
  // 渲染
  render () {
    return (
      <div
        className={`component-common-form-input component-common-form-input-${this.props.style}`}
      >
        <span
          className='title'
          style={{ display: this.props.title ? 'inline-block' : 'none' }}
        >
          {this.props.title}
        </span>
        <input
          type={this.props.type}
          placeholder={this.props.placeholder}
          value={this.state.value}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange}
          readOnly={this.props.readOnly || this.props.readonly}
          onBlur={this.handelBlur}
        />
        <span className='fa fa-search' onClick={this.handelBlur} />
      </div>
    )
  },

  // 处理改变
  handleChange (e) {
    this.setState({
      value: e.target.value
    })
  },

  // 处理按键
  handleKeyDown (e) {
    if (e.keyCode === 13 && this.props.onChange) {
      this.props.onChange(this.state.value)
    }
  },

  // 处理失焦
  handelBlur () {
    if (this.props.defaultValue != this.state.value && this.props.onChange) {
      this.props.onChange(this.state.value)
    }
  },

  // 获取值
  getValue () {
    return this.state.value
  }
})

export default Input
