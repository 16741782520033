import * as React from 'react'
import ReactDipper from 'react-dipper'

// 容器
export class Dipper extends React.Component<any, any> {
  // 渲染
  render () {
    return (
      <ReactDipper
        styleParams={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: '#f7fafc',
          backgroundPosition: '50% 50%',
          backgroundRepeat: true,
          backgroundSize: 'cover',
          zIndex: 0
        }}
        particleParams={{
          particles: {
            number: {
              value: 14,
              density: {
                enable: true,
                value_area: 800
              }
            },
            color: {
              value: '#dddddd'
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000'
              },
              polygon: {
                nb_sides: 5
              }
            },
            opacity: {
              value: 0.41688713582503595,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 14,
              random: true,
              anim: {
                enable: false,
                speed: 17.10794297352342,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 500,
              color: '#dddddd',
              opacity: 0.665416005259192,
              width: 1
            },
            move: {
              enable: true,
              speed: 1.603412060865523,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: false,
                mode: 'repulse'
              },
              onclick: {
                enable: false,
                mode: 'push'
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 121.81158184520176,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 537.6782077393075,
                size: 320.7704988590313,
                duration: 1.4663951120162932,
                opacity: 0.7471110353172375,
                speed: 3
              },
              repulse: {
                distance: 462.88401101176675,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        }}
      />
    )
  }
}

export default Dipper
