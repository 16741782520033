import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import { Icon, Switch as OrigSwitch, Form, message } from 'antd'
import PasswordModal from 'components/common/password_modal'
import * as rest from 'common/rest'
import * as userHelper from 'helpers/user'
import * as avatarHelper from 'helpers/avatar'

const Switch: any = OrigSwitch

const iconStyle = {
  marginRight: 4
}

const linkStyle = {
  cursor: 'pointer'
}
const VACATION_TIMES = 3

class Header extends React.Component<any, any> {
  state = {
    modalVisible: false,
    secondLevel: '',
    ticking: false
  }
  isOnline = utils.isOnline()

  handleVacation = () => {
    if (!(this.props.absenceTimes < VACATION_TIMES)) {
      return this.props.onShowVacationAlert()
    }
    this.props.onVacation()
  }

  handleModifyPassword = () => this.setState({ modalVisible: true });

  handleGetCaptcha = async () => {
    const phone = utils.getLocalStorage('userAccount')
    const res = await rest.post('/api/user/sms', { phone })
    if (res.code) {
      return message.error({ text: '验证码发送失败', msg: res.msg })
    }
    return this.setState({ ticking: true })
  }

  handleHideModal = () => {
    this.setState({ secondLevel: '' })
  }

  handleShowModal = () => {
    this.setState({ secondLevel: 'user' })
  }

  render () {
    const { isAbsencing } = this.props
    const { modalVisible, ticking } = this.state
    const avatarImg = avatarHelper.getAvatar()
    const userAccount = utils.getLocalStorage('userAccount')
    const isPhoneLogin = userAccount ? userAccount.indexOf('@') === -1 : null

    return (
      <div className='header'>
        <div className='header-left'>
          <div className='logo' />
          <div className='line' />
          <div className='title'>
            {this.props.title || ''}
          </div>
        </div>
        <div className='header-right'>
          {this.props.hasPermission
            ? (
              <div className='buttons'>
                <a className='feedback-btn' onClick={this.props.onFeedback}><Icon style={iconStyle} type='message' /><label>反馈问题</label></a>
              </div>
            )
            : null
          }
          {this.props.hasPermission && this.props.autoNextSwitchVisible
            ? (
              <div className='auto-play'>
                <div>
                  <Switch
                    size='small'
                    checked={this.props.autoPlayNext}
                    onChange={this.props.onChange}
                    style={iconStyle}
                  />
                  <label>自动打开下一课时</label>
                </div>
              </div>
            )
            : null
          }
          <div className='user' onMouseLeave={this.handleHideModal}>
            <img style={linkStyle} onMouseEnter={this.handleShowModal} src={avatarImg} />
          </div>
          <div
            className='panel'
            style={{ display: this.state.secondLevel === 'user' ? 'block' : 'none' }}
            onMouseEnter={this.handleShowModal}
            onMouseLeave={this.handleHideModal}
          >
            <div className='profile' >
              <img src={avatarImg} />
              <span className='name'>{utils.getUserName()}</span>
              <span className='trangle' />
            </div>
            {this.isOnline
              ? (
                <span
                  className='item'
                  onClick={this.handleVacation}
                >
                  <Icon type='calendar' />
                  我要{`${isAbsencing ? '销假' : '请假'}`}
                </span>
              )
              : null
            }
            {!this.isOnline
              ? (
                <span
                  className='item'
                  onClick={this.handleModifyPassword}
                >
                  <Icon type='lock' />
                  修改密码
                </span>
              )
              : null
            }
            { this.isOnline ? <span
              className='item'
              onClick={() => window.open(`${config.url.www}/user/account.html`, '_blank')}
            >
              <Icon type='user' />
              个人中心
            </span> : null }
            <span
              className='item'
              onClick={() => utils.logout(false)}
            >
              <Icon type='poweroff' />
              退出
            </span>
            {modalVisible
              ? (
                <PasswordModal
                  title='修改密码'
                  visible
                  form={this.props.form}
                  getCaptcha={() => this.handleGetCaptcha()}
                  resendCaptcha={() => this.setState({ ticking: false })}
                  ticking={ticking}
                  closeModal={() => this.setState({ modalVisible: false, ticking: false })}
                  isPhoneLogin={isPhoneLogin}
                  userAccount={userAccount}
                  modifyPassword={values => userHelper.modifyPassword(values, isPhoneLogin, () => {
                    this.setState({ modalVisible: false, ticking: false })
                    this.props.form.resetFields()
                  })}
                />
              )
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Form.create()(Header) as any
