import * as React from 'react'

export default class Ticking extends React.Component<any, any> {
  interval: any

  state = {
    secondsElapsed: this.props.maxSeconds
  }

  componentDidMount () {
    this.interval = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  resendCaptcha () {
    if (this.state.secondsElapsed === 0) {
      this.props.resendCaptcha()
    }
  }

  tick () {
    const { secondsElapsed } = this.state
    this.setState({
      secondsElapsed: secondsElapsed - 1
    }, () => this.resendCaptcha())
  }

  render () {
    const { secondsElapsed } = this.state
    return <span className='ticking-span'>{secondsElapsed}s</span>
  }
}
