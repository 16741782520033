
const questionScoreDescription = [
  '非常满意：5 分',
  '满意：4 分',
  '一般：3 分',
  '不满意：2 分',
  '非常不满意：1 分',
]

const questionInputTitles = {
  favoriteTeacher: '请写出一位您最喜欢的老师，并写明原因~',
  advice: '请问您对智课还有其他的意见或者建议吗？'
}

const questionTitleFactory = (type: string, ptName: string, exam?: string) => {
  switch (type) {
    case 'taskList':
      return `服务PT${ptName}每次都设置非常科学合理的学习任务，与我的学习进度匹配度高，可以帮助我提高当天学习效率；偶尔出现不匹配的时候也可以很及时调整过来。`
    case 'daily':
      return `服务PT${ptName}每次都会在当天（或最晚第二天早上12点前）在学习群里反馈我上课当天真实的学习情况，内容详实并有指导作用。`
    case 'management':
      return `服务PT${ptName}会严格按照任务清单上检查我当天任务完成情况，没有完成的PT老师会继续督促我完成。`
    case 'feedback':
      return `服务PT${ptName}会经常主动询问我是否有困惑或问题，并且我反映的问题都可以在24小时内得到相应人员的反馈，或者至少会告诉我什么时候可以解决？`
    case 'abilities':
      return `${exam}授课教师上课教学目的明确重点突出，能够因材施教，能够有效的解决我学习中遇到的问题`
    case 'atmosphere':
      return `${exam}授课教师有亲和力和耐心，课堂氛围活跃，有良好的互动，能够激发我的学习动力`
    case 'timeliness':
      return `${exam}授课教师没有调课、迟到早退情况，能及时（24小时内）为我答疑和认真批改作业`
    default:
      return ''
  }
}

export {
  questionScoreDescription,
  questionInputTitles,
  questionTitleFactory
}
