import moment from 'moment-timezone'
import * as _ from 'lodash'
import * as city from 'common/city'
import config from 'common/config'
import { VisibleOrganizationMapWithPinyin } from 'types'
const SMART_SOURCE = 'smart'
const SMART_INSIDE_SOURCE = 'smartInside'

/**
 * 常用函数
 *
 * 比较经常使用的一些函数
 */

import constants from 'common/constants'
import { Modal, message } from 'antd'
import pathToRegExp from 'path-to-regexp'
import Permission from 'common/permission'
import studentPermission from 'common/studentPermission'
import fn from 'common/modules'
import * as rest from 'common/rest'
import md5Lib from 'md5'
import { Base64 } from 'js-base64'
import * as socketHelper from 'helpers/socket'
const { PIGAIED } = constants.STUDENT_TASK_STATUS

var localPrefix = 'apol_'

var permissionModules = null
// 读取cookie
export const getCookie = function (key, prefix = localPrefix) {
  key = prefix + key

  let pairs = document.cookie.split(';').map(function (pair) {
    return {
      key: pair.split('=')[0],
      value: pair.split('=')[1]
    }
  })

  const obj: any = {}
  for (let i = 0; i < pairs.length; i++) {
    const value = decodeURIComponent(pairs[i].value)
    if (value) {
      obj[pairs[i].key.trim()] = value
    }
  }

  return obj[key]
}
export const getLocalStorage = function (key) {
  return JSON.parse(localStorage.getItem(localPrefix + key))
}
const sign = getLocalStorage('lang') == 'zh-CN' || getLocalStorage('lang') == undefined
let allFullModules = fn(sign)

function getStyle (ele) {
  if (!ele) {
    return
  }
  const style = window.getComputedStyle(ele)
  return [
    `border: ${style.getPropertyValue('border')}`,
    `background: ${style.getPropertyValue('background')}`,
    `color: ${style.getPropertyValue('color')}`
  ].join(';')
}
function setStyle (ele, style?) {
  if (!ele || !ele.style) {
    return
  }
  ele.style.cssText = style || getStyle(ele)
}

export const cloneObj = function (obj) {
  var str
  var newobj = obj.constructor === Array ? [] : {}
  if (typeof obj !== 'object') {
    return
  } else if (window['JSON']) {
    str = JSON.stringify(obj) // 系列化对象
    newobj = JSON.parse(str) // 还原
  } else {
    for (var i in obj) {
      newobj[i] = typeof obj[i] === 'object'
        ? cloneObj(obj[i]) : obj[i]
    }
  }
  return newobj
}

let fullModules: any = cloneObj(allFullModules)

export const deepGet = function (object, path, defaultValue) {
  return (!Array.isArray(path) ? path.replace(/\[/g, '.').replace(/\]/g, '').split('.') : path)
    .reduce((o, k) => (o || {})[k], object) || defaultValue
}

export const setLocalStorage = function (key, value) {
  if (value == null) {
    value = ''
  }
  return localStorage.setItem(localPrefix + key, JSON.stringify(value))
}
export const removeLocalStorage = function (key) {
  return localStorage.removeItem(localPrefix + key)
}
export const removePermissionModules = function () {
  return (permissionModules = null)
}
export const resetFullModules = function () {
  return (fullModules = cloneObj(allFullModules))
}

export const isOnline = function (organizationId = Number(getCookie('organizationId'))): boolean {
  const id = organizationId - 0
  const onlineOrganizationIds = config.onlineOrganizationIds || [config.onlineOrganizationId]
  if (onlineOrganizationIds instanceof Array) {
    return onlineOrganizationIds.includes(id)
  }
  // 使用数字来表示站点id
  return onlineOrganizationIds === id
}

export const isTeacherSupervisor = function () {
  return getLocalStorage('isTeacherSupervisor')
}

export const sliceStringWithEllipsis = function (string, wordlength) {
  return string.length < wordlength ? string : `${string.substr(0, wordlength)}...`
}

// 写入cookie
export const setCookie = function (key, value, maxAge = -1, prefix = localPrefix, domain) {
  const suffix = domain ? `domain=.${domain};` : ''
  if (maxAge === -1) {
    document.cookie = `${prefix}${key}=${encodeURIComponent(value)};path=/;${suffix}`
  } else {
    document.cookie = `${prefix}${key}=${encodeURIComponent(value)};max-age=${maxAge};path=/;${suffix}`
  }
}

// 将首字母转换为大写
export const toInitialUpperCase = function (s) {
  return s[0].toUpperCase() + s.substr(1)
}

export const convertPhoneFormat = function (phone) {
  const isPhoneInvalid = !phone || !(['string', 'number'].includes(typeof (phone)))
  if (isPhoneInvalid) {
    return ''
  }
  const separator = '-'
  const phoneStr = String(phone)
  if (phoneStr.indexOf(separator) !== -1) {
    const phoneArr = phoneStr.split(separator)
    return phoneArr[1]
  }
  return phoneStr
}

export function getProvince (cityCode): any {
  if (!cityCode) {
    return {}
  }
  const CITIES = city.default.concat()
  CITIES.splice(-8)
  const theProvince = CITIES.find(city => {
    if (city.id === cityCode) {
      return !!city.name
    } else if (city.children) {
      return !!city.children.find(city => city.id === cityCode)
    } else {
      return false
    }
  })
  return theProvince || {}
}

export const isPhoneCorrect = function (phone) {
  const phoneNum = phone.includes('-') ? phone.split('-')[1] : phone
  const regExp = /^1[3456789]\d{9}$/
  return (regExp.test(phoneNum))
}

export const isEmailCorrect = function (email) {
  const regExp = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
  return regExp.test(email)
}

// 解析path
export const parsePath = function () {
  let matches = location.pathname.match('(.*)/__(.*)')

  if (matches) {
    return {
      path: matches[1],
      params: JSON.parse(Base64.decode(matches[2]))
    }
  }
  return {
    path: location.pathname,
    params: {}
  }
}

// looks like lodash.keyBy
// convert [{id:1, name: zxd}, {}] => {1: {id:1, name: zxd}, 2: {}}
export const _KeyBy = function (arr, key) {
  return arr.reduce((init, next) => {
    init[next[key]] = next
    return init
  }, {})
}

// check if object/array is empty (source properties excluded)
export const _IsEmpty = function (obj) {
  if (obj instanceof Array) { return obj.length === 0 }
  return Object.keys(obj).length === 0
}

export const getattr = function (obj, key, defaultValue) {
  if (typeof obj === 'object' && obj[key] !== undefined) {
    return obj[key]
  }
  return defaultValue
}

export const isRealNum = val => {
  var regPos = /^\d+(\.\d+)?$/
  var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/
  if (regPos.test(val) || regNeg.test(val)) {
    return true
  } else {
    return false
  }
}

export const calcIeltsTotalScore = data => {
  // data传雅思总分或者分项分数的数组
  const IELTS_SCORE_NUM = 4
  const IELTS_LOW_LIMIT = 0.25
  const IELTS_HIGH_LIMIT = 0.74
  const IELTS_HALF_SCORE = 0.5
  let totalScore = data
  if (Array.isArray(data)) {
    totalScore = data.reduce((a, v) => a + v, 0)
  }
  const actualScore = totalScore / IELTS_SCORE_NUM
  const scoreInt = Math.floor(actualScore)
  const decimal = actualScore - scoreInt
  if (decimal < IELTS_LOW_LIMIT) return scoreInt
  if (decimal > IELTS_HIGH_LIMIT) return scoreInt + 1
  return scoreInt + IELTS_HALF_SCORE
}

export const setObjValue = (obj, value) => {
  for (let key in obj) {
    obj[key] = value
  }
  return obj
}

export const getAverage = (total, num) => {
  const result = new Array(num)
  result.fill(Math.floor(total / num))
  const remainder = total % num
  for (let i = 0; i < remainder; i++) {
    result[i] += 1
  }
  return result
}

export const getSubjectType = function (name) {
  // 默认返回0, 0为综合
  if (!name) { return 0 }
  for (let i in constants.CHECKLIST_SUBJECT_NAME) {
    // 名字里的综合需要忽略，比如综合写作需要判断为写作而不是综合
    if (i === '0') { continue }
    const subjectName = constants.CHECKLIST_SUBJECT_NAME[i]
    if (subjectName && name.includes(subjectName)) {
      return parseInt(i, 10)
    }
  }
  // 处理产品提出的额外的映射关系
  for (let i in constants.CHECKLIST_SUBJECT_NAME_EXTRA) {
    if (name.includes(i)) { return constants.CHECKLIST_SUBJECT_NAME_EXTRA[i] }
  }
  return 0
}

// export html table to excel xls file, dom is optional parameter to supply a html table, if not supplied, it will use first table in html
export const exportToCSV = function (filename, dom, skipStyle = false, cb = () => {}) {
  dom = dom || document.querySelector('table')
  dom.style.display = 'none'
  // 填写内联css样式
  if (!skipStyle) {
    setStyle(dom)
    setStyle(dom.querySelector('thead'))
    setStyle(dom.querySelector('tbody'))
    const trs = dom.querySelectorAll('tbody tr')
    const trStyle = getStyle(trs[0])
    const trTds = trs.length > 0 ? trs[0].querySelectorAll('td') : []
    const tdStyles = Array.prototype.map.call(trTds, td => {
      return getStyle(td)
    })
    Array.prototype.forEach.call(trs, tr => {
      setStyle(tr, trStyle)
      const tds = tr.querySelectorAll('td')
      for (let i = 0; i <= tds.length; i++) {
        setStyle(tds[i], tdStyles[i])
      }
    })
    Array.prototype.forEach.call(dom.querySelectorAll('th'), td => {
      setStyle(td)
    })
  }

  dom.style.display = 'block'
  const uri = 'data:application/vnd.ms-excel;base64,'
  const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
  const base64 = s => window.btoa(unescape(encodeURIComponent(s)))
  const format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p])
  const ctx = { worksheet: 'Worksheet', table: dom.innerHTML }
  const href = uri + base64(format(template, ctx))
  const a = document.createElement('a')
  a.href = href
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  cb && cb()
}

// convert array data to CSV file with BOM supoort
export const array2CSV = function (ary, keys) {
  if (!ary.length) { return '' }
  const strAry = []
  const columns = keys || Object.keys(ary[0])
  strAry.push(columns.join(','))
  for (let item of ary) {
    strAry.push(columns.map(el => item[el]).join(','))
  }
  // ADD UTF8 BOM prefix
  return `\ufeff${strAry.join('\r\n')}`
}

// get m-seconds from begin of that day
export const getTimeInterval = function (t) {
  const beginOfDay = new Date(t).setHours(0, 0, 0, 0)
  return new Date(t).getTime() - beginOfDay
}

// 将数组转成KV对象
export const array2kv = function (arr, isSelect = false, relationId?: number) {
  let res = isSelect ? { 0: '选择' } : { 0: '全部' }
  if (arr && arr.completed) {
    if (arr.data instanceof Array) {
      arr.data.forEach(function (item) {
        res[item.id] = relationId ? {
          name: item.name,
          [relationId]: item[relationId]
        } : item.name
      })
    } else {
      Object.keys(arr.data).forEach(function (key) {
        res[key] = arr.data[key]
      })
    }
  }
  return res
}

export const getZhikeToken = function () {
  const ssUserCookie = getCookie('user', 'ss_')
  if (ssUserCookie) {
    const ssUser = JSON.parse(ssUserCookie)
    if (ssUser && ssUser.token) {
      return ssUser.token
    }
  }

  return ''
}

// 查看数据是否准备就绪
export function isCompleted (data, allows = []) {
  allows = typeof allows === 'object' ? allows : [allows]

  for (let i in data) {
    if (['children', 'dispatch', 'history', 'location', 'staticContext',
      'params', 'route', 'routeParams', 'routes', 'isHeadquarters'].indexOf(i) !== -1) continue

    if (!data[i] && allows.indexOf(i) === -1) {
      return false
    }

    if (data[i] && data[i].completed === false) {
      return false
    }
  }

  return true
}

// 解析时间
export function parseDate (date) {
  let ret: any = {}
  ret.year = date.getFullYear()
  ret.month = date.getMonth() + 1
  ret.date = date.getDate()
  ret.hours = date.getHours()
  ret.minutes = date.getMinutes()
  ret.seconds = date.getSeconds()
  ret.day = date.getDay()
  ret.day = ret.day === 0 ? 7 : ret.day

  ret.yearText = ret.year.toString()
  ret.monthText = ret.month < 10 ? `0${ret.month}` : ret.month
  ret.dateText = ret.date < 10 ? `0${ret.date}` : ret.date
  ret.hoursText = ret.hours < 10 ? `0${ret.hours}` : ret.hours
  ret.minutesText = ret.minutes < 10 ? `0${ret.minutes}` : ret.minutes
  ret.secondsText = ret.seconds < 10 ? `0${ret.seconds}` : ret.seconds

  ret.yearMonthText = `${ret.yearText}-${ret.monthText}`
  ret.yearMonthDateText = `${ret.yearText}-${ret.monthText}-${ret.dateText}`
  ret.yearMonthDateTextCN = `${ret.year}年${ret.month}月${ret.date}日`
  ret.hoursMinutesText = `${ret.hoursText}:${ret.minutesText}`

  ret.rawDate = date
  ret.time = date.getTime()

  return ret
}

// 获取用户模块权限
export const getPermissionModules = (function () {
  return function (nocache = false) {
    if (nocache || (!permissionModules || permissionModules.length === 0)) {
      // 管理员只校验机构权限
      let isHeadquarters = +(getCookie('isHeadquarters') || 0)
      let permissions = []
      let orgPermissions = []
      let orgType = isHeadquarters ? 3 : isOnline() ? 2 : 1

      for (let key in Permission) {
        Permission[key].orgType.indexOf(orgType) !== -1 && orgPermissions.push(key)
      }

      try {
        permissions = getLocalStorage('permissionModules') || []
      } catch (e) {}
      // 取角色权限和机构权限的交集
      permissions = _.filter(permissions, item => orgPermissions.indexOf(item) > -1)
      permissionModules = permissions
    }
    return permissionModules
  }
}())

function getStudentM() {
  const studentMenu = fullModules.student
  return studentMenu.filter(item => {
    if (item.text === '知识库') {
      if (global.IS_INSIDE && [88, 160].includes(getOrganizationId())) {
        return false
      }
    }

    if (item.show && typeof item.show === 'function') {

      return item.show()
    }
    return true
  })
}
export const getStudentPermissionModules = () => {
  const onlineType = isOnline() ? 1 : 0
  const serveType = Number(getCookie('serveType')) || 0
  return getStudentM().filter(i => {
    const url = i.url === 'smart-adaptation' ? 'smart_adaptation' : i.url
    const permission = studentPermission[url]
    if (onlineType) {
      return permission.onlineType.includes(onlineType) &&
        permission.serveType.includes(serveType) &&
        // 对于有些panel是通过insideVisiable来控制在inside还是smart展示，organizationId决定具体是那个站点
        i.insideVisiable === undefined ? true : (global.IS_INSIDE == i.insideVisiable) &&
        (i.organizationId ? i.organizationId === getOrganizationId() : true)
    }
    return permission.onlineType.includes(onlineType) && i.insideVisiable === undefined ? true : (global.IS_INSIDE == i.insideVisiable) && (i.organizationId ? i.organizationId === getOrganizationId() : true)
  })
}

// 检查是否有模块权限
export function checkPermissionModule (module: string) {
  const permissions = getPermissionModules()
  return permissions.indexOf(module) !== -1
}

// 检查是否有操作权限
export function checkPermissionOperation (required: string) {
  let permissions: string[] = []

  try {
    permissions = getLocalStorage('permissionOperations')
  } catch (e) {
  }
  permissions = permissions || []
  return permissions.indexOf(required) > -1
}

// 将秒转换成时间
export function sec2time (sec: number) {
  let hour: any = Math.floor(sec / 3600)
  hour = hour < 10 ? `0${hour}` : hour
  let minute: any = Math.floor((sec - hour * 3600) / 60)
  minute = minute < 10 ? `0${minute}` : minute
  let second: any = Math.floor(sec % 60)
  second = second < 10 ? `0${second}` : second
  return `${hour}:${minute}:${second}`
}

export function getDateTime (date) {
  var d = new Date(date)

  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)

  return d.getTime()
}

/**
 * 获取日期字符串
 * @param {date} date 日期
 * @return {string} YYYY-MM-DD 格式的日期字符串
 */
export function getDate (date) {
  return date ? moment(date).format('YYYY-MM-DD') : ''
}

// md5编码
export function md5 (data) {
  data = typeof data === 'object' ? JSON.stringify(data) : data
  return md5Lib(data)
}

// base64编码
export const base64 = Base64

export function genUrl (params, url = '', dissAllowZero?) {
  let urlWithPathParams
  try {
    urlWithPathParams = pathToRegExp.compile(url)(params) || url
  } catch (error) {
    urlWithPathParams = url
  }
  var queryString: any = []
  var partial
  for (var p in params) {
    if (params[p] || (params[p] === 0 && !dissAllowZero)) { // 去掉无意义的参数
      partial = `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`
      queryString.push(partial)
    }
  }
  queryString = queryString.length ? (`?${queryString.join('&')}`) : ''
  return urlWithPathParams + queryString
}

export function compareTwoDateEqual (dateArr) { // 比较不同格式的两个时间的是否相等
  if (!dateArr || !dateArr.length || dateArr.length !== 2) return false
  const firstDate = (new Date(dateArr[0])).getTime()
  const secondDate = (new Date(dateArr[1])).getTime()
  return firstDate === secondDate
}

export function getOrganizationsObj (organizations) {
  const result = {}
  for (const org of organizations) {
    result[org.id] = org
  }
  return result
}

export function getVisibleOrganizations (organizations) {
  const visibleOrganizationIds = getLocalStorage('visibleOrganizations') || []
  const orgs = organizations.filter(org => {
    return !!visibleOrganizationIds.find(id => +id === +org.id)
  })
  const result = {}
  for (const org of orgs) {
    result[org.id] = org.name
  }
  return result
}

export function getVisibleOrganizationsWithPinyin (organizations): VisibleOrganizationMapWithPinyin {
  const visibleOrganizationIds = getLocalStorage('visibleOrganizations') || []
  const orgs = organizations.filter(org => {
    return !!visibleOrganizationIds.find(id => +id === +org.id)
  })
  const result = {}
  for (const org of orgs) {
    result[org.id] = {
      id: org.id,
      name: org.name,
      pinyin: org.namePinyin
    }
  }
  return result
}

export function getQueryObject (str?: any): any {
  // 如果传入自定义的对象字符串则解析这个字符串，否则解析url中的
  let search = str || location.search
  const ret = {}
  let pairs = []

  if (search) {
    pairs = search.slice(1).split('&')
  }
  _.each(pairs, item => {
    const arr = item.split('=')
    ret[arr[0]] = arr[1]
  })
  return ret
}

export function filterKeywordInArray (list, keyword) {
  return list.filter(str => keyword.toUpperCase().split('').reduce((index, ch) => index !== -1 ? str.toUpperCase().indexOf(ch, Math.max(index + 1), 0) : -1, -2) > -1)
}

export function filterByPinyin (input, option) {
  const baseWord = (option.props.children || '').toLowerCase()
  const basePinyin = (option.props.pinyin || '').toLowerCase()
  const keyword = (input || '').toLowerCase()
  const isChoosen = keyword.split('').reduce((index, ch) => index !== -1 ? baseWord.indexOf(ch, Math.max(index + 1), 0) : -1, -2) > -1 ||
  keyword.split('').reduce((index, ch) => index !== -1 ? basePinyin.indexOf(ch, Math.max(index + 1), 0) : -1, -2) > -1
  return isChoosen
}

export function showMeiqia () {
  // 是在线站点，而且是学生端，则允许美恰弹出，暂时的方案，肯定会改，先加入美恰
  // const { _MEIQIA } = window;
  // let isOnline = export const isOnline();
  // let isStudent = export const isStudent();
  // if (isOnline && isStudent) {
  //   if (!window.meiqiaLoaded) {
  //     window.meiqiaLoaded = true;
  //     _MEIQIA('init');
  //   }
  //   _MEIQIA('showPanel');
  // }
  // 目前只有一个美恰的地址。先写死。
  window.open('https://static.meiqia.com/dist/standalone.html?from=smart_adaptation&_=t&eid=21064')
}

export const isLogined = function () {
  let userId = parseInt(getCookie('userId'), 10)
  let token = getCookie('token')
  if (!userId || !token) {
    // 尝试取 ss_user 的cookie
    const ssUserCookie = getCookie('user', 'ss_')
    if (ssUserCookie) {
      try {
        const ssUser = JSON.parse(ssUserCookie)
        if (ssUser) {
          userId = ssUser.id
          token = ssUser.token
        }
      } catch (error) {
        // do nothing
      }
    }
  }
  return userId && token
}

// 获取用户能看到的系统的模块结构,每次登录成功后强制更新系统结构信息
export function getModules (nocache = false) {
  var fullModules: any = cloneObj(allFullModules)
  if (!isLogined()) return []
  let userTypeId = Number(getCookie('userTypeId'))
  if (userTypeId === 1) {
    const studentMenu = fullModules.student
    return studentMenu.filter(item => {
      if (item.text === '知识库') {
        if (global.IS_INSIDE && [88, 160].includes(getOrganizationId())) {
          return false
        }
      }

      if (item.show && typeof item.show === 'function') {

        return item.show()
      }
      return true
    })
  }
  if (userTypeId === 3) return fullModules.platform

  var modulePermission = getPermissionModules(nocache)
  if (!modulePermission.length) return []
  let modules = []
  fullModules.school.forEach(menu => {
    if (!menu.children) {
      modulePermission.indexOf(menu.key) > -1 && modules.push(menu)
    } else {
      menu.children = menu.children.filter(
        submenu => modulePermission.indexOf(submenu.key) > -1
      )
      menu.children.length && modules.push(menu)
    }
  })
  return Object.create(modules)
}

// 判断用户是否当前是否在平台层
export const isPlatform = function () {
  return parseInt(getCookie('userTypeId'), 10) === 3 && !parseInt(getCookie('organizationId'))
}

// 判断用户是否当前是否在总部层
export const isHeadquarter = function (orgId?: number): boolean {
  if (orgId) return config.headquartersId === orgId
  return Number(getCookie('isHeadquarters')) === 1
}

// 判断用户是否当前是否在教师端
export const isTeacher = function () {
  return +getCookie('userTypeId') === 2
}

export const isStudent = function () {
  return +getCookie('userTypeId') === 1
}

export const getCurrentUserBusiness = function () {
  return getLocalStorage('business')
}

// 默认可用时间的默认值
export const getDefaultAvailableTime = function () {
  if (global.IS_INSIDE) return [[], [], [], [], [], [], []]
  // 一天的可用时间默认值
  var defaultTime = [[8 * 60 + 30, 21 * 60]]
  var ret = []
  _.times(7, () => {
    ret.push(defaultTime)
  })

  return JSON.parse(JSON.stringify(ret))
}

export const removeEmptyValues = object => {
  for (let i in object) {
    if (!object[i]) delete object[i]
  }
  return object
}

// simple fetch wrapper deal with exception and code zero.
// use it like: fetch('get')({url: '/api', query: {a:1, b:2}, error: 'failed to get XXX'})
export const myFetch = (method) => ({ url, query = {}, data = {}, json = null, error, options = { credentials: 'same-origin' } }) => {
  url = query ? genUrl(query, url) : url
  const fetchOptions: any = { method, credentials: options.credentials }
  if (['put', 'post'].includes(method.toLowerCase())) {
    fetchOptions.headers = {
      'Content-Type': 'application/json'
    }
    if (json) { fetchOptions.body = JSON.stringify(json) }
  }
  const timezone = moment().utcOffset() / 60
  const fingerprint = getLocalStorage('fingerprint')
  const isAinAPI = url.startsWith('/api')
  fetchOptions.headers = isAinAPI ? {
    ...fetchOptions.headers,
    timezone,
    'X-APOL-VERSION': global.CLIENT_VERSION,
    'X-APOL-CLIENT': global.CLIENT_NAME,
    'X-APOL-BUILD-TIME': global.BUILD_TIME,
    fingerprint
  } : {}
  const res = fetch(url, fetchOptions)
    .then(res => res.json())
    .then(json => {
      if (json.code === 0) {
        return json.data
      }
      throw (json.msg || 'non-zero code!')
    })

  if (error) {
    let type = 'error'
    let msg = error
    if (error instanceof Object) {
      type = error.type || 'error'
      msg = error.msg || ''
    }
    return res.catch(err => {
      message[type] && message[type](`${msg}, ${err}!`, 2)
    })
  }
  return res
}

export const getSlices = async sliceIds => {
  if (!sliceIds || !sliceIds.length) return {}
  const ids = [].concat(sliceIds)
  const arr = []
  let res = {}
  while (ids.length > 20) {
    arr.push(ids.splice(0, 20))
  }
  arr.push(ids)
  const chunk = await Promise.all(arr.map(idChunk => rest.get(
    `${config.url.transcode}/api/slices/info?sliceIds=[${idChunk.join(',')}]`,
    { credentials: 'omit' }
  )))
  chunk.forEach(result => {
    if (result.code) {
      return message.error({
        text: '获取切片信息失败',
        msg: result.msg
      })
    }
    result.data.forEach(info => { res[info.id] = info })
  })
  return res
}

export const fetchSliceVersions = async sliceId => {
  const res = await rest.get(
    `${config.url.transcode}/versions?id=${sliceId}`,
    { credentials: 'omit' }
  )
  return res
}

export const fetchSliceProgress = async (userId, sliceId) => {
  if (!(sliceId instanceof Array)) {
    sliceId = [sliceId]
  }
  const MAX_SIZE_PER_REQUEST = 100
  const arr = _.chunk(sliceId, MAX_SIZE_PER_REQUEST)
  const res: any[] = await Promise.all(arr.map(ids => rest.get(genUrl({
    userId,
    sliceId: ids.join(',')
  }, `/api/video/progress`))))
  for (const item of res) {
    if (item.code) return message.error({ text: '切片进度获取失败', msg: item.msg })
  }
  const progress = res.reduce((result, item) => Object.assign(result, item.data.progress), {})
  return progress
}

export const fetchSliceInfo = async sliceId => {
  const res = await rest.get(
    `${config.url.transcode}/api/slices/info?sliceIds=[${sliceId}]`,
    { credentials: 'omit' }
  )
  if (res.code || !res.data.length) return message.error({ text: '切片信息获取失败', msg: res.msg })
  return res.data[0]
}

export const hasPermission = async (sliceId, type) => {
  const studentId = getCookie('userId')
  if (sliceId) {
    const url = `/api/video/permission?studentId=${studentId}&type=${type}&sliceId=${sliceId}`
    const res = await rest.get(url)
    if (res.code) {
      message.error({ text: '获取切片播放权限失败', msg: res.msg })
      return 'video_permission_error'
    }
    return res.data
  }
}

export const bytesToSize = bytes => {
  if (bytes === 0) return '0 B'
  if (!bytes) return '--'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return (bytes / (k ** i)).toPrecision(3) + sizes[i]
}

export const machineTime = time_ => {
  const time = Math.floor(time_)
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time - hours * 3600) / 60)
  const seconds = time - hours * 3600 - minutes * 60
  if (hours === 0) {
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

export const round = (num, decimal) => {
  if (isNaN(num)) {
    return 0
  }
  const p1 = Math.pow(10, decimal + 1)
  const p2 = Math.pow(10, decimal)
  return Math.round(num * p1 / 10) / p2
}

export const toFixed = (num, decimal) => {
  return round(num, decimal).toFixed(decimal)
}

export function today () {
  return moment().format('YYYY-MM-DD')
}

export const convertTimestampToDateString = (timeStamp, separator = '-') => {
  if (!timeStamp) return ''
  const year = new Date(timeStamp).getFullYear()
  const month = new Date(timeStamp).getMonth() + 1
  const day = new Date(timeStamp).getDay()
  return `${year}${separator}${String(month).padStart(2, '0')}${separator}${String(day).padStart(2, '0')}`
}

const ONE_HOUR_MINUTES = 60

export function convertMinuteToTime (minutes) {
  const hour = Math.floor(minutes / ONE_HOUR_MINUTES)
  const minute = (minutes % ONE_HOUR_MINUTES)
  return moment({ hour, minute }).format('HH:mm')
}

export function convertMinuteToTimeRange (minutes) {
  if (minutes[0] === undefined) {
    return ''
  }
  const startTime = convertMinuteToTime(minutes[0])
  const endTime = convertMinuteToTime(minutes[1])
  return `${startTime}-${endTime}`
}

/**
 * 获取某一个值的具体类型
 */
export const getType = function (obj) {
  var type = Object.prototype.toString.call(obj).match(/^\[object (.*)\]$/)[1].toLowerCase()
  if (type === 'string' && typeof obj === 'object') return 'object'
  if (obj === null) return 'null'
  if (obj === undefined) return 'undefined'
  return type
}

export function getSource() {
  const smartSource = global.IS_INSIDE ? SMART_INSIDE_SOURCE : SMART_SOURCE
  return smartSource
}
/**
 * 获取访问题库时的url参数
 */
export function getTikuQs () {
  const source = getSource()
  const result: any = {
    organizationId: getOrganizationId(),
    source: source,
    from: source,
    token: getCookie('token'),
    online: isOnline() ? 1 : 0
  }
  if (isTeacher()) {
    result.role = 'teacher'
  }
  return result
}

/**
 * 获取访问单词检测时的url参数
 */
export function getTiWordQs () {
  const result: any = {
    source: 'smart',
    from: 'smart',
    token: getCookie('token')
  }
  return result
}

// 这个应该算是个helper
export const genPracticeReportURL = (params, exerciseId, type) => {
  if (['new', 'new_practice', 'new_question'].includes(type)) {
    params = {
      ...(getTikuQs()),
      ...params
    }
  } else if (type === 'old_practice') {
    params = {
      ...(getTikuQs()),
      no_header: 1,
      no_footer: 1,
      no_meiqia: 1,
      ...params
    }
  } else {
    params = {
      ...(getTikuQs()),
      ...params
    }
  }
  let baseURL = ''
  switch (type) {
    case 'old_question':
      baseURL = `${config.url.exerciseV2}/spexam/answer`
      break
    case 'old_practice':
      baseURL = `${config.url.www}/spexam/report.html`
      break
    case 'new_practice':
      baseURL = `${config.url.newExercise}/report/practice/${exerciseId}`
      break
    case 'new_question':
      baseURL = `${config.url.newExercise}/report/question/${exerciseId}`
      break
    default:
      break
  }
  return genUrl(params, baseURL)
}

export const disabledDate = current => current && current.valueOf() < Date.now()

export const _get = (obj, keys, defaultValue = null) => {
  if (!obj) return defaultValue
  let temp = obj
  while (keys.length) {
    const key = keys.shift()
    if (!temp[key]) return defaultValue
    temp = temp[key]
  }
  return temp
}

export const delay = async time => new Promise(resolve => setTimeout(resolve, time))

export const confirm = async params => new Promise(resolve => Modal.confirm(Object.assign({}, params, {
  title: params.title || '提示',
  onOk: () => resolve(true),
  onCancel: () => resolve(false)
})))

export const get = myFetch('get')
export const put = myFetch('put')
export const post = myFetch('post')

export function getToken () {
  return getCookie('token') || null
}

export function getUserId () {
  return Number(getCookie('userId')) || null
}

export function getUserName () {
  return getCookie('userName') || null
}

const _INTERNAL_ADMIN_NAMES = ['雨晴', '王云', '朝博', '李丹']

export function isInternalAdmin() {
  const currentUserName = getUserName()
  for (const admin of _INTERNAL_ADMIN_NAMES) {
    if (String(currentUserName).includes(admin)) {
      return true
    }
  }
  return false
}

export function crossTeacherVisible () {
  return !!Number(getCookie('crossTeacherVisible', ''))
}

export function getOriginUserId () {
  return Number(getCookie('originUserId')) || null
}

export function getUserTypeId () {
  return Number(getCookie('userTypeId')) || null
}

export function getOrganizationId () {
  return Number(getCookie('organizationId')) || null
}

export function getStudentServeType () {
  return Number(getCookie('serveType')) || null
}

let realRedirect = null
let realRefresh = null
let realCreateModal = null
let realCreateModalForm = null
let realCreateModalConfirm = null
let realCreateTip = null

export const createModalForm = (options: any) => {
  realCreateModalForm && realCreateModalForm(options)
}

export function setCreateModalForm (func) {
  realCreateModalForm = func
}

export const createModalConfirm = (options: any) => {
  realCreateModalConfirm && realCreateModalConfirm(options)
}

export function setCreateModalConfirm (func) {
  realCreateModalConfirm = func
}

export const createTip = (options: any) => {
  realCreateTip && realCreateTip(options)
}

export function setCreateTip (func) {
  realCreateTip = func
}

export const redirect = (url?, replace?) => {
  realRedirect && realRedirect(url, replace)
}

export function setRedirect (func) {
  realRedirect = func
}

export const refresh = () => {
  realRefresh && realRefresh()
}

export function setRefresh (func) {
  realRefresh = func
}
export const createModal = (config?) => {
  realCreateModal && realCreateModal(config)
}

export function setCreateModal (func) {
  realCreateModal = func
}

export function logout (refresh = false, role?) {
  // 更新socket身份信息
  socketHelper.updateSocketAuthorization(false)

  let keys = document.cookie.match(/[^ =;]+(?=\=)/g)
  if (keys) {
    for (let i = 0; i < keys.length; i++) {
      if (global.NODE_ENV === 'production') {
        if (global.IS_INSIDE) {
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.smartxue.com`
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=${location.hostname}`

          // For release env cookie
          if (location.hostname && location.hostname.indexOf('smartxue.com') === -1) {
            document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.smartstudy.com`
            document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.yun.dev.smartstudy.com`
            document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=${location.hostname}`
          }
        } else {
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.smartstudy.com`
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.sp.smartstudy.com`

          // For release env cookie
          if (location.hostname && location.hostname !== 'sp.smartstudy.com') {
            document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.sp.dev.smartstudy.com`
          }
        }
      } else {
        if (global.IS_INSIDE) {
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.smartstudy.com`
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.yun.dev.smartstudy.com`
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=${location.hostname}`
        } else {
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.smartstudy.com`
          document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/;domain=.sp.dev.smartstudy.com`
        }
      }
      document.cookie = `${keys[i]}=0;expires=${new Date(0).toUTCString()};path=/`
    }
  }
  // 删除权限数据
  removeLocalStorage('permissionModules')
  removeLocalStorage('permissionOperations')
  removeLocalStorage('visibleOrganizations')
  removeLocalStorage('org')
  removePermissionModules()
  resetFullModules()
  redirect('/')
  removeLocalStorage('business')
  if (!refresh) {
    if (role === 'admin') {
      redirect('/admin')
    } else if (global.IS_INSIDE) {
      role && sessionStorage.setItem('inside_auth', role)
      redirect('/')
    } else {
      role === 'teacher' ? redirect('/teacher') : redirect('/')
    }
  } else {
    window.location.reload()
  }
  removeLocalStorage('business')
}

export function checkMultiAudioPigaiReport(pigai) {
  if (!pigai) return false
  return _.some(pigai, item => item.status === PIGAIED)
}
