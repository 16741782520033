
import constants from 'common/constants'
const map = {
  // 性别
  sex: {
    0: '未知',
    1: '男',
    2: '女'
  },
  // 信息可使用状态
  status: {
    1: '正常',
    2: '冻结'
  },

  studyStage: [
    { id: 1, name: '基础段' },
    { id: 2, name: '强化段' },
    { id: 3, name: '冲刺段' },
    { id: 4, name: '预备段' }
  ],

  crmOfflineProductTypes: {
    1: '个性化定制',
    2: '全程托管',
    3: '全程计划',
    4: '班课',
    5: 'IVY',
    6: '精英计划',
    7: '批改服务',
    8: '商务英语',
    9: '其他'
  },

  atheneOfflineProductTypes: {
    1: '全程托管',
    2: '个性定制',
    3: '全托管小班',
    4: '成人无限时',
    5: '试听课',
    6: '高中留学申请',
    7: '本科留学申请',
    8: '研究生留学申请'
  },

  classroomType: {
    1: '线上教室',
    2: '线下教室',
    3: '线上教室&线下教室'
  },
  // 合同的可用状态1待处理 2已接受 3已驳回
  transferStatus: {
    1: '待处理',
    2: '服务中',
    3: '已驳回',
    4: '已结课',
    6: '待分配教学PT',
    7: '待填写排课须知',
    8: '待分配服务PT'
  },
  // 教师可使用类型
  classroomUsage: {
    1: '班课',
    2: '一对一',
    3: '班课&一对一'
  },
  country: {
    us: '美国',
    cn: '中国',
    japan: '日本',
    fr: '法国'
  },
  // 转案合同享受的服务
  transferServer: {
    offline: [
      { id: 'specialist', name: '系统专家课程(在线)' },
      { id: 'vipCourseHours', name: 'VIP面授课' },
      { id: 'basis', name: 'PT基础辅导课' },
      { id: 'pigai', name: '口语/写作批改' },
      { id: 'task', name: '作业' },
      { id: 'tongue', name: '外教口语' },
      { id: 'plan', name: '留学规划课（定期）' },
      { id: 'vocabulary', name: '词汇辅导' }
    ],
    online: [
      { id: 'vipCourseHours', name: '1vs1' },
      { id: 'pigai', name: '批改' },
      { id: 'ztjjCount', name: '逐题精讲' },
      { id: 'liveCourseCount', name: '专项直播课' },
      { id: 'entryTestingCount', name: '入学测试' },
      { id: 'examGuidanceCount', name: '备考规划' },
      { id: 'supervisoryCourseCount', name: '督导课' }
    ]
  },

  // 做新练习
  doExam: {
    1: 'toefl',
    2: 'ielts',
    3: 'gre',
    5: 'gmat',
    6: 'sat',
    7: 'kaoyan',
    8: 'cet4',
    9: 'cet6'
  },

  // 机构可调用资源
  organizationResource: {
    courseExaminationIds: '专家课程'
  },
  systemList: {
    systems: '系统分类'
  },
  exam: {// 考试分类

  },
  subject: { // 科目

  },
  subjectWithExam: {// 带考试分类的科目名称与科目的对应关系

  },
  org: { // 组织

  },
  // 订单类型
  purchasingType: {
    6: '首次购买',
    4: '复购',
    3: '续费',
    7: '首次购买(PT推荐)'
  },
  // 语言
  language: {
    'zh-CN': '中文',
    'en-US': 'English'
  }
}

const getName = function (enumType: string, value: any, fallback?: any) {
  return (map[enumType] && map[enumType][value]) || fallback || '未知'
}
const getNames = function (enumType, valArr) {
  valArr = valArr || []
  var names = []
  if (map[enumType]) {
    names = valArr.map(value => ((map[enumType] && map[enumType][value]) || '未知'))
  }

  return names
}

// 添加新的枚举类型（主要用于处理那些从服务器获取的枚举信息，例如 学校、科目）
const add = function (enumType, enumMap) {
  map[enumType] = { ...enumMap }
}

const allSubjects = {}
var configLoaded = false
var _configs = null

const loadConfigs = function (configs) {
  if (configLoaded) return
  _configs = configs
  var examList = configs.exams.list
  var subjectList = configs.subjects.list
  var organizationsList = configs.organizations.list

  var examMap = {}
  var subjectMap = {}
  var subjectWithExamMap = {}
  var organizationsMap = {}

  examList.forEach(item => {
    examMap[item.id] = item.name
  })

  subjectList.forEach(item => {
    allSubjects[item.id] = item
    subjectMap[item.id] = item.name
    subjectWithExamMap[item.id] = examMap[item.examinationId] + item.name
  })
  organizationsList.forEach(item => {
    organizationsMap[item.id] = item.name
  })

  map.exam = examMap
  map.subject = subjectMap
  map.subjectWithExam = subjectWithExamMap
  map.org = organizationsMap
}

const getSubjects = function (examId?: number) {
  return examId ? _configs.subjects.list.filter(item => item.examinationId == examId).map(item => {
    return { ...item }
  }) : _configs.subjects.list.map(item => {
    return { ...item }
  })
}

const getExams = function () {
  return _configs.exams.list.map(item => Object.assign({}, item, { subjects: getSubjects(item.id) }))
}
const getbusiness = function () {
  const bussnessM = [
    { id: 1, name: 'APOLLON' },
    { id: 2, name: 'CRM' }
  ]
  return bussnessM.map(item => {
    return { ...item }
  })
}
const getOrganizations = function () {
  return _configs.organizations.list.map(item => {
    return { ...item }
  })
}

const getSlotName = id => {
  const slot = constants.TIME_SLOTS.find(k => k.slot === parseInt(id))
  return slot && slot.name
}

export {
  map,
  loadConfigs,
  getName,
  getbusiness,
  getNames,
  getSubjects,
  getExams,
  getOrganizations,
  allSubjects,
  add,
  getSlotName
}
