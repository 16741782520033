import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import * as rest from 'common/rest'
import { message, Icon, Form, Input } from 'antd'
import PasswordModal from './password_modal'
import CommonModal from 'components/common/common_modal'
import VacationModal from 'components/common/vacation_modal'
import WechatAccountContainer from './wechat_account_promotion/container'
import socket from 'common/socket'
import * as vacationHelper from 'helpers/vacation'
import * as userHelper from 'helpers/user'
import * as avatarHelper from 'helpers/avatar'

const Search = Input.Search
const VACATION_TIMES = 3

const linksConfig = [
  { name: '我的会员', url: '/user/vip.html', type: 'gerenxinxi' },
  { name: '我的订单', url: '/user/order.html', type: 'order' },
  { name: '我的券卡', url: '/user/card/1.html', type: 'coupon' },
  { name: '账号管理', url: '/user/account.html', type: 'ctqa' }
]

class StudentHeader extends React.Component<any, any> {
  state = {
    secondLevel: null,
    modalVisible: false,
    ticking: false,
    vacationModalAlertVisible: false,
    vacationModalVisible: false,
    examsMenuVisible: false,
    orgMenuVisible: false,
    orgs: null,
    fetchingOrgs: false
  }
  isOnline = utils.isOnline()

  componentDidMount = async () => {
    await this.fetchUserInfo()
    await this.props.getCount()
    socket.on('SMART_ADAPTATION_MESSAGE', async () => {
      await this.props.getCount()
    })
  }

  handleModifyPassword = () => this.setState({ modalVisible: true })

  handleGetCaptcha = async () => {
    const phone = utils.getLocalStorage('userAccount')
    const res = await rest.post('/api/user/sms', { phone })
    if (res.code) {
      return message.error('验证码发送失败， 请重试')
    }
    return this.setState({ ticking: true })
  }

  handleVacation = async isAbsencing => {
    if (!isAbsencing && !(this.props.profile.data.absenceTimes < VACATION_TIMES)) {
      return this.setState({
        vacationModalAlertVisible: true
      })
    }
    this.setState({
      vacationModalVisible: true
    })
  }

  handleConfirmVacation = async () => {
    vacationHelper.handleConfirmVacation(this.props.profile.data.isAbsencing, () => { this.setState({ vacationModalVisible: false }) })
  }

  handleHideModal = () => {
    this.setState({ secondLevel: '' })
  }

  handleShowModal = () => {
    this.setState({ secondLevel: 'user' })
  }

  getOrgName = () => {
    const { orgMenuVisible } = this.state
    if (!this.props.organizations ||
      !this.props.organizations.list ||
      !this.props.organizations.list.length) return null

    let orgId = utils.getOrganizationId()
    if (!orgId) return null
    let orgName = ''
    this.props.organizations.list.forEach(org => {
      if (org.id === orgId) {
        orgName = utils.isOnline(org.id) ? '智课网学习中心' : org.name
      }
    })
    if (!orgName) return null
    return (<div
      className={`org ${orgMenuVisible ? 'org-search' : ''}`}
      onMouseOver={this.handleShowOrgMenu}
      onMouseLeave={this.handleHideOrgMenu}>
      { !orgMenuVisible ? <Icon type='home' /> : '' }
      { orgMenuVisible ? <Search autoFocus placeholder={orgName} /> : orgName }
      {
        orgMenuVisible ? (<div className='org-menu' onMouseLeave={this.handleHideOrgMenu}>
          <div className='org-menu-inner'>
            {this.getSitesMenu()}
          </div>
        </div>) : null
      }
    </div>)
  }

  getSitesMenu = () => {
    const { orgs } = this.state
    const orgId = utils.getOrganizationId()
    const organizations = this.props.organizations.list
    const orgArray = []
    for (let i = 0; i < orgs.length; i++) {
      if (orgs.includes(orgs[i])) {
        const org = organizations.filter(item => item.id == orgs[i])
        orgArray.push(org)
      }
    }
    return orgArray.length
      ? orgArray.map(item => (
        <span key={item[0].id} onClick={() => this.handleChangeSite(item[0].id)} className={`site-link ${item[0].id === orgId ? 'selected' : ''}`}>
          {utils.isOnline(item[0].id) ? '智课网学习中心' : item[0].name}
        </span>
      ))
      : <span className='site-link no-site-link'>暂无数据</span>
  }

  handleChangeSite = id => {
    this.setState({ orgMenuVisible: false })
    this.props.onChangeSite(id)
  }

  handleShowOrgMenu = async () => {
    if (global.IS_INSIDE) return false
    this.setState({ fetchingOrgs: true })
    if (this.state.orgMenuVisible) return
    const url = '/api/student/available-organization'
    const res = await rest.get(url)
    if (res.code) {
      return message.error({ text: '获取可切换站点失败', msg: res.msg })
    }
    if (this.state.fetchingOrgs) {
      this.setState({ orgMenuVisible: true, orgs: res.data })
    }
  }

  handleHideOrgMenu = () => {
    if (global.IS_INSIDE) return false
    this.setState({ orgMenuVisible: false, keyword: '', fetchingOrgs: false })
  }

  handleHideExamsMenu = () => {
    this.setState({ examsMenuVisible: false })
  }

  handleShowExamsMenu = () => {
    this.setState({ examsMenuVisible: true })
  }

  renderLinks = () => {
    return linksConfig.map(item => <span
      className='item'
      key={item.type}
      onClick={() => window.open(`${config.url.www}${item.url}`, '_blank')}
    >
      <svg className='icon' aria-hidden='true'>
        <use xlinkHref={`#icon-${item.type}`} />
      </svg>
      {item.name}
    </span>)
  }

  fetchUserInfo = async () => {
    await rest.get('/api/user/refresh')
  }

  renderModals () {
    const { ticking, vacationModalVisible } = this.state
    const { isAbsencing } = this.props
    const userAccount = utils.getLocalStorage('userAccount')
    let isPhoneLogin
    if (userAccount) {
      isPhoneLogin = userAccount.indexOf('@') === -1
    } else {
      isPhoneLogin = null
    }
    return <React.Fragment>
      { this.state.modalVisible ? <PasswordModal
        title='修改密码'
        visible
        form={this.props.form}
        getCaptcha={() => this.handleGetCaptcha()}
        resendCaptcha={() => this.setState({ ticking: false })}
        ticking={ticking}
        closeModal={() => this.setState({ modalVisible: false, ticking: false })}
        isPhoneLogin={isPhoneLogin}
        userAccount={userAccount}
        modifyPassword={values => userHelper.modifyPassword(values, isPhoneLogin, () => {
          this.setState({ modalVisible: false, ticking: false })
          this.props.form.resetFields()
        })}
      /> : null }
      {
        utils.isOnline()
          ? <CommonModal
            title='提示'
            visible={this.state.vacationModalAlertVisible}
            handleOk={() => this.setState({ vacationModalAlertVisible: false })}
            handleCancel={() => this.setState({ vacationModalAlertVisible: false })}
            header='您今年已累计请假3次或满90天，不可再请假'
            footer={null}
          />
          : null
      }
      {
        utils.isOnline()
          ? <VacationModal
            isAbsencing={isAbsencing}
            visible={vacationModalVisible}
            handleOk={this.handleConfirmVacation}
            handleCancel={() => this.setState({ vacationModalVisible: false })}
          />
          : null
      }
    </React.Fragment>
  }

  render () {
    const { count } = this.props
    const { isAbsencing } = this.props
    const avatarImg = avatarHelper.getAvatar()
    return (
      <div
        className='student-header-container'
        style={{ display: this.props.topLevel ? 'block' : 'none' }}
      >
        <div className='user' onMouseLeave={this.handleHideModal} >
          <img onMouseEnter={this.handleShowModal} src={avatarImg} />
          { isAbsencing ? <img className='vacationing' src={require('images/vacationing.png')} alt='' /> : null }
        </div>
        {
          this.isOnline
            ? <span className='notification' onClick={this.props.watchMessage}>
              <span>
                <span className='notification-pic' />
                { count > 0 ? <span className='notification-count' /> : '' }
              </span>
            </span> : ''
        }
        { this.getOrgName() }
        {
          <div className='follow-wechat-account'>
            <WechatAccountContainer type='student' />
          </div>
        }
        <div
          className='panel'
          style={{ display: this.state.secondLevel === 'user' ? 'block' : 'none' }}
          onMouseEnter={this.handleShowModal}
          onMouseLeave={this.handleHideModal}
        >
          <div className='profile'>
            <img src={avatarImg} />
            <span className='name'>{utils.getUserName()}</span>
            <span className='trangle' />
          </div>
          { this.isOnline ? this.renderLinks() : null }
          { this.isOnline ? <span
            className='item'
            onClick={() => this.handleVacation(isAbsencing)}
          >
            { this.isOnline ? <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#icon-vacation' />
            </svg> : <Icon type='calendar' />}
            我要{`${isAbsencing ? '销假' : '请假'}`}
          </span> : null }
          { !this.isOnline
            ? <span className='item' onClick={this.handleModifyPassword}>
              <Icon type='lock' />
              修改密码
            </span> : null
          }
          <span
            className='item'
            onClick={() => utils.logout(false, 'student')}
          >
            { this.isOnline ? <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#icon-logout' />
            </svg> : <Icon type='poweroff' />}
            退出
          </span>
        </div>
        {this.renderModals()}
      </div>
    )
  }
}

export default Form.create()(StudentHeader) as any
