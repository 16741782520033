import * as _ from 'lodash'
import * as utils from 'common/utils'
import qs from 'qs'
import createHistory from 'history/createBrowserHistory'

const history = createHistory()
const originalHistoryPushFunction = history.push

history.push = data => {
  if (_.isObject(data)) {
    const { pathname, query } = data as any
    let search = ''
    if (query) {
      search = utils.genUrl(query)
    }
    return originalHistoryPushFunction({
      pathname,
      search
    })
  }
  if (_.isString(data)) {
    return originalHistoryPushFunction(data)
  }
}

const addQuery = history => {
  const location = history.location
  history.location = { ...location, query: qs.parse(location.search, { ignoreQueryPrefix: true }) }
}

addQuery(history)

history.listen(() => {
  // 每次页面跳转都会执行
  addQuery(history)
})

export default history
