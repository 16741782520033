import * as React from 'react'

import { message } from 'antd'
const createReactClass = require('create-react-class')

// 组件
const ModalConfirm = createReactClass({
  // 获取初始状态
  getInitialState () {
    return {
      maskOpacity: 0,
      formOpacity: 0,
      formMarginTop: -50 + (this.props.top || 0)
    }
  },

  // 组件加载
  componentDidMount () {
    let self = this

    setTimeout(function () {
      self.setState({
        maskOpacity: 0.5,
        formOpacity: 1,
        formMarginTop: 0 + (self.props.top || 0)
      })
    }, 50)
  },

  // 渲染
  render () {
    return (
      <div className='common-modal-form'>
        <div className='wrapper'>
          <div className='form' style={{ opacity: this.state.formOpacity, marginTop: this.state.formMarginTop || 0 }}>
            <div className='title'>{this.props.type}</div>

            <div className='confirm'>
            您确认{this.props.type}<span className='name'>{this.props.name}</span>吗？
            </div>

            <div className='buttons'>
              <span className='button cancel' onClick={this.close}>取消</span>
              <span className='button submit' onClick={this.submit}>
                {this.state.submitting ? '处理中' : '确认'}
              </span>
            </div>
          </div>

          <div className='mask' onClick={this.close} style={{ opacity: this.state.maskOpacity }} />
        </div>
      </div>
    )
  },

  // 关闭
  close () {
    this.setState({
      maskOpacity: 0,
      formOpacity: 0,
      formMarginTop: -50 + (this.props.top || 0)
    })

    let self = this
    setTimeout(function () {
      self.props.close()
    }, 150)
  },

  // 提交
  submit () {
    if (this.state.submitting) return

    this.setState({
      submitting: true
    })
    this.props.submit({ id: this.props.id }, this.handleResult())
  },

  // 处理结果
  handleResult () {
    let self = this

    return function (err, res) {
      if (err) {
        self.setState({
          submitting: false
        })
        self.close()
        return
      }

      self.close()
      message.success('操作成功')
      if (self.props.refresh) {
        self.props.refresh(res)
      }
    }
  }
})

export default ModalConfirm
