import * as React from 'react'
import { Select, Input } from 'antd'
const Option = Select.Option
export default class AreaCodeInput extends React.Component<any, any> {
  myTextInput: any

  state = {
    countries: [],
    currentCountryCode: '86',
    userPhone: ''
  }

  componentDidMount () {
    const res = this.props.codes
    let data = res.length != 0 ? res : []
    this.setState({
      countries: data
    }, () => {
      const defaultValue = this.props.defaultValue || ''
      const code = !defaultValue ? '86' : defaultValue.indexOf('-') !== -1 ? String(defaultValue.split('-')[0]) : '86'
      this.setState({
        currentCountryCode: code,
        userPhone: defaultValue.split('-')[1]
      })
    })
  }

  handleChangeCountry = (value, isSide = false) => {
    this.setState({
      currentCountryCode: value
    }, () => {
      if (isSide) {
        this.props.onChange(`${this.state.currentCountryCode}-${this.myTextInput.input.value}`)
      }
    })
  }

  getValue = () => {
    const originalValue = this.state.userPhone || ''
    if (originalValue.includes('@')) {
      return originalValue
    }
    const value = `${this.state.currentCountryCode}-${originalValue}`
    if (/^\d+$/.test(originalValue.replace(/(^\s*)|(\s*$)/g, ''))) {
      return value
    } else if (originalValue === '') {
      return 'emptyNum'
    }
    return 'invalidNum'
  }

  renderCountry (item) {
    if (item.combine) {
      let str = ''
      for (let i = 0; i < item.children.length; i++) {
        str += `${item.children[i].country_name} ${item.children.length - 1 !== i ? '/ ' : ''}`
      }
      return str
    }
    return item.country_name
  }

  changeUser = (currentCountryCode, value) => {
    this.setState({ userPhone: value })
    if (!this.props.noOnChange) {
      this.props.onChange(`${currentCountryCode}-${value}`)
    }
  }

  render () {
    const { countries } = this.state
    let finalCountries = []
    if (countries.length) {
      finalCountries = countries.filter(el => el.country_code !== '1')
      const usCountries = countries.filter(el => el.country_code === '1')
      if (usCountries.length) {
        let newItem: any = {}
        newItem.country_code = '1'
        newItem.combine = true
        newItem.children = []
        for (var i = 0; i < usCountries[0].children.length; i++) {
          newItem.children[i] = {}
          newItem.children[i].alpha2 = usCountries[0].children[i].alpha2
          newItem.children[i].alpha3 = usCountries[0].children[i].alpha3
          newItem.children[i].country_name = usCountries[0].children[i].country_name
        }
        finalCountries.unshift(newItem)
      }
    }
    const defaultValue = this.props.defaultValue
    const value = !defaultValue ? '' : defaultValue.indexOf('-') === -1 ? defaultValue : defaultValue.split('-')[1]
    const { readOnly, isForm, noOnChange, noBorder, isIndex, vipOnly } = this.props
    return (
      <div className={`area-code-container ${isForm ? 'form' : ''} ${noBorder ? 'no-border' : ''} ${isIndex ? 'is-index' : ''} ${vipOnly ? 'vip-only' : ''}`}>
        <Input
          className='username-input'
          disabled={readOnly}
          defaultValue={value}
          addonBefore={<div className='area-code-select-container'>
            <Select
              className='area-code-select'
              value={(this.state.currentCountryCode)}
              filterOption={(input, option) => (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp='children'
              optionLabelProp="label"
              showSearch
              disabled={readOnly}
              onSelect={noOnChange ? (e) => this.handleChangeCountry(e) : e => this.handleChangeCountry(e, true)}
            >
              {
                finalCountries.map(item => <Option
                  // className={item.country_code} // TODO: 因为TS显示不支持，所以暂时注释。需要检查
                  value={`${item.country_code}`}
                  label={`+${item.country_code}`}
                  key={`${item.alpha3}`}
                >
                  {`+${String(item.country_code)} ${this.renderCountry(item)}`}
                </Option>)
              }
            </Select>
          </div>}
          onKeyDown={this.props.onKeyDown}
          ref={(ref) => { this.myTextInput = ref }}
          // onChange={noOnChange ? null : e => this.props.onChange(`${this.state.currentCountryCode}-${e.target.value}`)}
          onChange={e => this.changeUser(this.state.currentCountryCode, e.target.value)}
        />
      </div>)
  }
}
