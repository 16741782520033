import * as React from 'react'
import constants from 'common/constants'
import { connect } from 'react-redux'
import { Tabs, message, Button, Icon } from 'antd'
import ReportTable from 'components/school/evaluation_report/report_table'
import * as rest from 'common/rest'

const TabPane = Tabs.TabPane

export class TaskStudyPlan extends React.Component<any, any> {
  state = {
    studentId: '',
    plan: [],
    hasFetchPlan: false
  }

  componentDidMount = async () => {
    this.setState({
      studentId: this.props.studentId
    }, this.fetchPlan)
  }

  async UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    if (this.props.studentId != nextProps.studentId) {
      this.setState({
        studentId: nextProps.studentId
      })
      await this.fetchPlan()
    }
  }

  fetchPlan = async () => {
    const { studentId } = this.state

    if (!studentId) {
      this.setState({
        plan: [],
        hasFetchPlan: true
      })
      return
    }

    const res = await rest.get(`/api/student/${studentId}/plans`)
    if (res.code) return message.error({ text: '获取学习计划失败', msg: res.msg })

    this.setState({
      plan: res.data,
      hasFetchPlan: true
    })
  }

  renderSubjectName = (key) => {
    const subjectName = this.props.subjects.filter(i => i.id == key)[0].name
    for (let i in constants.PROGRESS_SUBJECT) {
      if (i == subjectName) {
        return constants.PROGRESS_SUBJECT[i]
      }
    }
  }
  capitalize = ([first, ...rest]) => {
    return first.toUpperCase() + rest
  }

  renderTable (contentItem) {
    if (!contentItem) return <div />
    return (
      <div>
        <ReportTable
          isStudyPlan={!this.props.isStudentPlan}
          title={contentItem.roundName}
          data={contentItem.pointList}
        />
      </div>
    )
  }

  updatePlan = async () => {
    const studentId = this.state.studentId
    const res = await rest.post('/api/student/plan', { studentId })
    if (res.code) return message.error({ text: '', msg: res.msg })
    message.success('学习计划更新成功')
    await this.fetchPlan()
  }

  render () {
    const { plan, hasFetchPlan } = this.state
    if (hasFetchPlan && !plan.length) {
      return <div className='study-plan-empty'><Icon type='file' className='icon' /><p>暂无内容</p></div>
    }
    return (
      <div className='task-study-plan-container'>
        {this.props.isStudentPlan
          ? <div className='student-plan-head'>
            <h3>{this.props.studentName}智能学习计划</h3>
          </div>
          : <Button type='primary' onClick={this.updatePlan} className='update-plan'>更新计划</Button>
        }
        <Tabs className='study-plan-tabs' defaultActiveKey='1'>
          {
            plan.map((item) => (
              <TabPane tab={<span className={this.renderSubjectName(item.subjectId)}>{this.capitalize(this.renderSubjectName(item.subjectId))}</span>} key={item.subjectId}>
                {
                  item.content.map(contentItem => this.renderTable(contentItem))
                }
              </TabPane>
            ))
          }
        </Tabs>
      </div>
    )
  }
}

export default connect(state => ({
  subjects: state.config.subjects.list
}))(TaskStudyPlan)
