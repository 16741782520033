import React from 'react'
import _ from 'lodash'
import Question from 'components/school/questionaire/question'
import { questionTitleFactory, questionInputTitles } from 'common/questionaireConfig'
import { message } from 'antd'
import Loading from 'components/common/common_loading'
import { connect } from 'react-redux'
import * as rest from 'common/rest'
import moment from 'moment'
import * as utils from 'common/utils'
import constants from 'common/constants'
const { SENT, SUBMITTED, EXPIRED } = constants.QUESTIONAIRE_STATUS
const { TRUSTEESHIP, PERSONAL_TAILOR } = constants.QUESTIONAIRE_TYPE

const imgType = {
  1: 'vip',
  2: 'personal',
  3: 'banke'
}

const PT_QUESTION_LIST = ['taskList', 'daily', 'management', 'feedback']

class Questionaire extends React.Component<any, any> {
  state = {
    data: null,
    submitted: false,
    loading: true
  }

  async componentDidMount () {
    await this.fetchQuestionaire()
  }

  fetchQuestionaire = async () => {
    const id = _.get(this.props, ['location', 'query', 'id'])
    if (!id) {
      message.error('无问卷id，返回首页')
      return this.props.history.push('/')
    }
    const res = await rest.get(`/api/quality-test/${id}`)
    let ptScore
    if (res.code !== 0) {
      message.error({
        text: '问卷详情获取失败',
        msg: res.msg
      })
    } else {
      const questionaire = res.data
      const { version, type, status, examinationId, sendTime, teachers, ptName } = questionaire
      const teachersArr = []
      for (let teacherId in teachers) {
        teachersArr.push({
          subjectId: +teacherId,
          name: teachers[teacherId].name
        })
      }
      if ([SENT, EXPIRED].includes(questionaire.status)) {
        ptScore = {
          daily: null,
          management: null,
          feedback: null,
        }
        if (questionaire.type === TRUSTEESHIP) {
          ptScore.taskList = null
        }
        const baseScoreObject = Object.assign({}, res.data.courseTeacherIds)
        for (let i in baseScoreObject) {
          baseScoreObject[i] = null
        }
        this.setState({
          data: {
            version,
            type,
            status,
            examId: examinationId,
            date: moment(sendTime).format('YYYY-MM'),
            ptName,
            teachers: teachersArr,
            ptScore: { ...ptScore },
            abilities: { ...baseScoreObject },
            atmosphere: { ...baseScoreObject },
            timeliness: { ...baseScoreObject },
            advice: '',
            favoriteTeacher: ''
          },
          loading: false
        })
      } else if (status === SUBMITTED) {
        this.setState({
          data: {
            version,
            type,
            status,
            teachers: teachersArr,
            examId: examinationId,
            date: moment(sendTime).format('YYYY-MM'),
            ptName,
            ...questionaire.results
          },
          loading: false
        })
      }
    }
  }

  handleMergeScore = questionData => {
    this.setState(prevState => ({
      data: _.merge(prevState.data, questionData)
    }))
  }

  renderQuestions = () => {
    const { data } = this.state
    const { subjects, examinations } = this.props
    const examName = ((examinations.find(exam => exam.id === data.examId) || {}) as any).name || ''
    let questionIndex = 0
    const ptName = data.ptName
    const ptScore = data.ptScore
    const ptKeys = Object.keys(ptScore)
    const isStudent = utils.isStudent()
    const ptQuestions = PT_QUESTION_LIST.filter(el => ptKeys.includes(el)).map((ptKey) => {
      questionIndex = questionIndex + 1
      const titleText = questionTitleFactory(ptKey, ptName)
      return (
        <Question
          title={titleText}
          index={questionIndex}
          type={ptKey}
          onChange={this.handleMergeScore}
          score={data.ptScore[ptKey]}
          mode={(data.status === SUBMITTED || !isStudent) ? 'view' : 'create'}
        />
      )
    })
    const teacherKeys = ['abilities', 'atmosphere', 'timeliness']
    const teacherQuestions = teacherKeys.map((teacherKey) => {
      questionIndex = questionIndex + 1
      const titleText = questionTitleFactory(teacherKey, ptName, examName)
      const scoreData = data[teacherKey]
      return (
        <Question
          title={titleText}
          index={questionIndex}
          subjects={subjects}
          type={teacherKey}
          score={scoreData}
          onChange={this.handleMergeScore}
          teachers={data.teachers}
          mode={(data.status === SUBMITTED || !isStudent) ? 'view' : 'create'}
        />
      )
    })
    const inputQuestions = ['favoriteTeacher', 'advice'].map((question) => {
      const { data } = this.state
      return (
        <Question
          title={questionInputTitles[question]}
          index={++questionIndex}
          text={data[question]}
          type={question}
          onChange={this.handleMergeScore}
          mode={(data.status === SUBMITTED || !isStudent) ? 'view' : 'create'}
        />
      )
    })
    return [...ptQuestions, ...teacherQuestions, ...inputQuestions]
  }

  renderButton = () => {
    const { status } = this.state.data
    if (status === SENT && utils.isStudent()) {
      return <button onClick={this.handleSubmit}>
        提交
      </button>
    }
  }

  handleSubmit = async () => {
    const { ptScore, atmosphere, abilities, timeliness } = this.state.data
    const allScores = [ ptScore, atmosphere, abilities, timeliness ].reduce((result, score) => {
      const values = Object.values(score)
      result = [...result, ...values]
      return result
    }, [])
    const isIllegal = allScores.some(score => !score)
    if (isIllegal) {
      return message.error('带星号（*）的是必填项')
    } else {
      const { abilities, atmosphere, timeliness, ptScore, favoriteTeacher, advice } = this.state.data
      const id = _.get(this.props, ['location', 'query', 'id'])
      const results = {
        abilities,
        atmosphere,
        timeliness,
        ptScore,
        favoriteTeacher,
        advice
      }
      const res = await rest.put(`/api/quality-test/${id}/submit`, { results })
      if (res.code !== 0) {
        message.error({
          text: '提交失败',
          msg: res.msg
        })
      } else {
        this.setState({
          submitted: true
        })
      }
    }
  }

  handleGoBack = () => {
    location.href = '/'
  }

  render () {
    const { submitted, loading } = this.state
    const type = _.get(this.state, ['data', 'type'])
    const date = _.get(this.state, ['data', 'sendTime']) || new Date()
    const month = moment(date).format('MM')
    return (
      loading ? <Loading /> : !submitted ? <div className='questionaire-container'>
        <div className='header'>
          <div className='img-wrapper'>
            <img
              className='banner'
              src={require(`images/questionaire/questionaire-${imgType[type]}-bg.png`)}
              alt=''
            />
            <img
              className='month-pic'
              src={require(`images/questionaire/month-${month}.png`)}
              alt=''
            />
          </div>
        </div>
        <div className='body'>
          <div className='questions'>
            <p
              className='tip'
            >
              温馨提示：您的建议会被作为教学服务改进的重要参考，仅智课教育质检老师可见，放心答题哦！
            </p>
            {this.renderQuestions()}
            {this.renderButton()}
          </div>
        </div>
      </div> : <div className='questionaire-container finished'>
        <img src={require('images/xiaozhi-happy.png')} alt='' />
        <p>您已完成本次问卷，感谢您的宝贵时间</p>
        <button onClick={this.handleGoBack}>返回学习中心</button>
      </div>
    )
  }
}
export default connect(state => ({
  examinations: state.config.exams.list.filter(i => i.visible == 1) || [],
  subjects: state.config.subjects.list || []
}))(Questionaire)
