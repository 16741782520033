import * as React from 'react'
import Loadable from 'react-loadable'
const DELAY_DURATION = 2000

const MyLoadable = opts => {
  return Loadable(Object.assign({
    loading: () => <div />, // eslint-disable-line react/display-name
    delay: DELAY_DURATION
  }, opts))
}

export default MyLoadable
