import * as utils from 'common/utils'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SetIntervalUpdate from './setInterval_update'
import socket from 'common/socket'
import { Icon } from 'antd'

class EditionUpdate extends React.Component<any, any> {
  state = {
    toggleShow: true,
    message: '',
    now: 10
  }

  componentDidMount = async () => {
    socket.on('SMART_ADAPTATION_MESSAGE', async res => {
      this.props.showMessage()
      this.setState({
        message: res
      })
      await utils.delay(10000)
      await this.props.closeMessage()
    })
  }

  styleChange = async () => {
    this.setState({
      toggleShow: !this.state.toggleShow
    })
  }

  render () {
    const { version, type } = this.props
    const { toggleShow } = this.state
    const isLogined = utils.isLogined()
    return (
      <div className='edition-update-container'>
        {
          isLogined && version.needUpdate ? <div>
            {
              type == 'student'
                ? <div className='edition update update1-student' style={{ display: toggleShow ? 'none' : '' }} onClick={this.styleChange} /> : <div className='edition update update1-teacher' onClick={this.styleChange} style={{ display: toggleShow ? 'none' : '' }} />
            }
            <SetIntervalUpdate
              toggleShow={toggleShow}
              styleChange={this.styleChange}
            />
          </div> : ''
        }
        {
          this.props.messageUpdate ? <div className='new-message tip-modal'>
            <div className='left-text'>
              <div className='content'>
                <p
                  className='message-content'
                  dangerouslySetInnerHTML={{ __html: this.state.message }}
                />
                <span className='watch-detail' onClick={this.props.watchMessage}>点击进入</span>
              </div>
              <img className='right-img' src={require('images/mascot.png')} />
            </div>
            <Icon type='cross' onClick={this.props.closeMessage} />
          </div> : ''
        }
      </div>

    )
  }
}
export default connect(state => {
  return {
    version: state.version || {}
  }
})(withRouter(EditionUpdate))
