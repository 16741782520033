
const initialState = {
  campuses: {},
  role: {},
  roles: {},
  structures: {},
  staffs: {},
  staff: {},
  prices: {},
  students: {},
  student: {},
  plans: {},
  plan: {},
  planPhases: {},
  planPeriods: {},
  contracts: {},
  contract: {},
  contractTeachers: {},
  contractPostponements: {},
  teachers: {},
  teacher: {},
  teaching: {},
  teachings: {},
  classrooms: {},
  classroomsOnline: {},
  studios: {},
  absences: {},
  questions: {},
  notes: {},
  contractTransfers: {},
  contractTransfer: {},
  organizations: {},
  uskidNewPermissions: {}
}

export default (state = initialState, action) => {
  if (action.type === 'SCHOOL.AUTO_FETCHING') {
    let newState = Object.assign({}, state)
    newState[action.secondLevel][action.key] = {
      completed: action.completed,
      data: action.data
    }
    return newState
  } else if (action.type === 'SCHOOL.AUTO_FETCHING_ONE') {
    let newState = Object.assign({}, state)
    newState[action.secondLevel] = {
      completed: action.completed,
      data: action.data
    }
    return newState
  }
  return state
}
