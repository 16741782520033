import * as utils from 'common/utils'
import * as React from 'react'
import debug from '../../common/debug'

class ErrorBoundary extends React.Component<any, any> {
  state = { hasError: false }

  componentDidCatch (error) {
    debug(error)
    this.setState({ hasError: true })
  }

  render () {
    const isStudent = utils.isStudent()
    if (this.state.hasError) {
      return (<div className='error-bondary-container'>
        <div className='error-info'>
          <img src={require('images/error.png')} alt='' />
          <p>
            页面出错啦，请{` `}
            <a href='' onClick={location.reload}>刷新</a>
            {` `}
            {!isStudent && '或 联系管理员'}
          </p>
        </div>
      </div>)
    }
    return this.props.children
  }
}

export default ErrorBoundary
