import * as React from 'react'
import mountUpdate from 'mixins/mount_update'
const createReactClass = require('create-react-class')

const Select = createReactClass({
  text: React.createRef<any>(),

  // 重用
  mixins: [mountUpdate],

  // 状态初始化
  getInitialState () {
    return {}
  },

  // 加载时
  UNSAFE_componentWillMount/* eslint-disable-line camelcase */ () {
    let first = this.props.children[0]
    this.setState({
      value: first.props.value,
      optionTitle: first.props.title
    })
  },

  // 元素更新
  componentDidUpdate () {
    let defaultValue = this.props.defaultValue == undefined
      ? this.props.children[0].props.value
      : this.props.defaultValue

    if (this.state.lastDefaultValue !== defaultValue) {
      this.setState({
        lastDefaultValue: defaultValue
      })
      this.select(defaultValue)()
    } else {
      this.select(this.state.value)()
    }
  },

  // 元素加载/更新
  componentDidMountOrUpdate () {
    let listWidth = 150
    this.props.children.forEach(function (item) {
      let leng = 0
      const title = item.props.title || ''
      for (let i = 0; i < title.length; i++) {
        leng += item.props.title[i].charCodeAt() < 256 ? 1 : 2
      }

      listWidth = Math.max(listWidth, (leng * 13) / 2 + 50)
    })
    if (listWidth !== this.state.listWidth) {
      this.setState({
        listWidth
      })
    }
  },

  // 渲染
  render () {
    let self = this
    return (
      <div className={`component-common-form-select component-common-form-select-${this.props.style}`}>
        <div
          ref={this.text}
          className='text'
          onClick={this.open}
        >
          <div
            className='title'
            style={{ display: this.props.title ? 'inline-block' : 'none' }}
          >
            {this.props.title}
          </div>
          <span className='option'>{this.state.optionTitle}</span>
          <span
            className='fa fa-angle-down'
            style={{ display: this.props.readOnly ? 'none' : 'inline-block' }}
          />
        </div>

        <div
          className='list'
          style={{
            minWidth: this.text.current && this.text.current.offsetWidth + 2,
            width: this.state.listWidth,
            left: this.props.style === 'form' ? 0 : this.state.listLeft,
            opacity: this.state.listOpacity,
            display: this.state.isShowed ? 'block' : 'none',
            overflow: this.props.children.length > 4 ? 'auto' : 'hidden'
          }}
        >
          {this.props.children.map(function (item) {
            return React.cloneElement(item, {
              key: item.props.value,
              active: item.props.value === self.state.value.toString(),
              select: self.select(item.props.value),
              close: self.close()
            })
          })}
        </div>

        <div
          className='mask'
          style={{ display: this.state.isShowed ? 'block' : 'none' }}
          onClick={this.close()}
        />
      </div>
    )
  },

  // 展示
  open () {
    if (this.props.readOnly) return

    let self = this
    this.setState({
      isShowed: true,
      listLeft: (this.text.current.offsetWidth - this.state.listWidth) / 2
    })

    setTimeout(function () {
      self.setState({
        listOpacity: 1
      })
    }, 10)
  },

  // 关闭
  close () {
    let self = this

    return function () {
      self.setState({
        isShowed: false,
        listOpacity: 0
      })
    }
  },

  // 选择元素
  select (value) {
    let self = this

    return function () {
      let title = ''
      self.props.children.forEach(function (item) {
        if (item.props.value.toString() === value.toString()) {
          title = item.props.title
        }
      })

      if (value.toString() !== self.state.value.toString() || title !== self.state.optionTitle) {
        self.setState({
          value,
          optionTitle: title
        })

        if (self.props.onSelect) {
          self.props.onSelect(value)
        }

        if (self.props.onChange) {
          setTimeout(function () {
            self.props.onChange(value)
          }, 10)
        }
      }
    }
  },

  // 获取值
  getValue () {
    return this.state.value
  }
})

export default Select
