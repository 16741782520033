import * as React from 'react'

const createReactClass = require('create-react-class')
// 组件
const Option = createReactClass({
  // 渲染
  render () {
    let active: React.ReactNode = ''
    if (this.props.active) {
      active = (<span className='active fa fa-check' />)
    }

    return (
      <div
        className='component-common-form-option'
        onClick={this.handleClick}
      >
        {active}
        {this.props.title}
      </div>
    )
  },

  // 处理点击
  handleClick () {
    this.props.select()
    this.props.close()

    let self = this
    setTimeout(function () {
      if (self.props.onChange) {
        self.props.onChange()
      }
    }, 50)
  }
})

export default Option
