import React from 'react'
import { Input, message } from 'antd'
import _ from 'lodash'
import * as utils from 'common/utils'

const MIN_SCORE = 0
const MAX_SCORE = 5
const MAX_DECIMAL_NUM = 4
const DECIMAL_NUM = 2

class QuestionaireInput extends React.Component<any, any> {
  inputRef = React.createRef<any>()

  handleCheckAndSubmit = event => {
    let scoreNum = +event.target.value
    const input = _.get(this.inputRef, ['current', 'input'])
    if (!scoreNum ||
      isNaN(scoreNum) ||
      scoreNum < MIN_SCORE ||
      scoreNum > MAX_SCORE
    ) {
      input.value = ''
      return message.error(`请输入${MIN_SCORE}到${MAX_SCORE}之间有效数字`)
    }
    if (`${scoreNum}`.length > MAX_DECIMAL_NUM) {
      scoreNum = input.value = Number(scoreNum.toFixed(DECIMAL_NUM))
    }
    return this.props.onBlur(scoreNum)
  }

  render () {
    const { score, mode } = this.props
    const isStudent = utils.isStudent()
    const inputProp = mode === 'view' ? { defaultValue: score, disabled: true } : { onBlur: this.handleCheckAndSubmit }
    return (
      <Input
        type='text'
        ref={this.inputRef}
        disabled={!isStudent}
        {...inputProp}
      />
    )
  }
}

export default QuestionaireInput
