import * as React from 'react'
import * as utils from 'common/utils'
import * as _ from 'lodash'
import availableTimeHelper from 'common/calendar/availableTime'

const weekdaysObj = [
  {
    name: '周一',
    english: 'monday'
  },
  {
    name: '周二',
    english: 'tuesday'
  },
  {
    name: '周三',
    english: 'wednesday'
  },
  {
    name: '周四',
    english: 'thursday'
  },
  {
    name: '周五',
    english: 'friday'
  },
  {
    name: '周六',
    english: 'saturday'
  },
  {
    name: '周日',
    english: 'sunday'
  }
]

class TimeSlotsTable extends React.Component<any, any> {
  render () {
    let weekdays = _.cloneDeep(weekdaysObj)
    let { timeSlots: slots, operations, sundayFirst, disabledSlots = [] } = this.props
    if (sundayFirst) {
      slots = availableTimeHelper.convertSlotsDataToSundayFirst(_.cloneDeep(slots))
      weekdays.unshift(weekdays.pop())
    }
    return (
      <div className={`time-slots-table-container ${operations ? 'nine-blocks' : 'eight-blocks'}`}>
        <div className='head'>
          <span>时段名称</span>
          {
            weekdays.map(day => {
              return <span key={day.name}>{day.name}</span>
            })
          }
          { operations && <span>操作</span> }
        </div>
        {
          slots.map((slot, index) => {
            const isDisabled = disabledSlots.includes((index + 1) * 10)
            return <div className='course-span' key={slot.label}>
              <span>{slot.label}</span>
              {
                slot.slots.map((slot, index) => {
                  return <span key={index}>{utils.convertMinuteToTimeRange(slot)}</span>
                })
              }
              { operations && <span>
                {
                  operations.map((operation) => {
                    if (isDisabled && operation.name === '删除') {
                      return null
                    }
                    return <a key={operation.name} onClick={() => operation.handler(slot, index, isDisabled)}>{operation.name}</a>
                  })
                }
              </span> }
            </div>
          })
        }
      </div>
    )
  }
}

export default TimeSlotsTable
