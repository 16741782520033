import * as _ from 'lodash'
import * as React from 'react'
import { Link } from 'react-router-dom'
import memoize from 'memoize-one'
import { Menu, Icon } from 'antd'
import 'antd/lib/menu/style'
import 'antd/lib/icon/style'
import pathToRegexp from 'path-to-regexp'

const { SubMenu } = Menu

const formatMenuPath = (data, parentPath = '/') => (
  _.map(data, (item) => {
    const result = {
      ...item,
      path: `${parentPath}${item.path}`
    }
    if (item.children) {
      result.children = formatMenuPath(item.children, `${parentPath}${item.path}/`)
    }
    return result
  })
)

const getFlatMenuKeys = menuData => (
  _.reduce(menuData, (keys, item) => {
    keys.push(item.path)
    if (item.children) {
      return keys.concat(getFlatMenuKeys(item.children))
    }
    return keys
  }, [])
)

const getMeunMatchKeys = (flatMenuKeys, paths) =>
  _.reduce(paths, (matchKeys, path) => (
    matchKeys.concat(_.filter(flatMenuKeys, item => pathToRegexp(item).test(path)))
  ), [])

const urlToList = (url, level) => {
  if (level === 'school') {
    const urlArr = url.split('/')
    urlArr.shift() // TODO: 暂时先这么写，之后修改一下
    urlArr.shift()
    url = `/${urlArr.join('/')}`
  }
  if (url) {
    const urlList = url.split('/').filter(i => i)
    return _.map(urlList, (urlItem, index) => `/${urlList.slice(0, index + 1).join('/')}`)
  }
  return []
}

class Sider extends React.Component<any, any> {
  fullPathMenuData = memoize(menuData => formatMenuPath(menuData))
  selectedKeys = memoize((pathname, fullPathMenu) => (
    getMeunMatchKeys(getFlatMenuKeys(fullPathMenu), urlToList(pathname, this.props.level))
  ))

  state = {
    openKeys: this.selectedKeys(this.props.pathname, this.fullPathMenuData(this.props.menuData))
  }

  static defaultProps = {
    prefixCls: 'react-sider',
    className: '',
    style: {},
    appName: '',
    appLogo: '',
    appBaseUrl: '/',
    width: 256,
    menuData: [],
    pathname: '/'
  }

  handleOpenChange = (openKeys) => {
    const state = this.state
    const latestOpenKey = openKeys.find(key => !(state.openKeys.indexOf(key) > -1))
    const latestCloseKey = state.openKeys.find(key => !(openKeys.indexOf(key) > -1))
    let nextOpenKeys = []
    if (latestOpenKey) {
      nextOpenKeys = [latestOpenKey]
    }
    if (latestCloseKey) {
      nextOpenKeys = []
    }
    this.setState({ openKeys: nextOpenKeys })
  }

  renderMenu = data => {
    const topLevel = this.props.level === 'school' ? '/school' : ''
    return _.map(data, (item) => {
      if (item.children) {
        return (
          <SubMenu
            key={item.path}
            title={
              <span>
                <Icon type={item.icon} />
                <span>{item.name}</span>
              </span>
            }
          >
            {this.renderMenu(item.children)}
          </SubMenu>
        )
      }
      return (
        <Menu.Item key={item.path}>
          <Link to={`${topLevel}${item.path}`} href={item.path}>
            <Icon type={item.icon} />
            <span>{item.name}</span>
          </Link>
        </Menu.Item>
      )
    })
  }

  renderSiderBody = () => {
    const { prefixCls, pathname, menuData } = this.props
    const { openKeys } = this.state

    return (
      <div className={`${prefixCls}-body`}>
        <Menu
          style={{ width: '100%' }}
          mode='inline'
          openKeys={openKeys}
          selectedKeys={this.selectedKeys(pathname, this.fullPathMenuData(menuData))}
          onOpenChange={this.handleOpenChange}
        >
          {this.renderMenu(this.fullPathMenuData(menuData))}
        </Menu>
      </div>
    )
  }

  render () {
    const { prefixCls, className, style, width } = this.props

    const classes = `${prefixCls} ${className}`
    const styles = {
      ...style,
      width
    }

    return (
      <div className={classes} style={styles}>
        {/* {this.renderSiderHeader()} */}
        {this.renderSiderBody()}
      </div>
    )
  }
}

export default Sider
