import * as _ from 'lodash'
import * as utils from 'common/utils'
import * as React from 'react'
import * as rest from 'common/rest'
import { message } from 'antd'

const actionPermissionKV = {
  assignServicePTAndTeachingPT: ['transfer-assignServicePT', 'transfer-assignTeachingPT'],
  arrange: 'arrangement-arrange',
  viewTeachingRequests: 'teachingReq-view',
  viewStudentCourseSchedule: '',
  immediateConfirmation: '',
  viewMyCourseSchedule: '',
  change: 'changing-arrange',
  requestChange: 'changingReq-create',
  viewStudentContractTransfer: 'transfer-view',
  requestTeaching: 'teachingReq-create',
  assignTeachingPT: 'transfer-assignTeachingPT',
  assignServicePT: 'transfer-assignServicePT',
  fillInDetails: ['transfer-assignServicePT', 'transfer-assignTeachingPT']
}

export async function getUnreadMessageCount () {
  const res = await rest.get('/api/message/count?status=1')
  return _.get(res, ['data', 'count'], 0)
}

export function generateMessageLink (item) {
  return <span
    className='operation-link'
    onClick={() => {
      const permission = actionPermissionKV[item.action]
      if (!permission) {
        return window.open(item.link)
      }
      switch (typeof permission) {
        case 'string':
          if (utils.checkPermissionOperation(permission)) {
            window.open(item.link)
          } else {
            message.error('请联系管理员开通权限，再进行操作。')
          }
          break
        case 'object':
          for (let i = 0; i < permission.length; i++) {
            if (!utils.checkPermissionOperation(permission[i])) {
              return message.error('请联系管理员开通权限，再进行操作。')
            }
          }
          return window.open(item.link)
        default:
          break
      }
    }}
  >
    {item.text}
  </span>
}
