
/**
 * @file 环境层配置，只当前环境有效
 */

module.exports = {
  // localDebug: true,
  //
  webpackProxyHost: 'http://sp.dev.smartstudy.com',
  // webpackProxyHost: 'http://feature-expo-622-evaluation.sp.dev.smartstudy.com/',
  webpackProxyPort: 3456,
  webpackProxyHostInside: 'http://yun.dev.smartstudy.com',
  webpackProxyPortInside: 3457,

  // CDN地址
  cdn: '',

  // 总部层id, 产品要求很多地方需要排除总部层，但是线上线下总部层id不一样
  headquartersId: 3,
  // 直营机构类型id，线上线下不一样
  directOrganizationTypeId: 58,
  adaptationHost: 'https://zsy.dev.smartstudy.com',
  onlineOrganizationIds: [43],
  onlineOrganizationId: 43,
  demoOrganizationIds: process.env.IS_INSIDE ? [] : [],
  yunUrl: 'yun.dev.smartstudy.com',
  smartDemoOrganizationIds: [42],
  basicCourse: { productId: -1, productName: '基础课', examinationId: 59 },
  foreignCourse: { productId: -2, productName: '外教课', examinationId: 60 },
  sparringCourse: { productTypeId: 27, productName: '带练课' },
  inServiceProductSubTypes: [1, 2, 3],
  showQuestionnaireLink: 'off',
  showSmartAdaptationQuestionnaireLink: 'on',
  questionnaireUrl: '//www.wenjuan.in/s/INzeMbR/',
  smartAdaptationQuestionnaireUrl: '//www.wenjuan.com/s/jiyMZf/',
  scolarShipQuery: { isdev: true },
  newTestingIds: {
    1: 1000002,
    2: 1000003
  },
  zhikePracticePackageSubjectId: -1,
  zhikePracticePackageTextbookIdsAndNames: {
    1: {
      TPO: [1, 'TPO'],
      TPO_PLUS: [126, 'TPO Plus'],
      OG: [2, 'OG'],
      WORDS: [71, '托福21天词汇'],
      RECALL_JIJING: [8, '机经']
    },
    2: {
      CAMBRADGE: [3, '剑桥'],
      OG: [57, 'OG'],
      EG: [58, 'EG'],
      IELTS_ALTERNATION: [73, '雅思同义词替换练习'], // dev没有这个题，先用另一个题替换跳转
      JIJING: [10, '机经']
    },
    // 测试环境只有三套题，要你命三千和官方150没有
    3: {
      OG: [6, 'OG'],
      OFFICIAL_150: [98, 'OFFICIAL_150'],
      OFFICIAL_ISSUE: [11, 'GRE官方题库Issue'],
      ARGUMENT: [12, 'GRE官方题库Argument'],
      SANQIAN: [93, 'SANQIAN']
    },
    5: {
      OG: [7, 'OG'],
      ARGUMENT: [13, 'ARGUMENT'],
      PREP: [82, 'PREP'],
      HONGBAOSHU: [94, 'HONGBAOSHU']
    }
  },

  rap: {
    host: '//rap.smartstudy.tech',
    projects: [
      {
        id: 4,
        apis: [
          /api\/test/
        ]
      },
      {
        id: 5,
        host: '//crms.smartstudy.com',
        apis: [
          /api\/frontend-developers/
        ]
      }
    ]
  },

  // 地址
  url: {
    apiGateway: '//api.dev.smartstudy.com',
    www: '//www.dev.smartstudy.com',
    smart: '//sp.dev.smartstudy.com',
    transcode: '//tr.smartstudy.com',
    exercise: '//ti_old.dev.smartstudy.com',
    exerciseV2: '//ti.dev.smartstudy.com',
    newExercise: '//ti-nav.dev.smartstudy.com',
    tikuBase2: '//ti-base2.dev.smartstudy.com',
    newTiku: '//tiku.dev.smartstudy.com',
    practice: '//practice.dev.smartstudy.com',
    practiceFront: '//practice.dev.smartstudy.com',
    crm: '//for-smart.crm2.smartstudy.tech',
    word: '//api.dev.smartstudy.com/word',
    wordFront: '//ti-word.dev.smartstudy.com',
    wordFrontOld: '//dev.smartstudy.com:2222',
    smartStudyUrl: '//www.smartstudy.com',
    videoCDN: '//media6.smartstudy.com',
    cdnHost: '//ugc.smartstudy.com',
    pigai: '//pigai.dev.smartstudy.com',
    product: '//www.dev.smartstudy.com/api/product',
    // videoDetail: '//www.smartstudy.com/play',
    athene: '//www.dev.smartstudy.com/api',
    uskidAdmin: '//admin-old.uskid.tech',
    uskid: '//uskid.tech',
    zhike: '//www.dev.smartstudy.com/api',
    ti: '//ti.hq.smartstudy.com/api',
    sentry: 'https://475e0aab1ec644919c65b9231ab71075@sentry.smartstudy.com/41',
    promotion: 'http://promotion.dev.smartstudy.com/zt',
    im: '//api.dev.smartstudy.com/im'
  },
  // 学习中心对应的UID
  adminKey: 'rNlAHvoEB620qnLDHsp8IaDDIPQozNqt'
}
