import moment from 'moment-timezone'
import * as _ from 'lodash'
import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import constants from 'common/constants'
import * as rest from 'common/rest'
import * as classroomHelper from 'helpers/classroom'
import * as teacherHelper from 'helpers/teacher'
import { Select, Input, Button as OrigButton, message } from 'antd'
import SubjectPicker from 'components/common/subject_picker'
import TimeTable from 'components/common/time_table'

const Option = Select.Option
const { TextArea } = Input
const { CLASSROOM_USAGE } = constants
const SLOT_COUNT = 10

const Button: any = OrigButton

class OnlineTrialCourseRequest extends React.Component<any, any> {
  constructor (props) {
    super(props)

    this.state = {
      currentDate: null,
      teachers: [],
      teacherId: null,
      classrooms: [],
      classroomId: null,
      remark: '',
      subjectId: null,
      tableData: []
    }
  }

  async componentDidMount () {
    const { location = {} } = this.props
    const query = location.query || {}
    const requiredFields = ['apollonToken', 'userId', 'clueId', 'ccId', 'studentName']
    for (const key of requiredFields) {
      if (!query[key]) return message.error({ text: `缺少${key}参数` })
    }
    sessionStorage.setItem('apollon_token', query.apollonToken)
    await this.handleDateChange()
    await this.fetchTeachers()
  }

  fetchTeachers = async () => {
    // 获取可用教师
    const { teacherId, subjectId } = this.state
    const teachers = await teacherHelper.getAutoTrialTeachers({ subjectId })

    this.setState({ teachers }, () => {
      // 如果最新教师列表中不存在已选择的teacherId，则将teacherId重置
      if (!teachers.find(el => el.id === teacherId)) {
        const teacherId = _.get(teachers, [0, 'id'])
        this.handleChangeTeacher(teacherId)
      }
    })
  }

  fetchClassrooms = async () => {
    const { date, timeSlot, classroomId } = this.state
    if (date && timeSlot) {
      const classrooms = await classroomHelper.getAvailableClassrooms(config.onlineOrganizationId, date, timeSlot, CLASSROOM_USAGE.VIP_COURSE)
      this.setState({ classrooms }, () => {
        if (!classrooms.find(el => el.id === classroomId)) {
          const classroomId = _.get(classrooms, [0, 'id'])
          this.handleChangeClassroom(classroomId)
        }
      })
    }
  }

  /**
   * 获取可用的日期和slot
   * @return {set} set，元素是 YYYY-MM-DD,<slot>，如 2018-01-01,30
   */
  getAvailableDateSlotSet = () => {
    const { futureDaysTimeStamps, classroomUsage, startTime, endTime, teacherDisabledTimeSlots = [] } = this.state
    const isAfternoon = moment().hour() >= 10
    // 如果是上午，则最后一天不可用，如果是下午，则第一天上午和最后一天下午不可用
    const firstDay = moment(startTime).format('YYYY-MM-DD')
    const lastDay = moment(endTime).format('YYYY-MM-DD')
    const timeSlotSplitPoint = 40 // A1 ~ B2 属于上午，C1 ~ E2 属于下午和晚上
    const filterOut = isAfternoon
      ? (date, timeSlot) => (date === firstDay && timeSlot <= timeSlotSplitPoint) || (date === lastDay && timeSlot > timeSlotSplitPoint)
      : (date, timeSlot) => date === lastDay

    const result = new Set()
    for (let i = 0; i < futureDaysTimeStamps.length; i++) {
      const timeStamp = futureDaysTimeStamps[i]
      const dateStr = moment(timeStamp).format('YYYY-MM-DD')
      for (let j = 1; j < SLOT_COUNT + 1; j++) {
        let available = (_.get(classroomUsage, [i, 'timeSlots']) || []).includes(j * 10) && !filterOut(dateStr, j * 10)
        if (available) {
          for (const item of teacherDisabledTimeSlots) {
            if (utils.compareTwoDateEqual([item.date, timeStamp])) {
              for (let l = 0; available && l < item.timeSlots.length; l++) {
                if (item.timeSlots[l].timeSlot === j * 10) {
                  available = false
                  break
                }
              }
            }
          }
        }
        if (available) result.add([dateStr, j * 10].join(','))
      }
    }
    return result
  }

  fetchTableData = () => {
    const { futureDaysTimeStamps, date, timeSlot } = this.state
    const availableDateSlotSet = this.getAvailableDateSlotSet()
    const arr = []
    for (let i = 0; i < futureDaysTimeStamps.length; i++) {
      const timeStamp = futureDaysTimeStamps[i]
      const dateStr = moment(timeStamp).format('YYYY-MM-DD')
      const dateSelected = utils.compareTwoDateEqual([date, timeStamp])
      let data = { [timeStamp]: [] }

      for (let j = 1; j < SLOT_COUNT + 1; j++) {
        const selected = dateSelected && (timeSlot === j * 10)
        let available = availableDateSlotSet.has([dateStr, j * 10].join(','))
        data[timeStamp][j - 1] = { timeSlot: j * 10, day: timeStamp, available, selected }
      }
      arr.push(data)
    }
    this.setState({
      tableData: arr
    })
  }

  handleChangeTeacher = async teacherId => {
    const { startTime, endTime } = this.state
    const teacherDisabledTimeSlots = teacherId ? (await teacherHelper.getDisabledTimeSlots(teacherId, startTime, endTime)) : []
    this.setState({ teacherId, teacherDisabledTimeSlots }, this.fetchTableData)
  }

  handleChangeSubject = async subjectId => {
    this.setState({ subjectId }, this.fetchTeachers)
  }

  handleChangeClassroom = async classroomId => {
    this.setState({ classroomId })
  }

  handleSelectTimeSlot = async data => {
    const self = this
    this.setState({ date: data[0], timeSlot: data[1] }, () => {
      self.fetchTableData()
      self.fetchClassrooms()
    })
  }

  handleDateChange = async () => {
    // 处理日期变更的情况
    const { futureDaysTimeStamps, startTime, endTime } = this.getAvailableAndDisableTime()
    const currentDate = moment(futureDaysTimeStamps[0]).format('YYYY-MM-DD')
    let classroomUsage = (await classroomHelper.getClassroomEnableTimeSlots(config.onlineOrganizationId, startTime, endTime)) || []
    classroomUsage = classroomUsage.sort((a, b) => a.date > b.date)
    this.setState({ futureDaysTimeStamps, startTime, endTime, currentDate, classroomUsage }, this.fetchTableData)
  }

  getAvailableAndDisableTime = () => {
    const bookableDays = 4
    const bookOffsetDays = 1
    const futureDaysTimeStamps = []
    for (let i = 0; i < bookableDays; i++) {
      futureDaysTimeStamps.push(moment().startOf('day').add(bookOffsetDays + i, 'days').toDate())
    }
    const firstDay = futureDaysTimeStamps[0]
    const endDay = futureDaysTimeStamps[futureDaysTimeStamps.length - 1]
    const startTime = moment(firstDay).startOf('day').toDate()
    const endTime = moment(endDay).endOf('day').toDate()
    return { futureDaysTimeStamps, startTime, endTime }
  }

  handleSubmit = async () => {
    const { query } = this.props.location
    const { userId, apollonToken, clueId, ccId, studentName } = query
    const { teacherId, date, timeSlot, classroomId, subjectId, remark } = this.state
    const res = await rest.post(`/api/auto-trial-course-request?apollonToken=${apollonToken}`, {
      userId, teacherId, classroomId, subjectId, date, timeSlot, remark, clueId, ccId, studentName
    })
    if (res.code === 0) {
      // 重新请求props数据
      return message.success('预约成功，可刷新当前页面查看预约试听记录')
    }
    return message.error({ text: '预约失败', msg: res.msg })
  }

  render () {
    const { subjectId, teachers, teacherId, remark, tableData, date, timeSlot, classrooms, classroomId, futureDaysTimeStamps = [] } = this.state

    return (<div className='online-trial-course-request'>
      <div className='container'>
        <div className='modal-line'>
          <div className='filter-item'>
            <label>选择科目：</label>
            <SubjectPicker
              value={subjectId}
              onChange={this.handleChangeSubject}
            />
          </div>

          <div className='filter-item'>
            <label>选择教师：</label>
            <Select
              className='teacher-select'
              style={{ width: 150 }}
              value={teacherId}
              filterOption={(input, option) => (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp='children'
              showSearch
              onSelect={teacherId => this.handleChangeTeacher(teacherId)}
            >
              {
                teachers.map(teacher => <Option
                  value={teacher.id}
                  key={`${teacher.id}`}
                >
                  {teacher.name}
                </Option>)
              }
            </Select>
          </div>
        </div>
        <div className='modal-line'>
          <div className='filter-item'>
            <label>备注：</label>
            <TextArea
              rows={3}
              style={{ width: 610 }}
              value={remark}
              onChange={e => this.setState({ remark: e.target.value })}
            />
          </div>
        </div>
        <div className='rules'>
          <p>
            <b>提示：</b>
            1、只可预约三天后的课程，当天12点前可以预约第2天上午到第4天下午的课程，当天12点之后可以预约第2天下午到第5天上午的试听课程； <br />
            2、时段：A1（08:30~09:00） A2（09:30~10:00） B1（10:40~11:10） B2（11:40~12:10） C1（13:30~14:00） C2（14:30~15:00） D1（15:40~16:10） D2（16:40~17:10） E1（18:30~19:00） E2（19:30~20:00）。
          </p>
        </div>
        <div>
          <TimeTable
            futureDaysTimeStamps={futureDaysTimeStamps}
            onSelect={this.handleSelectTimeSlot}
            data={tableData}
          />
        </div>
        <div className='modal-line'>
          <div className='filter-item'>
            <label>选择教室：</label>
            <Select
              style={{ width: 150 }}
              value={classroomId}
              filterOption={(input, option) => (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
              optionFilterProp='children'
              showSearch
              onSelect={classroomId => this.handleChangeClassroom(classroomId)}
            >
              {
                classrooms.map(classroom => <Option
                  value={classroom.id}
                  key={`${classroom.id}`}
                >
                  {classroom.name}
                </Option>)
              }
            </Select>
          </div>
        </div>
        <Button
          type='primary'
          style={{ width: 150 }}
          disabled={!(date && timeSlot && subjectId && teacherId)}
          onClick={this.handleSubmit}
        >
          预约
        </Button>
      </div>
    </div>)
  }
}

export default OnlineTrialCourseRequest
