import config from 'common/config'
import * as React from 'react'
import { message } from 'antd'
import './polyfill'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { store } from './common/redux'
import './common/global'
import './common/socket'
import { Router } from 'react-router-dom'
import App from './containers/index'
import history from 'common/history'
import { LocaleProvider } from 'antd'
import * as utils from 'common/utils'
import zhCn from 'antd/lib/locale-provider/zh_CN'
import enUs from 'antd/lib/locale-provider/en_US'
import 'moment/locale/zh-cn'

import 'font-awesome/scss/font-awesome.scss'

import LangContext from './helpers/context'
import intl from 'react-intl-universal'
const locales = {
  'en-US': require('./locale/en_US.json'),
  'zh-CN': require('./locale/zh_CN.json')
}


const currentDomain = window.location.host
if (currentDomain !== config.productionDomain && currentDomain !== config.stagingDomain) {
  (window as any)._accessConfig = { host: config.devAccessDomain }
}

class Container extends React.Component<any, any> {
  state = {
    lang: 'zh-CN'
  }
  componentDidMount () {
    const ifHasLang = !!utils.getLocalStorage('lang')
    this.loadLocales(ifHasLang ? utils.getLocalStorage('lang') : 'zh-CN')
  }
  loadLocales = (val) => {
    let currentLocale = val || 'zh-CN'
    intl.init({
      currentLocale,
      locales,
      warningHandler: (msg) => message.error(msg)
    })
    utils.setLocalStorage('lang', val)
    this.setState({
      lang: val
    })
  }
  render () {
    const val = {
      lang: this.state.lang,
      changeLanguage: async (val) => {
        this.loadLocales(val)
      }
    }
    return (
      <LangContext.Provider value={val}>
        <Provider store={store}>
          <LocaleProvider locale={this.state.lang == 'zh-CN' ? zhCn : enUs}>
            <Router history={history}>
              <App />
            </Router>
          </LocaleProvider>
        </Provider>
      </LangContext.Provider>
    )
  }
}
// 渲染
ReactDOM.render(
  <Container />,
  document.getElementById('root')
)

// 打点注册
{
  const script = document.createElement('script')
  script.src = `${config.url.cdnHost}/data/access.js`
  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(script, s)
}
