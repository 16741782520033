import * as utils from 'common/utils'
import * as rest from 'common/rest'
import * as qs from 'qs'
import * as _ from 'lodash'
import { message } from 'antd'

export async function getStaff (id = undefined, options: any = {}) {
  if (!id) {
    id = utils.getUserId()
  }
  const data: any = { id }
  if (options.withPermission) {
    data.withPermission = true
  }
  const staffUrl = `/api/organization/staff?${qs.stringify(data)}`
  const res = await rest.get(staffUrl)
  return res.data
}

export async function getStaffWithPermission (id = undefined) {
  return getStaff(id, { withPermission: true })
}

/**
 * 重置员工的密码
 * @param {number} staffId 员工ID
 * @return {string} 新密码
 */
export async function resetPassword (staffId) {
  const res = await rest.post(`/api/organization/staffs/${staffId}/reset-password`)
  if (res.code) {
    message.error({ text: '重置密码失败', msg: res.msg })
    return ''
  }
  return _.get(res, ['data', 'newPassword']) || ''
}
