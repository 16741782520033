import * as utils from 'common/utils'
import { Route } from 'react-router-dom'
import * as React from 'react'
import { message } from 'antd'
import ErrorBoundary from 'components/common/error'
import Home from 'containers/home'

export default class AuthorizedRoute extends React.Component<any, any> {
  checkPermission = perm => {
    const MESSAGE_DURATION = 3
    if (perm && !utils.checkPermissionOperation(perm)) {
      message.error('您没有此页面权限，请联系管理员。', MESSAGE_DURATION)
      return 'illegal'
    } else {
      return 'legal'
    }
  }
  render () {
    const { path, isExact, perm } = this.props
    const isIllegal = perm ? this.checkPermission(perm) === 'illegal' : false
    if (isIllegal) {
      return <Route path='/' render={props => <Home
        {...props}
        params={props.match.params}
      />} />
    } else {
      const isHeadquarters = utils.isHeadquarter()
      return <Route
        exact={isExact}
        path={path}
        render={
          props => {
            return (
              <ErrorBoundary>
                <this.props.component
                  {...props}
                  isHeadquarters={isHeadquarters}
                  params={props.match.params}
                />
              </ErrorBoundary>
            )
          }
        }
      />
    }
  }
}
