import * as React from 'react'
import { Checkbox } from 'antd'

const TIPS = {
  teacher: [
    { icon: 'search', text: '快速查询课表和日报，内容一目了然' },
    { icon: 'write', text: '填写教学反馈，轻松实现移动办公   ' }, /* 多余的空格是为了实现设计稿的“伪居中” */
    { icon: 'notice', text: '接收系统通知，不错过任何重要事项' }
  ],
  student: [
    { icon: 'search', text: '快速查看课表和日报，学习状况一目了然' },
    { icon: 'notice', text: '及时通知各种上课信息，不遗漏重要课程' }
  ]
}

class WechatAccountContent extends React.Component<any, any> {
  onChange = e => {
    this.props.toggleFollowed(e.target.checked)
  }

  render () {
    const { type, style, title } = this.props

    return (
      <div className='wechat-account-content'>
        {
          style === 'popover' &&
          <div className='title-popover'>{title}</div>
        }
        <img className={`qrcode qrcode-${style}`} src={require('images/wechat_account_promotion/qrcode.png')} />
        {
          TIPS[type].map(item => (
            <div key={item.icon} className={`tips tips-${style}`}>
              <img className={`tips-icon tips-icon-${style}`} src={require(`images/wechat_account_promotion/${item.icon}.png`)} />
              {item.text}
            </div>
          ))
        }
        {
          style === 'modal' &&
          <div className='checkbox-modal'>
            <Checkbox onChange={this.onChange}>我已关注，不再提示</Checkbox>
          </div>
        }
        {
          style === 'modal' &&
          <img className='inset-modal' src={require('images/wechat_account_promotion/inset.png')} />
        }
      </div>
    )
  }
}

export default WechatAccountContent
