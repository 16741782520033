import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import { connect } from 'react-redux'
import * as rest from 'common/rest'
import { message, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import Messages from 'components/school/messages'
import PasswordModal from './password_modal'
import WechatAccountContainer from './wechat_account_promotion/container'
import * as userHelper from 'helpers/user'
import * as avatarHelper from 'helpers/avatar'
import * as selectCacheHelper from 'helpers/add_select_cache'
import * as _ from 'lodash'
import LanguageChange from 'components/common/language_change'
import intl from 'react-intl-universal'

const Search = Input.Search

class Header extends React.Component<any, any> {
  state = {
    secondLevel: null,
    searchClass: '',
    canComment: true,
    modalVisible: false,
    ticking: false,
    orgMenuVisible: false,
    orgs: [],
    keyword: '',
    fetchingOrgs: false
  }

  async componentDidMount () {
    if (this.props.topLevel !== 'student') return
    const res = await rest.get('/api/comment/teachers')
    if (res.code) return message.error({ text: '获取教师评语失败', msg: res.msg })
    const CanCommentTeachers = res.data.filter(item => !!item.canComment)
    if (CanCommentTeachers.length === 0) {
      this.setState({ canComment: false })
    }
  }

  async UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    if (nextProps.topLevel !== 'student') return
    const res = await rest.get('/api/comment/teachers')
    if (res.code) return message.error({ text: '获取教师评语失败', msg: res.msg })
    const CanCommentTeachers = res.data.filter(item => !!item.canComment)
    if (CanCommentTeachers.length === 0) {
      this.setState({ canComment: false })
    }
  }

  handleShowOrgMenu = async () => {
    if (global.IS_INSIDE) return false
    this.setState({
      fetchingOrgs: true
    })
    if (this.state.orgMenuVisible) return
    const url = '/api/organization/available'
    const res = await rest.get(url)
    if (res.code) {
      return message.error({ text: '获取可切换站点失败', msg: res.msg })
    }
    if (this.state.fetchingOrgs) {
      let orgs = selectCacheHelper.getOrderData('platform', res.data)
      this.setState({
        orgMenuVisible: true,
        orgs
      })
    }
  }

  handleHideOrgMenu = () => {
    if (global.IS_INSIDE) return false
    this.setState({
      orgMenuVisible: false,
      keyword: '',
      fetchingOrgs: false
    })
  }

  handleChangeSite = id => {
    this.setState({
      orgMenuVisible: false
    })
    selectCacheHelper.setLatestSelectData('platform', id)
    this.props.onChangeSite(id)
  }

  handleFilterSites = e => {
    this.setState({
      keyword: e.target.value
    })
  }

  getSitesMenu = () => {
    const { orgs, keyword = '' } = this.state
    const orgId = utils.getOrganizationId()
    const organizations = this.props.organizations.list
    const orgArray = []
    for (let i = 0; i < orgs.length; i++) {
      let existInfo = organizations.find(ele => ele.id === orgs[i])
      if (existInfo && (!keyword.toLowerCase() ||
        keyword.toLowerCase().split('').reduce((index, ch) => index !== -1 ? existInfo.name.toLowerCase().indexOf(ch, Math.max(index + 1), 0) : -1, -2) > -1 ||
        keyword.toLowerCase().split('').reduce((index, ch) => index !== -1 ? (existInfo.namePinyin || '').toLowerCase().indexOf(ch, Math.max(index + 1), 0) : -1, -2) > -1)) {
        orgArray.push({
          id: existInfo.id,
          name: existInfo.name
        })
      }
    }
    return orgArray.length
      ? orgArray.map(item => <span key={item.id} onClick={() => this.handleChangeSite(item.id)} className={`site-link ${item.id === orgId ? 'selected' : ''}`}>{item.name}</span>)
      : <span className='site-link no-site-link'>暂无数据</span>
  }

  getOrg = () => {
    const { orgMenuVisible } = this.state
    if (!this.props.organizations ||
      !this.props.organizations.list ||
      !this.props.organizations.list.length) return null

    if (this.props.topLevel === 'platform') {
      return <div className='org'>平台层</div>
    }

    let orgId = utils.getOrganizationId()
    if (!orgId) return null
    let orgName = ''
    this.props.organizations.list.forEach(org => {
      if (org.id === orgId) {
        orgName = org.name
      }
    })
    if (!orgName) return null
    return (<div className='org' onMouseOver={this.handleShowOrgMenu} onMouseLeave={this.handleHideOrgMenu}>
      {
        orgMenuVisible ? <Search autoFocus onChange={this.handleFilterSites} placeholder={orgName} /> : orgName
      }
      {
        orgMenuVisible ? (<div className='org-menu' onMouseLeave={this.handleHideOrgMenu}>
          <div className='org-menu-inner'>
            {this.getSitesMenu()}
          </div>
        </div>) : null
      }
    </div>)
  }

  onGetCaptcha = async () => {
    const phone = utils.getLocalStorage('userAccount')
    const res = await rest.post('/api/user/sms', { phone })
    if (res.code) return message.error('验证码发送失败， 请重试')
    this.setState({ ticking: true })
  }

  onModifyPasswordClick = () => this.setState({ modalVisible: true })

  onRedirectToCrm () {
    location.href = `${config.url.crm}`
  }

  onToggleSearch = () => this.setState({ searchClass: this.state.searchClass ? '' : 'search-active' })

  render () {
    const userAccount = utils.getLocalStorage('userAccount')
    let isPhoneLogin
    if (userAccount) {
      isPhoneLogin = userAccount.indexOf('@') === -1
    } else {
      isPhoneLogin = null
    }
    const topLevel = this.props.topLevel
    const role = topLevel == 'school' ? 'teacher' : topLevel == 'student' ? 'student' : topLevel == 'platform' ? 'admin' : ''
    const avatarImg = avatarHelper.getAvatar()
    // const isPhoneLogin = Accountuser.indexOf('@') === -1;
    const orgLogo = _.get(this.props, ['organization', 'logo', 'url'])
    let logoUrl = orgLogo || require('images/logo_zhike2.png')
    return (
      <div
        className='component-common-header'
        style={{ display: this.props.topLevel ? 'block' : 'none' }}
      >
        <Link className='main-logo' to='/'>
          <img src={logoUrl} alt='' />
          <span>SMART智能学习系统</span>
        </Link>
        <div className='user' onClick={() => { this.setState({ secondLevel: 'user' }) }}>
          <img src={avatarImg} alt='' />
          <span>{utils.getUserName()}</span>
        </div>

        { role == 'teacher' && global.IS_INSIDE && <LanguageChange />}
        {
          this.props.topLevel === 'school' && utils.checkPermissionModule('myMessages')
            ? <div className={'messages'} >
              <Messages messagesCount={this.props.messagesCount} />
            </div>
            : ''
        }
        {this.getOrg()}

        <div className='follow-wechat-account'>
          <WechatAccountContainer type='teacher' />
        </div>

        <div className='comment'>
          {
            !utils.isOnline() && this.props.topLevel === 'student'
              ? (this.state.canComment &&
              <Link
                className={this.state.canComment ? 'active' : ''}
                to={'/student/comment'}
              >
              我要评价
              </Link>) ||
              <a onClick={() => message.error('目前没有可评价的老师')}>我要评价</a>
              : ''
          }
        </div>

        <div className={`search ${this.state.searchClass}`}>
          <input
            placeholder='智能搜索...'
            onFocus={this.onToggleSearch}
            onBlur={this.onToggleSearch}
          />
          <span><span className='fa fa-search' /></span>
        </div>

        <div
          className='panel'
          style={{ display: this.state.secondLevel === 'user' ? 'block' : 'none' }}
          onClick={() => this.setState({ secondLevel: '' })}
        >
          <Link to='/' style={{ display: 'none' }}>请假</Link>
          {!global.IS_INSIDE && utils.isTeacher() && <span className='item' onClick={this.onRedirectToCrm} style={!utils.isTeacher() ? { display: 'none' } : {}}>跳转到CRM系统</span>}
          <span className='item' onClick={this.onModifyPasswordClick}>{intl.get('changePassword')}</span>
          { !utils.isHeadquarter() && utils.isTeacher() && !global.IS_INSIDE
            ? <a target='_blank' rel='noopener noreferrer' href='http://pro.beikaodi.com/%E8%80%83%E5%9F%B9smart%E9%A1%B9%E7%9B%AE%E7%BB%84/Smart%E7%B3%BB%E7%BB%9F%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98'>常见问题</a>
            : null
          }
          <span className='item' onClick={() => utils.logout(false, role)}>{intl.get('logout')}</span>
        </div>

        <div
          className='wrapper'
          style={{ display: this.state.secondLevel ? 'block' : 'none' }}
          onClick={() => this.setState({ secondLevel: '' })}
        />
        { this.state.modalVisible ? <PasswordModal
          title='修改密码'
          visible
          form={this.props.form}
          getCaptcha={() => this.onGetCaptcha()}
          resendCaptcha={() => this.setState({ ticking: false })}
          ticking={this.state.ticking}
          closeModal={() => this.setState({ modalVisible: false, ticking: false })}
          isPhoneLogin={isPhoneLogin}
          userAccount={userAccount}
          modifyPassword={(values, isPhoneLogin) => userHelper.modifyPassword(values, isPhoneLogin, () => {
            this.setState({ modalVisible: false, ticking: false })
            this.props.form.resetFields()
          })}
        /> : null }
      </div>
    )
  }
}

export default connect(state => ({ messagesCount: state.message, organization: state.common.organization }))(Form.create()(Header))
