import request from 'common/request'
import * as utils from 'common/utils'
/**
 * @file src/scripts/actions/common.js
 *
 * 通用action
 *
 * 由于大部分的action是很类似的，都是为了获取数据
 * 所以为了方便起见，定义了一个通用的action来获取数据
 * 通过这个方法获取的数据会被reducer自动解析并赋值到全局的state中
 */

import constants from 'common/constants'
import { message } from 'antd'
const US_PHONE_SERIES = ['USA', 'CAN']

/**
 * 自动获取
 * @param {String} topLevel 第一层
 * @param {String} secondLevel 第二层
 * @param {Object} data 数据
 * @param {String} key key
 * @return {void}
 */
export function fetch (topLevel: string, secondLevel: string, data, key?: string) {
  let options = constants.FETCHING_OPTIONS[topLevel][secondLevel]

  if (!key) {
    if (typeof data === 'string') {
      key = data

      let obj = {}
      if (options.default) {
        obj[options.default] = data
        data = obj
      }
    } else {
      key = utils.md5(data)
    }
  }

  const reduceType = `${topLevel.toUpperCase()}.AUTO_FETCHING`
  return function (dispatch, getState) {
    dispatch({
      type: reduceType,
      topLevel,
      secondLevel,
      key,
      completed: false
    })

    request({
      type: options.method || 'get',
      url: options.url,
      data
    }, function (err, res) {
      if (err) return message.error(err)

      if (options.take) {
        res = res[options.take]
      }

      dispatch({
        type: reduceType,
        topLevel,
        secondLevel,
        key,
        completed: true,
        data: res
      })
    })
  }
}

/**
 * 获取基础的配置信息
 * @return {Function} 获取信息的函数
 */
export function fetchConfigs () {
  return function (dispatch, getState) {
    request({
      type: 'get',
      url: '/api/subjects'
    }, function (err, res) {
      if (err) return
      dispatch({
        type: 'FETCH_CONFIGS',
        key: 'subjects',
        completed: true,
        data: res
      })
      request({
        type: 'get',
        url: '/api/examinations'
      }, function (err, res) {
        if (err) return
        dispatch({
          type: 'FETCH_CONFIGS',
          key: 'exams',
          completed: true,
          data: res
        })
      })
    })
    dispatch({
      type: 'FETCH_CONFIGS',
      key: 'organizations',
      completed: false
    })

    request({
      type: 'get',
      url: '/api/organizations?noPage=true&all=1'
    }, function (err, res) {
      if (err) return
      dispatch({
        type: 'FETCH_CONFIGS',
        key: 'organizations',
        completed: true,
        data: res.data
      })
    })

    request({
      type: 'get',
      url: '/api/productSubTypes'
    }, function (err, res) {
      if (err) return
      dispatch({
        type: 'FETCH_CONFIGS',
        key: 'productSubTypes',
        completed: true,
        data: res
      })
    })

    request({
      type: 'get',
      url: '/api/country-code/list'
    }, function (err, res) {
      if (err) return
      let codes = res.filter(el => el.country_code !== '1')
      const usCountries = res.filter(el => el.country_code === '1' && US_PHONE_SERIES.includes(el.alpha3))
      if (usCountries.length) {
        let newItem: any = {}
        newItem.country_code = '1'
        newItem.combine = true
        newItem.children = []
        for (var i = 0; i < usCountries.length; i++) {
          newItem.children[i] = {}
          newItem.children[i].alpha2 = usCountries[i].alpha2
          newItem.children[i].alpha3 = usCountries[i].alpha3
          newItem.children[i].country_name = usCountries[i].country_name
        }
        codes.unshift(newItem)
      }
      codes = codes.sort((a, b) => (a.country_code - 0) - (b.country_code - 0))
      dispatch({
        type: 'FETCH_CONFIGS',
        key: 'countryCodes',
        completed: true,
        data: codes
      })
    })
  }
}

/**
 * 自动获取单个案例
 * @param {*} topLevel 第一层
 * @param {*} secondLevel 第二层
 * @param {*} data 数据
 * @return {Function} 获取数据的函数
 */
export function fetchOne (topLevel, secondLevel, data) {
  let options = constants.FETCHING_OPTIONS[topLevel][secondLevel]
  return function (dispatch, getState) {
    dispatch({
      type: 'SCHOOL.AUTO_FETCHING_ONE',
      topLevel,
      secondLevel,
      completed: false
    })
    request({
      type: options.method || 'get',
      url: `${options.url}/${data}`
    }, function (err, res) {
      if (err) {
        message.error(err)
        return
      }
      dispatch({
        type: 'SCHOOL.AUTO_FETCHING_ONE',
        topLevel,
        secondLevel,
        completed: true,
        data: res
      })
    })
  }
}
