import * as React from 'react'

import { Modal } from 'antd'
import intl from 'react-intl-universal'

// 容器
class CommonModal extends React.Component<any, any> {
  // 渲染
  render () {
    return (
      <Modal
        closable
        title={this.props.title}
        visible={this.props.visible}
        maskStyle={this.props.maskStyle}
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        okText={this.props.okText || `${intl.get('ok')}`}
        cancelText={this.props.cancelText || `${intl.get('cancel')}`}
        wrapClassName={`commonModal ${this.props.classname ? this.props.classname : ''}`}
        footer={this.props.footer}
      >
        {this.props.header ? <div className='header'>{this.props.header}</div> : ''}
        <div className='contentBody'>{this.props.content}</div>
      </Modal>
    )
  }
}

export default CommonModal
