import { message } from 'antd'

function receiveOrganization (organization) {
  return {
    type: 'COMMON.ORGANIZATION',
    organization
  }
}

function fetchOrganization ({ organizationId = '' } = {}) {
  return (dispatch) => {
    return fetch(`/api/organization?id=${organizationId}`, {
      credentials: 'same-origin'
    })
      .then(res => res.json())
      .then(json => {
        if (json.code === 0) {
          dispatch(receiveOrganization(json.data))
        } else {
          throw new Error('non-zero code!')
        }
      })
      .catch(msg => message.error({ text: '获取机构信息失败', msg }))
  }
}

export {
  fetchOrganization
}
