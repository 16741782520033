import * as rest from 'common/rest'
import { message } from 'antd'

export function pushHistory (props, params) {
  const query = Object.assign({}, props.location.query, params)
  props.history.push({
    pathname: location.pathname,
    query
  })
}

export async function modifyPassword (values, isPhoneLogin, callback) {
  if (!values) return false
  const data: any = {
    password: values.password,
    newPassword: values.newPassword
  }
  if (isPhoneLogin) {
    data.captcha = values.captcha
  }
  const res = await rest.put('/api/user/password', data)
  if (res.code) return message.error({ text: '修改密码失败', msg: res.msg })
  callback && callback()
  return message.success('修改密码成功')
}
