import * as _ from 'lodash'
import * as utils from 'common/utils'
import * as rest from 'common/rest'
import constants from 'common/constants'
import { message } from 'antd'

export async function fetchServiceTeachers () {
  const url = `/api/teachers/with-cross-teachers?noPage=true&all=1&statusId=1&organizationId=${utils.getOrganizationId()}`
  const res = await rest.get(url, { credentials: 'same-origin' })
  if (res.code) return message.error({ text: '获取服务PT失败', msg: res.msg })
  return res.data.data
}

export async function fetchTeachTeachers () {
  const url = `/api/teachers/with-cross-teachers?noPage=true&organizationId=${utils.getOrganizationId()}`
  const res = await rest.get(url, { credentials: 'same-origin' })
  if (res.code) return message.error({ text: '获取教学PT失败', msg: res.msg })
  let result = (res.data.data || []).filter(teacher => teacher.statusId === constants.STATUS.NORMAL)
  return result
}

export async function getTeachers (organizationId, options = {}) {
  const qs = Object.assign({}, options, { organizationId, noPage: true })
  const url = utils.genUrl(qs, `/api/teachers`)
  const res = await rest.get(url, { credentials: 'same-origin' })
  return _.get(res, ['data', 'data']) || []
}

export async function getAutoTrialTeachers (options = {}) {
  const url = utils.genUrl(options, `/api/teachers/trial-teachers`)
  const res = await rest.get(url, { credentials: 'same-origin' })
  return _.get(res, ['data']) || []
}

export async function getDisabledTimeSlots (id: number, startTime?: Date, endTime?: Date) {
  if (!id) return []
  const url = utils.genUrl({ id, startTime, endTime }, '/api/teacher/disabled-time-slots')
  const res = await rest.get(url)
  if (res.code !== 0) {
    return message.error({
      text: '获取教师不可用时间失败',
      msg: res.msg
    })
  }
  return res.data
}

export async function getAvailableStaffs (id) {
  if (!id) return []
  const url = `/api/teacher/${id}/staffs`
  const res = await rest.get(url)
  if (res.code !== 0) {
    return message.error({
      text: '获取教师站点列表失败',
      msg: res.msg
    })
  }
  return res.data || []
}
