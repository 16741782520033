import * as utils from 'common/utils'
import config from 'common/config'
import { dispatch } from '../common/redux'
import * as rest from 'common/rest'
import { fetchProfile } from 'actions/profile'
import { message } from 'antd'

export async function handleConfirmVacation (isAbsencing, hideVacationModal) {
  const type = isAbsencing ? 'work' : 'absence'
  const words = isAbsencing ? '销假' : '请假'
  const url = `${config.url.www}/api/user/${type}`
  const res = await rest.put(url, { token: utils.getToken() }, { credentials: 'omit' })
  hideVacationModal && await hideVacationModal() // 隐藏对话框
  if (res.code) return message.error({ text: `${words}失败`, msg: res.msg })
  await dispatch(fetchProfile(utils.getUserId()) as any)
  return message.success(`${words}成功`)
}
