import config from 'common/config'
import * as React from 'react'
import PercentRing from 'components/common/percent_ring'
import Ellipsis from 'components/common/ellipsis'
import { Collapse, Tooltip } from 'antd'

const Panel: any = Collapse.Panel

const getPercent = (duration, time, percent?: number) => {
  if (!duration) return 0
  if (percent || percent === 0) return percent
  return Math.ceil((time || 0) * 100 / duration)
}

const patch = num => num >= 10 ? num : `0${num}`
const getTimeStr = duration => {
  const hour = Math.floor(duration / 3600)
  const minute = Math.floor((duration % 3600) / 60)
  const second = Math.floor(duration % 60)
  const arr = []
  hour ? arr.push(patch(hour)) : arr.push('00')
  minute ? arr.push(patch(minute)) : arr.push('00')
  second ? arr.push(patch(second)) : arr.push('00')
  return arr.join(':')
}

// 由于编辑器提示package是保留字, 故变量名使用package_

class Menu extends React.Component<any, any> {
  getTaskMenuItem = (date, task, textWidth?: number) => {
    const isActive = this.props.task.taskId === task.taskId
    const isCtqa = this.props.type === 'multiCtqa'
    const percent = getPercent(task.sliceInfo.duration, task.time, task.percent)
    return (
      <div
        className={`menu-item ${this.props.type !== 'task' && isActive ? 'active' : ''}`}
        id={`${isActive ? 'scroll-anchor-target' : ''}`}
        onClick={async () => this.props.onTaskChange(date, task)}
      >
        <PercentRing percent={isCtqa ? 100 : percent} />
        {Ellipsis(textWidth, task.name)}
        <div>{getTimeStr(task.sliceInfo.duration)}</div>
      </div>
    )
  }

  handleChangePlay = (slice, initTime = 0, params) => {
    const { courseId } = this.props
    const { id } = slice
    const { sectionIndex } = params
    if ('ontouchstart' in window) {
      location.href = `/play?sliceId=${id}&type=course&sectionIndex=${sectionIndex}&courseId=${courseId}&time=0`
    }

    this.props.onSliceChange(slice, initTime, params)
  }

  getSliceMenuItem = (slice, width, params: any = {}) => {
    let isActive = false
    const isCtqa = this.props.type === 'multiCtqa'
    if (params.sectionIndex) {
      isActive = params.sectionIndex === this.props.sectionIndex && slice.id == this.props.slice.id
    }
    const sliceTime = slice.time || 0
    const time = isActive ? Math.max(sliceTime, this.props.currentTime || 0) : sliceTime
    const percent = Math.max(getPercent(slice.duration, time), slice.percent || 0)
    return (
      <div
        className={`menu-item-slice ${isActive ? 'active' : ''}`}
        id={`${isActive ? 'scroll-anchor-target' : ''}`}
        onClick={() => this.handleChangePlay(slice, 0, params)}
      >
        <PercentRing percent={isCtqa ? 100 : percent} />
        {Ellipsis(width, slice.alias || slice.name)}
        <div>{getTimeStr(slice.duration)}</div>
      </div>
    )
  }

  getSectionMenuHeader = (section, index) => {
    return (
      <div className='section-menu-header'>
        <span className='line' />
        <span className='index'>{index + 1}</span>
        <span className='name'>{section.name}</span>
      </div>)
  }

  getSectionMenuItem = (courseId, isActive, params = {}) => {
    let sections = this.props.sections[courseId]
    sections = Array.isArray(sections) && typeof sections.sort === 'function' ? sections.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })) : sections
    if (!sections) return <div />
    return (
      <Collapse
        activeKey={this.props.sections[courseId].filter(i => i.isOpen).map(i => `${i.index}`)}
        onChange={async (key) => {
          await this.props.toggleSectionsOpen(courseId, key)
        }}
      >
        {sections.map((section, index) => {
          section.section = section.section.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
          const isActive = section.index === this.props.sectionIndex
          return (
            <Panel
              header={this.getSectionMenuHeader(section, index)}
              key={section.index}
              className={`menu-section ${isActive ? 'active' : ''}`}
              id={`menu-item-section-${section.index}`}
            >
              <span className='inner-line' />
              {section.section.map(
                slice => this.getSliceMenuItem(
                  slice,
                  this.props.width - 110,
                  Object.assign({}, params || {}, { sectionIndex: section.index })
                )
              )}
            </Panel>
          )
        })}
      </Collapse>
    )
  }

  getCourseMenuItem = (course, isActive, params = {}) => {
    return this.getSectionMenuItem(course.id, isActive, params)
  }

  getCourseMenuPanel = course => {
    const isActive = course.id === this.props.courseId
    return (
      <Panel
        header={course.name}
        key={course.id}
        className={`menu-course ${isActive ? 'active' : ''}`}
        id={`menu-item-course-${course.id}`}
      >
        {this.getCourseMenuItem(course, isActive)}
      </Panel>
    )
  }

  getPackageMenuPanel = package_ => {
    const packageId = package_.id
    const isActive = packageId === this.props.packageId
    const course = this.props.courses[package_.courseId]
    return (
      <Panel
        header={this.getPackageHeader(package_, isActive)}
        key={package_.id} className={`menu-package ${isActive ? 'active' : ''}`}
        id={`menu-item-package-${packageId}`}
      >
        {this.getCourseMenuItem(course, isActive, { packageId })}
      </Panel>
    )
  }

  getPackageHeader = (package_, isActive) => {
    if (package_.courses.length > 1) {
      const courses = package_.allCourses.map(i => this.props.courses[i])
      return (
        <div className='menu-package-header'>
          <div className='package-name'>
            {package_.name}
          </div>
          <div className='teachers'>
            {courses.map(i => (
              <Tooltip placement='top' title={`${this.props.teachers[i.teacherId].name}`} key={i.id}>
                <div
                  className='imgBox'
                  onClick={async e => {
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                    if (package_.courseId == i.id) return
                    await this.props.onPackageTeacherChange(package_.id, i.id)
                  }}
                >
                  <div
                    className={`${package_.courseId == i.id ? '' : 'show'}`}
                  />
                  <img
                    className={`avatar ${package_.courseId == i.id ? 'active' : ''}`}
                    src={`${config.url.cdnHost}${this.props.teachers[i.teacherId].smallAvatar}`}
                    onError={(e) => this.handleImageErrored(e)}
                    onClick={async e => {
                      e.stopPropagation()
                      e.nativeEvent.stopImmediatePropagation()
                    }}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )
    } else if (package_.courses.length === 1) {
      const course = this.props.courses[package_.courses[0]]
      return (
        <div className='menu-package-header'>
          <div className='package-name'>
            {package_.name}
          </div>
          <div className='teachers'>
            <Tooltip placement='top' title={`${this.props.teachers[course.teacherId].name}`}>
              <img
                onError={(e) => this.handleImageErrored(e)}
                className={`avatar ${package_.courseId == course.id ? 'active' : ''}`}
                src={`${config.url.cdnHost}${this.props.teachers[course.teacherId].smallAvatar}`}
              />
            </Tooltip>
          </div>
        </div>
      )
    }
  }

  handleImageErrored = async (e) => {
    e.target.src = require('images/teacherImgDefault.png')
  }

  render () {
    window['menuProps'] = this.props
    if (['multiCtqa'].includes(this.props.type)) {
      return (
        <div className={`menu-student ${this.props.type === 'multiCtqa' ? 'menu-ctqa' : ''}`} id='scroll-anchor'>
          <Collapse
            activeKey={this.props.dates.filter(i => i.isOpen).map(i => i.date)}
            onChange={this.props.toggleDatesOpen}
          >
            {this.props.dates.map(i => (
              <Panel
                header={i.date === 'none' ? '其他' : i.date}
                key={i.date}
                className={`menu-date ${i.date === this.props.date ? 'active' : ''}`}
                id={`menu-item-date-${i.date}`}
              >
                {(this.props.tasks[i.date] || []).map(task => this.getTaskMenuItem(i.date, task, this.props.width - 130))}
              </Panel>
            ))}
          </Collapse>
        </div>
      )
    } else if (this.props.type === 'student') {
      const { tasks, date } = this.props
      return (
        <div className='menu-task'>
          {
            (tasks[date] || []).map(task => {
              return this.getTaskMenuItem(date, task, this.props.width - 130)
            })
          }
        </div>
      )
    } else if (this.props.type === 'task') {
      return (
        <div className='menu-task'>
          {this.getTaskMenuItem(this.props.date, this.props.task)} // TODO: 因TS编译不通过，将参数从原来的只有task改成了现在的样子。需要检查
        </div>
      )
    } else if (this.props.type === 'slice') {
      return (
        <div className='menu-slice'>
          {this.getSliceMenuItem(this.props.slice, true)}
        </div>
      )
    } else if (this.props.type === 'trial') {
      return (
        <div className='menu-trial' id='scroll-anchor'>
          <Collapse
            activeKey={this.props.packages.filter(i => i.isOpen).map(i => `${i.id}`)}
            onChange={this.props.togglePackagesOpen}
          >
            {this.props.packages.map(package_ => this.getPackageMenuPanel(package_))}
          </Collapse>
        </div>
      )
    } else if (this.props.type === 'servicePack' || this.props.type === 'course') {
      let { courses } = this.props
      return (
        <div className='menu-product' id='scroll-anchor'>
          <Collapse
            activeKey={this.props.courses.filter(i => i.isOpen).map(i => `${i.id}`)}
            onChange={this.props.toggleCoursesOpen}
          >
            {courses.map(course => this.getCourseMenuPanel(course))}
          </Collapse>
        </div>
      )
    }
  }
}

export default Menu
