import * as React from 'react'
import constants from 'common/constants'
import { Table, Tooltip } from 'antd'

export class ReportTable extends React.Component<any, any> {
  state = {
    dataList: []
  }

  componentDidMount = async () => {
    await this.processData()
  }

  UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ = async () => {
    await this.processData()
  }

  processData = () => {
    const dataList = []
    for (let data of this.props.data) {
      for (let i = 0; i < data.tasks.length; i++) {
        const task = data.tasks[i]
        const list = {
          knowledgePointName: data.name,
          taskName: task.displayName,
          type: task.type,
          duration: task.taskTime,
          rowSpan: data.tasks.length,
          index: i,
          status: task.status
        }
        dataList.push(list)
      }
    }
    this.setState({
      dataList
    })
  }

  getColumns () {
    const columns = [
      {
        title: '需掌握知识点',
        dataIndex: 'knowledgePointName',
        key: 'knowledgePointName',
        width: '16%',
        render: (val, row) => this.renderKnowledge(val, row)
      },
      {
        title: '任务名称',
        dataIndex: 'taskName',
        key: 'taskName',
        width: '30%',
        render: (val) => this.renderTaskName(val)
      },
      {
        title: '任务类型',
        dataIndex: 'type',
        key: 'type',
        width: '18%',
        render: (val, row) => constants.SMART_ADAPTATION_TASK.TYPE_NAME[val]
      },
      {
        title: '学习所需时长（min）',
        dataIndex: 'duration',
        key: 'duration',
        width: '18%'
      }
    ]
    if (this.props.isStudyPlan) {
      columns.push({
        title: '任务状态',
        dataIndex: 'status',
        key: 'status',
        width: '18%',
        render: (val, row) => constants.ADAPTATION_TASK_STATUS[val]
      })
    }
    return columns
  }

  renderKnowledge = (val, row) => {
    return {
      children: val,
      props: {
        rowSpan: row.index ? 0 : row.rowSpan
      }
    }
  }

  renderTaskName = (val) => {
    return (
      <Tooltip placement='top' title={val}>
        <span className='task-name'>
          {val}
        </span>
      </Tooltip>
    )
  }

  render () {
    const { dataList } = this.state
    return (
      <div>
        {
          dataList.length
            ? <div className='report-table'>
              <header>
                {this.props.title}
                <span />
              </header>
              <Table
                dataSource={dataList}
                columns={this.getColumns()}
                bordered
                pagination={false}
              />
            </div>
            : ''
        }
      </div>
    )
  }
}

export default ReportTable
