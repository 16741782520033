import * as React from 'react'

const createReactClass = require('create-react-class')
// 组件
const Radio = createReactClass({
  // 渲染
  render () {
    let icon = this.props.active ? 'fa-check-square-o' : 'fa-square-o'

    return (
      <div
        className='component-common-form-radio'
        onClick={this.handleClick}
        style={this.props.style}
      >
        <span className={`fa ${icon}`} />
        <span className='title'>{this.props.title}</span>
      </div>
    )
  },

  // 处理点击
  handleClick () {
    if (typeof this.props.select === 'function') {
      this.props.select()
    }
  }
})

export default Radio
