/**
 * onlineType: 0 - 线下站点可用, 1 - 在线站点可用
 * serveType: 仅onlineType含1时起效
 * 1、只注册，但是没有购买任何商品
 * 2、购买过商品，但是购买的商品中不包含任何服务
 * 3、购买过商品，且购买的商品包括服务，但不包括面授课（包括1对1直播课和班课）课时
 * 4、购买了商品，且购买的商品包括服务，且包括面授课（包括1对1直播课和班课）课时
*/

const studentPermission = {
  ol_plans: {
    onlineType: [1],
    name: '学习计划',
    serveType: []
  },
  plans: {
    onlineType: [0],
    name: '全程计划',
    serveType: []
  },
  tasks: {
    name: '学习任务',
    onlineType: [0]
  },
  study: {
    name: 'VIP学习任务',
    onlineType: [1],
    serveType: [4]
  },
  course: {
    onlineType: [1],
    name: '专家课程',
    serveType: [1, 2, 3, 4]
  },
  student_banke: {
    name: '我的班课',
    onlineType: [0, 1],
    serveType: [1, 2, 3, 4]
  },
  smart_adaptation: {
    name: '智能学',
    onlineType: [1],
    serveType: [1, 2, 3, 4]
  },
  practices: {
    onlineType: [1],
    name: '练习&批改',
    serveType: [1, 2, 3, 4]
  },
  online_tests: {
    onlineType: [1],
    name: '模考&测评',
    serveType: [1, 2, 3, 4]
  },
  tests: {
    onlineType: [0],
    name: '我的测评'
  },
  publicClass: {
    name: '公开课',
    onlineType: [1],
    serveType: [1, 2, 3, 4]
  },
  notes: {
    onlineType: [0, 1],
    name: '我的笔记',
    serveType: [1, 2, 3, 4]
  },
  collections: {
    onlineType: [0, 1],
    name: '我的收藏',
    serveType: [1, 2, 3, 4]
  },
  questions: {
    onlineType: [0, 1],
    name: '我的提问',
    serveType: [4]
  },
  documents: {
    onlineType: [0, 1],
    name: '我的资料',
    serveType: [4]
  },
  knowledge_repository: {
    onlineType: [0],
    name: '知识库',
    serveType: []
  },
  IGCSE: {
    onlineType: [0, 1],
    name: 'IGCSE',
    serveType: [4]
  }
}

export default studentPermission
