import * as _ from 'lodash'
import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import uuid from 'node-uuid'
import * as sensorHelper from 'helpers/sensor'
import { any } from 'prop-types'

const RESOLUTION = {
  1: '标清',
  2: '标清',
  3: '高清',
  4: '高清',
  5: '流畅',
  7: '老版PC',
  8: '老版移动端'
}

export default class Player extends React.Component<any, any> {
  zkwPlayer: any
  player: any

  state = {
    videos: any,
    videoDuration: null,
    videoId: null,
    videoNew: null,
    activeTab: 'question',
    isCDNAvailable: false,
    tipId: '',
    done: true
  }
  played = false
  isPlaying = false
  isAvailable = false
  upLoadSetTime = null

  async componentDidMount () {
    window.addEventListener('beforeunload', this.onUnload)
    if (this.props.slice.name) {
      await this.newVideoPlay(this.props)
    }
    await this.checkCompleted()
    await this.watchAgain()
    if (this.props.onPlayerInit) {
      await this.props.onPlayerInit()
    }
  }

  async UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    const playerResolution = _.get(window, ['zkwPlayer', 'player', 'resolution_'])
    if (playerResolution) {
      if (playerResolution !== this.props.resolution) {
        this.props.onChangeResolution(playerResolution)
      }
    }
    if (this.props.slice.id !== nextProps.slice.id) {
      if (window['zkwPlayer'] && window['zkwPlayer'].player) {
        window['zkwPlayer'].player.dispose()
      }
      await utils.delay(1000)
      await this.forceUpdate()
      this.newVideoPlay(nextProps)
    }
  }

  onUnload = event => {
    let endTime = 0
    if (this.state.videoNew) {
      if (window['zkwPlayer'] && window['zkwPlayer'].player) {
        const currentTime = window['zkwPlayer'].player.currentTime()
        endTime = currentTime
      }
    } else {
      if (window['smartplayer']) {
        const currentTime = window['smartplayer'].getCurrentTime ? window['smartplayer'].getCurrentTime() : 0
        endTime = currentTime
      }
    }
    sensorHelper.trackVideoSensor(this.props, 'close', endTime)
  }

  componentWillUnmount () {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  newVideoPlay = async (props) => {
    const { slice } = props
    const versions = props.versions
    const isNew = this.isNew(versions)
    // 播放函数
    let play = async () => {
      if (this.played) return
      this.played = true
      this.isPlaying = true
      // 在播放的时候加上神策
      sensorHelper.trackVideoSensor(props, 'open')
      // 新视频
      if (isNew) {
        let videos = this.getVideos(versions)
        if (this.props.resolution === '高清') {
          videos = videos.reverse()
        }

        let videoId = uuid.v1()
        const tipId = slice.tipId
        this.setState({
          videoNew: true,
          videoId,
          videos,
          tipId,
          done: false
        })

        const cueTips = this.getCueTips(slice)

        await utils.delay(200)
        let zkwPlayerOptions = {
          videoId,
          playbackRates: [1, 1.2, 1.5, 2],
          // autoplay: true,
          plugins: {
            cueTips,
            customizedErrorDisplay: {
              htmlContent: '请<a href="javascript:location.reload();">刷新</a>当前页面重试，如果问题仍然出现<br />您可以<a target="_blank" href="https://www.baidu.com/s?wd=%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8">下载最新的Chrome浏览器</a>'
            },
            videoLog: {
              logToConsole: false,
              logData: {
                userId: utils.getOriginUserId(),
                detailedInfo: {
                  sliceId: slice.id,
                  // taskId: task.id,
                  source: 'apollon'
                }
              }
            }
          }
        }
        if (typeof window['DashHlsPlayer'] !== 'undefined' && window['DashHlsPlayer'] != null) {
          this.zkwPlayer = new window['DashHlsPlayer'](zkwPlayerOptions)
          if (this.zkwPlayer) {
            this.player = this.zkwPlayer.player || {}
            // this.player.on('timeupdate', this.getPlayerCurrentTime);
            this.player.on('loadeddata', () => {
              this.player.play()
            })
            this.player.on('ended', () => {
              const isFullscreen = window['zkwPlayer'].player.isFullscreen_
              if (isFullscreen) this.player.exitFullscreen()
              this.props.onPlayerFinish()
            })
            // this.player.on('play', this.props.handlePlayOfVideo);
            if (typeof window !== 'undefined') {
              window['player'] = this.player
            }
            window['zkwPlayer'] = this.zkwPlayer

            try {
              clearInterval(this.upLoadSetTime)
            } catch (e) {}
            this.upLoadSetTime = setInterval(() => {
              this.props.onUploadTime(this.props.slice, window['zkwPlayer'].player.currentTime())
            }, 5000)
          }
        }
      } else {
        let video = this.getVideo(versions)
        this.setState({
          videoNew: false,
          videoId: video.vid,
          videoDuration: video.dest || video.info ? Math.floor(JSON.parse(video.dest || video.info).duration / 1000) : 0
        })
      }

      if (this.props.time) {
        await utils.delay(1000)
        // this.viewNote({ timeOrigin: this.props.time })() // TODO: 不存在的函数，需要检查
      }
    }

    // 判断是否需要使用CDN
    this.played = false
    const cacheHost = this.props.organization.cdn
    if (!isNew) return play()
    if (versions.length) {
      const item = versions[0]
      if (item.status !== 1) return
      if (item.format > 4) return
      let path = `${item.dest}/dest.${(item.format % 2 === 1 ? 'mpd' : 'm3u8')}`

      if (cacheHost && cacheHost.includes('//')) {
        const res: any = await Promise.race([
          fetch(cacheHost + path, { credentials: 'omit' }).then(res => res.text()),
          new Promise((resolve, reject) => {
            setTimeout(() => reject(new Error('request timeout')), 1500)
          })
        ]).catch(err => console.log(`cacheServer ${cacheHost} failed, ${err}.`))

        if (res) {
          if (res.includes('#EXTM3U') || res.includes('xml')) {
            this.isAvailable = true
            await play()
          }
        }
      }
    }
    await play()
  }

  pause = async () => {
    if (window['zkwPlayer'] && window['zkwPlayer'].player) {
      window['zkwPlayer'].player.pause()
      const currentTime = window['zkwPlayer'].player.currentTime()
      await this.props.onUploadTime(this.props.slice, currentTime)
    } else {
      // ...
    }
  }

  watchAgain = () => {
    setTimeout(() => {
      if (window['zkwPlayer'] && window['zkwPlayer'].player) {
        if (this.props.slice.time >= 0 && !this.props.slice.tipId) {
          if (this.props.slice.time == 0) {
            window['zkwPlayer'].player.currentTime(2)
          } else {
            window['zkwPlayer'].player.currentTime(this.props.slice.time)
          }
          window['zkwPlayer'].player.play()
        } else if (this.props.slice.percent && !this.props.slice.tipId && !this.props.slice.time) {
          window['zkwPlayer'].player.currentTime(this.props.slice.percent * this.props.slice.duration / 100)
          window['zkwPlayer'].player.play()
        }
      }
    }, 2000)
  }

  checkCompleted = () => {
    if (this.state.done) return setTimeout(this.checkCompleted, 1000)
    if (this.state.videoNew) {
      if (window['zkwPlayer'] && window['zkwPlayer'].player) {
        const currentTime = window['zkwPlayer'].player.currentTime()
        const duration = window['zkwPlayer'].player.duration()
        if (!duration) return setTimeout(this.checkCompleted, 1000)
        if (currentTime < 3) return setTimeout(this.checkCompleted, 1000)
        if (this.isPlaying && duration - currentTime < 20) {
          this.props.onPlayerWillFinish()
          this.isPlaying = false
        }
        if (duration - currentTime < 1) {
          this.props.onUploadTime(this.props.slice, window['zkwPlayer'].player.currentTime(), true)
          this.setState({ done: true })
          clearInterval(this.upLoadSetTime)
          // this.props.onPlayerFinish();
        }
      }
    } else {
      if (window['smartplayer']) {
        window['smartplayer'].getCurrentTime && window['smartplayer'].getCurrentTime()
        window['player'] = {}
        window['player'].getCurrentTimeCallback = currentTime => {
          this.setState({ currentTime: parseInt(currentTime) })

          if (this.state.videoDuration - currentTime < 1) {
            this.props.onUploadTime(this.props.slice, window['zkwPlayer'].player.currentTime())
            this.setState({ done: true })
            this.props.onPlayerFinish()
            clearInterval(this.upLoadSetTime)
          }
        }
      }
    }

    setTimeout(this.checkCompleted, 1000)
  }

  setTime = time => {
    if (window['zkwPlayer']) {
      window['zkwPlayer'].player.currentTime(time)
      window['zkwPlayer'].player.play()
    }
  }

  isNew = versions => {
    let isNew = false
    versions.forEach(item => {
      if (item.status !== 1) return
      if (item.format > 4) return
      isNew = true
    })
    return isNew
  }

  getVideos = versions => versions
    .filter(i => i.status === 1 && i.format <= 4)
    .map(item => {
      let src = `${(this.props.organization.cdn && this.isAvailable ? this.props.organization.cdn : config.url.videoCDN) + item.dest}/dest.${item.format % 2 === 1 ? 'mpd' : 'm3u8'}`
      let resolution = RESOLUTION[item.format] || '--'
      let type = item.format % 2 === 1 ? 'application/dash+xml' : 'application/x-mpegURL'
      const userAgent = window.navigator ? window.navigator.userAgent : ''
      if (userAgent.indexOf('Safari') != -1 && userAgent.indexOf('Version') != -1 && src.indexOf('.mpd') !== -1) {
        return ''
      }
      return (<source key={item.id} src={src} data-res={resolution} type={type} />)
    });

  getVideo = versions => versions
    .find(item => item.status === 100 && item.format >= 7) ||
    {};

  getCueTips = slice => {
    let tips = {}
    slice.tips && Object.assign(tips, slice.tips)

    return Object.keys(tips).length ? {
      showSummaryTip: true,
      startTip: this.state.tipId || 0,
      tips
    } : false
  }

  render () {
    return (
      <div className='player'>
        <div className='content'>
          { this.props.renderVideoTopMessage && this.props.renderVideoTopMessage() }
          <div id='videoDiv' className='video dash-hls-video-player' key={this.props.slice.id}>
            {(() => {
              if (!this.state.videoId) return null

              if (this.state.videoNew) {
                return (
                  <video
                    id={this.state.videoId}
                    className='video-js vjs-big-play-centered'
                  >
                    {this.state.videos}
                  </video>
                )
              }
              return (
                <object
                  type='application/x-shockwave-flash'
                  id='smartplayer'
                  name='smartplayer'
                  // align='middle' // TODO: 不支持的属性，暂时隐藏掉。需要检查
                  data='/swfs/player.swf'
                  width='100%'
                  height='100%'
                >
                  <param name='quality' value='high' />
                  <param name='bgcolor' value='' />
                  <param name='allowscriptaccess' value='always' />
                  <param name='allowfullscreen' value='true' />
                  <param name='wmode' value='transparent' />
                  <param name='scale' value='noScale' />
                  <param name='salign' value='TL' />
                  <param name='flashvars' value={`before_play=&section_id=apollon:${this.state.videoId}&token=&section_version=&server_path=rtmp://myms.smartstudy.com:8080/study&buy=1&host=https://www.smartstudy.com&cdn=${this.props.organization.cdn ? this.props.organization.cdn.replace('.smartstudy.com', '-old.smartstudy.com') : ''}&chain=${this.props.organization.isAntiChain ? 'yes' : 'no'}`} />
                </object>
              )
            })()}
          </div>
        </div>
      </div>
    )
  }
}
