import * as utils from 'common/utils'

// 系统中的侧边栏模块结构，配合react-sider应用的数据格式
export default (sign = true) => {
  return {
    platform: [{
      name: '配置管理',
      icon: 'setting',
      path: 'platform',
      key: 'config',
      children: [
        {
          name: '机构管理',
          key: 'organization',
          icon: 'share-alt',
          insideVisiable: false,
          path: 'organizations'
        },
        {
          name: '客户管理',
          key: 'clients',
          icon: 'share-alt',
          insideVisiable: true,
          path: 'clients'
        },
        {
          name: '角色管理',
          key: 'role',
          icon: 'solution',
          path: 'roles'
        },
        {
          name: 'LOV值管理',
          key: 'lov',
          insideVisiable: false,
          icon: 'tag-o',
          path: 'lovs'
        },
        {
          name: '管理员管理',
          key: 'admin',
          icon: 'lock',
          path: 'admins'
        },
        {
          name: '练习时长管理',
          key: 'practiceDuration',
          icon: 'book',
          path: 'exam-type-conf',
          insideVisiable: false
        }
      ]
    }],
    school: [
      {
        name: 'Dashboard',
        key: 'dashboard',
        icon: 'dashboard',
        path: 'dashboard'
      },
      {
        name: sign ? '我的主页' : 'Dashboard',
        key: 'myHomepage',
        icon: 'home',
        path: 'my-homepage'
      },
      // 学员管理/我的学员作为一个废弃的模块先不放进来
      {
        name: sign ? (global.IS_INSIDE ? '学员管理' : '转案管理') : 'Students',
        key: 'transfer',
        icon: 'retweet',
        path: 'transfer',
        children: [
          {
            name: sign ? '转案管理' : 'Orders', // 总部层和其他线下站点的转案列表
            key: 'transfer',
            icon: 'swap',
            path: 'transfer-list'
          },
          {
            name: sign ? '学员列表' : 'Students',
            key: 'studentList',
            icon: 'menu-fold',
            path: 'student-list',
            insideVisiable: true
          },
          {
            name: sign ? '学员档案' : 'Student Profile',
            key: 'studentArchives',
            icon: 'file-text',
            path: 'student-archives'
          },
          {
            name: sign ? '学员成绩' : 'Gradebook',
            key: 'studentScore',
            icon: 'solution',
            path: 'student-score'
          },
          {
            name: sign ? '批改授权管理' : 'Revise Management',
            key: 'pigaiAuthorizationManage',
            icon: 'copyright',
            path: 'pigai-authorization-manage'
          }
        ]
      },
      {
        name: sign ? '学习管理' : 'Study Management',
        key: 'learn',
        icon: 'calendar',
        path: 'study',
        children: [
          {
            name: sign ? '测评管理' : 'Test Management',
            key: 'evaluation',
            icon: 'file-text',
            path: 'evaluation'
          },
          {
            name: sign ? '学习任务' : 'Study Tasks',
            key: 'dayTask',
            icon: 'check-square-o',
            path: 'student-task'
          },
          {
            name: sign ? '学习任务' : 'Study Tasks',
            key: 'studyTask',
            icon: 'check-square-o',
            path: 'online-student-task'
          },
          {
            name: sign ? '教学计划' : 'Syllabus',
            key: 'teachingPlans',
            icon: 'check-square-o',
            path: 'teaching-plans'
          },
          {
            name: sign ? 'PT日报' : 'Daily Review',
            key: 'ptDaily',
            icon: 'file-excel',
            path: 'pt-daily'
          },
          {
            name: sign ? 'PT日报' : 'Daily Review',
            key: 'ptDailyAdmin',
            icon: 'file-excel',
            path: 'pt-daily'
          },
          {
            name: sign ? 'AI组卷' : 'Test Paper Generator',
            key: 'AITestPaperGenerator',
            icon: 'file-excel',
            path: 'test-paper-generator'
          },
          {
            name: '学习历史',
            key: 'studyHistory',
            icon: 'file-excel',
            path: 'study-history'
          },
          {
            name: '学习周报',
            key: 'weekly',
            icon: 'file-excel',
            path: 'weekly-list'
          }
        ]
      },
      {
        name: sign ? '面授管理' : 'Administration',
        key: 'schedule',
        icon: 'team',
        path: 'schedule',
        children: [
          {
            name: sign ? '排课申请' : 'Lesson Scheduling',
            key: 'teachingReq',
            icon: 'edit',
            path: 'teaching-reqs'
          },
          {
            name: sign ? '调课申请' : 'Lesson Adjustment',
            key: 'changingReq',
            icon: 'sync',
            path: 'changing-reqs'
          },
          {
            name: sign ? '班课管理(旧)' : 'Class Management(old)',
            key: 'newGroupCourseManage',
            icon: 'plus-circle-o',
            path: 'new-group-course'
          },
          {
            name: sign ? '班课管理' : 'Class Management',
            key: 'banke',
            icon: 'plus-circle-o',
            path: 'banke'
          },
          {
            name: sign ? '试听课申请' : 'Audit Scheduling',
            key: 'trialCourseRequest',
            icon: 'plus-circle',
            path: 'trial-course-requests'
          },
          {
            name: sign ? '学员课表' : 'Student Schedules',
            key: 'studentSchedule',
            icon: 'calendar',
            path: 'student-schedule'
          },
          {
            name: sign ? '教师课表' : 'Teacher Schedules',
            key: 'teacherSchedule',
            icon: 'calendar',
            path: 'teacher-schedule'
          },
          {
            name: sign ? '教室课表' : 'Classroom Schedules',
            key: 'classroomScheduleSchool',
            icon: 'calendar',
            path: 'classroom-usage'
          },
          {
            name: sign ? '教师饱和度' : 'Teacher Work Saturation',
            key: 'teacherStatSchool',
            icon: 'pie-chart',
            path: 'teacher-schedule-overview-school'
          },
          {
            name: sign ? '教室饱和度' : 'Classroom Saturation',
            key: 'classroomStatSchool',
            icon: 'pie-chart',
            path: 'classroom-schedule-overview-school'
          }
        ]
      },
      {
        name: sign ? '教学管理' : 'Teaching Management',
        key: 'teaching',
        icon: 'edit',
        path: 'teaching',
        children: [
          {
            name: sign ? '教师管理' : 'Teacher Management',
            key: 'teacher',
            icon: 'team',
            path: 'teachers'
          },
          {
            name: sign ? '教师时间管理' : 'Teacher Time Management',
            key: 'teacherTime',
            icon: 'clock-circle-o',
            path: 'teacher-time'
          },
          {
            name: '教师绩效系数',
            key: 'performance',
            icon: 'folder-open',
            path: 'performance'
          },
          {
            name: sign ? '模板管理' : 'Template Management',
            key: 'template',
            icon: 'copy',
            path: 'template'
          },
          {
            name: sign ? '资料管理' : 'Files',
            key: 'document',
            icon: 'folder-open',
            path: 'documents'
          },
          {
            name: sign ? '答疑管理' : 'Q&A',
            key: 'question',
            icon: 'question-circle-o',
            path: 'questions'
          }
        ]
      },
      {
        name: sign ? '学校管理' : 'School Management',
        key: 'school',
        icon: 'home',
        path: 'school-manage',
        children: [
          {
            name: sign ? '组织管理' : 'Department Management',
            key: 'structure',
            icon: 'layout',
            path: 'structures'
          },
          {
            name: sign ? '员工管理' : 'Staff Management',
            key: 'staff',
            icon: 'team',
            path: 'staffs'
          },
          {
            name: sign ? '教室管理' : 'Classroom Management',
            key: 'classroom',
            icon: 'windows',
            path: 'classrooms'
          },
          {
            name: sign ? '商品管理' : 'Product Management',
            key: 'product',
            icon: 'windows',
            path: 'goods-management',
            insideVisiable: true
          },
          {
            name: '直播间管理',
            key: 'studio',
            icon: 'video-camera',
            path: 'studios'
          }
        ]
      },
      {
        name: '教务管理',
        key: 'teachingAdmin',
        icon: 'solution',
        path: 'teaching-work',
        children: [
          {
            name: '待排课程',
            key: 'arrangement',
            icon: 'bars',
            path: 'arrangement'
          },
          {
            name: '待调课程',
            key: 'changing',
            icon: 'bars',
            path: 'changing'
          },
          {
            name: '班课管理(旧)',
            key: 'newGroupCourseManageAdmin',
            icon: 'tag-o',
            path: 'new-group-course'
          },
          {
            name: '班课管理',
            key: 'bankeNew',
            icon: 'plus-circle-o',
            path: 'banke'
          },
          {
            name: '试听课管理',
            key: 'trialCourseManage',
            icon: 'tag',
            path: 'trial-course-requests'
          },
          {
            name: '退换课管理',
            key: 'exchangeCourseManage',
            icon: 'swap',
            path: 'exchange-course'
          },
          {
            name: '学员课表',
            key: 'allStudentSchedule',
            icon: 'calendar',
            path: 'student-schedule'
          },
          {
            name: '教师课表',
            key: 'allTeacherSchedule',
            icon: 'calendar',
            path: 'teacher-schedule'
          },
          {
            name: '教室课表',
            key: 'classroomSchedule',
            icon: 'calendar',
            path: 'classroom-usage'
          },
          {
            name: '教师时间管理',
            key: 'teacherCalendar',
            icon: 'calendar',
            path: 'teacher-time-manage'
          },
          {
            name: '教师饱和度',
            key: 'teacherStat',
            icon: 'pie-chart',
            path: 'teacher-schedule-overview'
          },
          {
            name: '教室饱和度',
            key: 'classroomStat',
            icon: 'pie-chart',
            path: 'classroom-schedule-overview'
          },
          {
            name: '质检管理',
            key: 'qualityManage',
            icon: 'area-chart',
            path: 'quality-inspection-management'
          }
        ]
      },
      {
        name: sign ? '数据统计' : 'Statistics',
        key: 'statistics',
        icon: 'line-chart',
        path: 'statistics',
        children: [
          {
            name: sign ? '学员课时' : 'Student Hours',
            key: 'studentCourseHours',
            icon: 'area-chart',
            path: 'student-course-hours'
          },
          {
            name: sign ? '教师课时' : 'Teacher Hours',
            key: 'teacherCourseHours',
            icon: 'area-chart',
            path: 'teacher-course-hours'
          },
          {
            name: '教师课时（指）',
            key: 'onlineTeacherCourseHours',
            icon: 'area-chart',
            path: 'online-teacher-course-hours'
          },
          {
            name: sign ? '教师课消' : 'Teacher Service Hours',
            key: 'expendedTeacherCourseHours',
            icon: 'area-chart',
            path: 'expended-teacher-course-hours'
          },
          {
            name: sign ? '教师绩效' : 'Teacher Performance',
            key: 'teacherReward',
            icon: 'area-chart',
            path: 'teacher-reward'
          },
          {
            name: sign ? '学员签到' : 'Attendance',
            key: 'studentSign',
            icon: 'area-chart',
            path: 'student-sign'
          },
          {
            name: sign ? '学习任务统计' : 'StudyTask',
            key: 'studyTaskStatistics',
            icon: 'area-chart',
            path: 'study-task-statistics'
          },
          {
            name: sign ? '测评统计' : 'MokaoTask',
            key: 'mokaoStatistics',
            icon: 'area-chart',
            path: 'mokao-statistics'
          },
        ]
      },
      {
        name: sign ? '通知中心' : 'Notifications',
        key: 'messages',
        icon: 'bell',
        path: 'messages',
        children: [
          {
            name: sign ? '通知中心' : 'Notifications',
            key: 'myMessages',
            icon: 'bell',
            path: 'my-messages'
          }
        ]
      }
    ],
    student: [ // 学生端没有使用react-sider，应用原来的student-panel的数据格式
      { text: '学习任务', url: 'tasks', icon: 'offline_vip' },
      { text: 'VIP学习任务', url: 'study', icon: 'vip' },
      { text: '专家课程', url: 'course', icon: 'expert' },
      { text: '智能学', url: 'smart-adaptation', icon: 'smart_adaptation' },
      { text: '我的班课', url: 'student_banke', icon: 'vip' },
      { text: '练习&批改', url: 'practices', icon: 'practice_pigai' },
      { text: '我的测评', url: 'tests', icon: 'mokao' },
      { text: '模考&测评', url: 'online_tests', icon: 'mokao' },
      { text: '公开课', url: 'publicClass', icon: 'public_class' },
      { text: '我的提问', url: 'questions', icon: 'my_questions' },
      { text: '我的笔记', url: 'notes', icon: 'my_notes' },
      { text: '我的资料', url: 'documents', icon: 'my_docs' },
      { text: '我的收藏', url: 'collections', icon: 'my_collections' },
      {
        text: '知识库',
        url: 'knowledge_repository',
        icon: 'knowledge_repository',
        show() {
          if (global.IS_INSIDE && [88, 160].includes(utils.getOrganizationId())) {    // 北京春狮教育|ACG 不要知识库
            return false
          }
          return true
        }
      },
      { text: 'IGCSE', url: 'IGCSE', icon: 'knowledge_repository', insideVisiable: true, openNewTab: true, tabUrl: 'https://app.mysecondteacher.com/login', organizationId: 37 }
    ]
  }
}
