import * as React from 'react'

const createReactClass = require('create-react-class')
// 组件
const Textarea = createReactClass({
  // 获取初始状态
  getInitialState () {
    return {}
  },

  // 元素更新
  componentDidUpdate () {
    if (this.state.lastDefaultValue !== this.props.defaultValue) {
      this.setState({
        lastDefaultValue: this.props.defaultValue,
        value: undefined
      })
    }
  },

  // 渲染
  render () {
    return (
      <div
        className='component-common-form-textarea'
      >
        <textarea
          placeholder={this.props.placeholder}
          value={this.state.value === undefined ? this.props.defaultValue : this.state.value}
          onChange={this.handleChange}
        />
      </div>
    )
  },

  // 处理改变
  handleChange (e) {
    this.setState({
      value: e.target.value
    })
  },

  // 获取值
  getValue () {
    return this.state.value
  }
})

export default Textarea
