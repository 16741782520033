/**
 * @file src/scripts/common/global.js
 *
 * 全局变量
 *
 * 为了更快速方便的进行开发，定义了一些全局变量
 * 注：所有的全局变量必须在本文件进行定义
 */

import moment from 'moment-timezone'
import * as utils from 'common/utils'
import { message } from 'antd'
import config from './config'

const oldMessageError = message.error
message.error = (info: any, seconds = 3) => {
  if (typeof info === 'string') {
    return oldMessageError(info, seconds)
  }
  if (typeof info === 'object') {
    const arr = []
    info.text && arr.push(info.text)
    info.msg && arr.push(info.msg)
    return oldMessageError(arr.join(', '), seconds)
  }
}

// 全局变量
global['store'] = require('store')
global.Raven = require('raven-js')
global.NODE_ENV = process.env.NODE_ENV
global.CLIENT_NAME = process.env.CLIENT_NAME
global.CLIENT_VERSION = process.env.CLIENT_VERSION
global.BUILD_TIME = process.env.BUILD_TIME
global.IS_INSIDE = !!process.env.IS_INSIDE

let ravenConfig = {
  autoBreadcrumbs: {
    xhr: true, // XMLHttpRequest
    console: true, // console logging
    dom: true, // DOM interactions, i.e. clicks/typing
    location: true, // url changes, including pushState/popState
    sentry: true // sentry events
  },
  whitelistUrls: [/sp(-staging)?\.smartstudy\.com/, /media[\d]+\.smartstudy\.com\/apollon/],
  ignoreErrors: [
    /ResizeObserver loop limit exceeded/,                // no need to deal with this
  ],
}
// 加载本地自定义配置
if (process.env.NODE_ENV === 'development') {
  delete ravenConfig.whitelistUrls
}

global.Raven.config(
  config.url.sentry,
  ravenConfig
).install()

// 因为暂时练习报告不太支持https，这么做是因为前端跨项目的接口还是要保持https，但查看报告链接需要是http。
config.url.exerciseFront = config.url.exercise.startsWith('//') ? `http:${config.url.exercise}` : config.url.exercise

const originFetch = global['fetch']
const rap = config.rap
const smartProject = rap && rap.projects ? rap.projects.find(project => !project.host) : {}
global['fetch'] = (url, options, ...args) => {
  const timezone = moment().utcOffset() / 60
  const fingerprint = utils.getLocalStorage('fingerprint')
  const isAinAPI = url.startsWith('/api')

  options = {
    ...options,
    headers: isAinAPI ? {
      ...(options && options.headers),
      timezone,
      'X-APOL-VERSION': global.CLIENT_VERSION,
      'X-APOL-CLIENT': global.CLIENT_NAME,
      'X-APOL-BUILD-TIME': global.BUILD_TIME,
      fingerprint
    } : {
      ...(options && options.headers)
    }
  }
  let URL = url
  if (rap && rap.projects) {
    const matchedProject = rap.projects.find(project => (URL.startsWith(project.host))) || smartProject
    const relativeURL = URL.replace(matchedProject.host, '')
    const matchedApi = matchedProject.apis.find(api => api.test(relativeURL))
    if (matchedApi) {
      URL = `${rap.host}/mockjsdata/${matchedProject.id}${relativeURL}`
      options = {
        ...options,
        credentials: 'omit',
        headers: {}
      }
    }
  }
  return originFetch(URL, options, ...args)
}

// 以前遗留的做法是所有的style文件都在src/styles目录下，然后拼接一个require所有文件的文件global_index，然后require
// 这个global_index文件, 下面直接require这个目录的所有style文件, 不需要拼接
function requireAll (requireContext) {
  return requireContext.keys().map(requireContext)
}
requireAll((require as any).context('../../styles', true, /.*scss/))

export {}
