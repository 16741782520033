import * as React from 'react'

export default class VideoMask extends React.Component<any, any> {
  render () {
    return (
      <div className={`video-mask ${this.props.visible ? '' : 'hide'}`}>
        {this.props.nextName
          ? <div>
            <div className='video-mask-play'>即将播放</div>
            <div className='video-mask-title'>
              {this.props.nextName}
            </div>
            <div className='video-mask-button' onClick={this.props.onPlayNext}>
              <div className='circleProgress_wrapper'>
                <div className='wrapper right'>
                  <div className='circleProgress rightcircle' />
                </div>
                <div className='wrapper left'>
                  <div className='circleProgress leftcircle' />
                </div>
                <div className='button-play' />
              </div>
            </div>
            <div className='video-mask-replay' onClick={this.props.onPlayNext}>
              立即前往
            </div>
          </div>
          : <div>
            <div className='video-mask-title'>
              {this.props.thisName}
            </div>
            <div className='video-mask-button' onClick={this.props.onReplay}>
              <div className='circle'>
                <div className='button-play' />
              </div>
            </div>
            <div className='video-mask-replay' onClick={this.props.onReplay}>
              重新观看
            </div>
          </div>
        }
      </div>
    )
  }
}
