const SCOPES = {
  A1: [8 * 60 + 30, 9 * 60 + 30],
  A2: [9 * 60 + 30, 10 * 60 + 30],
  B1: [10 * 60 + 40, 11 * 60 + 40],
  B2: [11 * 60 + 40, 12 * 60 + 40],
  C1: [13 * 60 + 30, 14 * 60 + 30],
  C2: [14 * 60 + 30, 15 * 60 + 30],
  D1: [15 * 60 + 40, 16 * 60 + 40],
  D2: [16 * 60 + 40, 17 * 60 + 40],
  E1: [18 * 60 + 30, 19 * 60 + 30],
  E2: [19 * 60 + 30, 20 * 60 + 30],
  F1: [20 * 60 + 40, 21 * 60 + 40],
  F2: [21 * 60 + 40, 22 * 60 + 40]
}

const ScopeSlotMap = {
  10: { name: 'A1', detail: '08:30~09:30' },
  20: { name: 'A2', detail: '09:30~10:30' },
  30: { name: 'B1', detail: '10:40~11:40' },
  40: { name: 'B2', detail: '11:40~12:40' },
  50: { name: 'C1', detail: '13:30~14:30' },
  60: { name: 'C2', detail: '14:30~15:30' },
  70: { name: 'D1', detail: '15:40~16:40' },
  80: { name: 'D2', detail: '16:40~17:40' },
  90: { name: 'E1', detail: '18:30~19:30' },
  100: { name: 'E2', detail: '19:30~20:30' },
  110: { name: 'F1', detail: '20:40~21:40' },
  120: { name: 'F2', detail: '21:40~22:40' }
}

const WEEK_DAYS = {
  0: '周日',
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六'
}

const ONE_DAY = 24 * 3600 * 1000

export default {
  SCOPES,
  ScopeSlotMap,
  ONE_DAY,
  WEEK_DAYS
}
