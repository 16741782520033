
const initialState = { organizations: {}, organization: {} }

/**
 * 在State中添加机构信息
 * @param {Object} state state
 * @param {Object} action 动作
 * @return {Object} state
 */
export default function organizations (state = initialState, action) {
  switch (action.type) {
    // all organizations
    case 'PLATFORM.ORGANIZATIONS':
      return Object.assign({}, state, {
        organizations: action.organizations
      })
    case 'COMMON.EXAMINATIONS':
      return Object.assign({}, state, {
        examinations: action.examinations
      })
    // login user's organization info
    case 'COMMON.ORGANIZATION':
      return Object.assign({}, state, {
        organization: action.organization
      })
    default:
      return state
  }
}
