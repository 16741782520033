import * as React from 'react'
import { Input } from 'antd'
const { TextArea } = Input

class ControlledTextarea extends React.Component<any, any> {
  handleFocus = () => {
    this.props.onFocus()
  }

  handleChange = e => {
    this.props.onChange(e)
  }

  render () {
    const { limit, currentNum, content, disabled } = this.props
    return (
      <div className='textarea-wrapper'>
        <TextArea
          value={content}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <span className='progress'>{`${currentNum}/${limit}`}</span>
      </div>
    )
  }
}

export default ControlledTextarea
