export default (state = null, action) => {
  switch (action.type) {
    case 'USER.SAVE':
    case 'USER.FETCHING':
      return {
        completed: action.completed,
        data: action.data
      }
    case 'USER.CLEAR':
      return null
    default:
      return state
  }
}
