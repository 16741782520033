import * as utils from 'common/utils'
import * as rest from 'common/rest'
import constants from 'common/constants'
import * as qs from 'qs'
import { message } from 'antd'
import * as _ from 'lodash'

export function getStudentId () {
  return utils.getUserId()
}

export async function getStudent (id?: number) {
  id = id || getStudentId()
  const data = { id }
  const url = `/api/student?${qs.stringify(data)}`
  const res = await rest.get(url)
  return res.data
}

export async function isAbsencing (id?: number) {
  const student = await getStudent(id)
  return student.isAbsencing
}

export async function fetchStudentToken (studentId) {
  if (!studentId) return
  const res = await rest.get(`/api/student/token?id=${studentId}`)
  if (res.code) return message.error({ text: '获取学员token信息失败', msg: res.msg })
  return res.data
}

export async function checkAbsence (callback) {
  let userTypeId = utils.getUserTypeId()
  if (userTypeId !== constants.USER_TYPE.STUDENT) return callback({ isAbsencing: false, absenceTimes: 0 })
  const id = getStudentId()
  const student = await getStudent(id)
  callback && await callback({
    isAbsencing: student.isAbsencing,
    absenceTimes: student.absenceTimes
  })
}

/**
 * 重置学生的密码
 * @param {number} studentId 学生ID
 * @return {string} 新密码
 */
export async function resetPassword (studentId) {
  const res = await rest.post(`/api/student/${studentId}/reset-password`)
  if (res.code) {
    message.error({ text: '重置密码失败', msg: res.msg })
    return ''
  }
  return _.get(res, ['data', 'newPassword']) || ''
}
