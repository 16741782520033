import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import socket from 'common/socket'
import * as _ from 'lodash'
import * as utils from 'common/utils'
import * as rest from 'common/rest'
import MyLoadable from 'components/loadable'
import ErrorBoundary from 'components/common/error'
import moment from 'moment'
import { message } from 'antd'
// 学员层的base container

const routes = [
  {
    path: 'study',
    component: MyLoadable({
      loader: () => import('containers/school/study-task')
    })
  },
  {
    path: 'course', // 专家课程
    component: MyLoadable({
      loader: () => import('containers/student/course')
    }),
    isExact: true
  },
  {
    path: 'course_package/:id', // 专家课程详情
    component: MyLoadable({
      loader: () => import('containers/student/course_package')
    })
  },
  {
    path: 'report/view/:id', // 查看模考报告
    component: MyLoadable({
      loader: () => import('containers/school/report-detail')
    })
  },
  {
    path: 'practices', // 练习列表
    component: MyLoadable({
      loader: () => import('containers/student/practices')
    })
  },
  {
    path: 'tasks', // VIP学习任务
    component: MyLoadable({
      loader: () => import('containers/student/task')
    })
  },
  {
    path: 'pt_daily/:watch?', // 查看日报
    component: MyLoadable({
      loader: () => import('containers/school/pt_daily')
    })
  },
  {
    path: 'student_banke/:view?/:id?', // 查看班课
    component: MyLoadable({
      loader: () => import('containers/student/banke_list')
    })
  },
  {
    path: 'weekly', // 查看周报
    component: MyLoadable({
      loader: () => import('components/school/banke/banke_detail/report/weekly')
    })
  },
  {
    path: 'online_tests', // 我的测评
    component: MyLoadable({
      loader: () => import('containers/student/tests')
    })
  },
  {
    path: 'tests', // 我的测评
    component: MyLoadable({
      loader: () => import('containers/student/tests')
    })
  },
  {
    path: 'questions', // 我的提问
    component: MyLoadable({
      loader: () => import('containers/student/question_list')
    })
  },
  {
    path: 'publicClass', // 公开课
    component: MyLoadable({
      loader: () => import('containers/student/public_class')
    })
  },
  {
    path: 'notes/:filter?', // 我的笔记
    component: MyLoadable({
      loader: () => import('containers/student/note_list')
    })
  },
  {
    path: 'documents/:filter?', // 我的资料
    component: MyLoadable({
      loader: () => import('containers/student/documents')
    })
  },
  {
    path: 'knowledge_repository', // 知识库
    component: MyLoadable({
      loader: () => import('containers/student/knowledge_repository')
    })
  },
  {
    path: 'collections/:filter?', // 我的收藏
    component: MyLoadable({
      loader: () => import('containers/student/collections')
    })
  }
]

export default class StudentRoutes extends React.Component<any, any> {
  state = {
    questionaireInfo: {} as any,
    ignoreQuestionaireDate: '',
    dateReady: false,
    questionaireModalVisible: false
  }

  async componentDidMount () {
    const isVipStudent = utils.getStudentServeType() === 4
    if (isVipStudent) {
      await this.fetchUnSubmitQuestionaire()
      await this.fetchIgnoreQuestionaireDate()
      socket.on('SMART_TOAST', async questionaireInfo => {
        this.setState({
          questionaireModalVisible: true,
          questionaireInfo: { ...questionaireInfo, id: questionaireInfo.qualityTestId, from: 'socket' }
        })
      })
      socket.on('QUESTIONAIRE_SUBMITTED', async res => {
        this.setState({
          questionaireInfo: {}
        })
      })
    }
  }

  componentWillUnmount () {
    const isVipStudent = utils.getStudentServeType() === 4
    if (isVipStudent) {
      socket.off('SMART_TOAST')
      socket.off('QUESTIONAIRE_SUBMITTED')
    }
  }

  fetchUnSubmitQuestionaire = async () => {
    const res = await rest.get('/api/quality-test/unsubmit')
    if (res.code === 0) {
      this.setState({
        questionaireInfo: res.data,
        dateReady: true
      })
    } else {
      this.setState({
        dateReady: true
      })
      message.error({
        text: '获取未提交质检问卷失败',
        msg: res.msg
      })
    }
  }

  fetchIgnoreQuestionaireDate = async () => {
    const key = `ignoreQuestionaireDate-${utils.getUserId()}`
    const res = await rest.get(`/api/common-config/key/${key}?business=smart-questionaire`)
    if (res.code === 0) {
      const date = _.get(res, ['data', 'value']) || ''
      const isTodayIgnored = date === moment(new Date()).format('YYYY-MM-DD')
      this.setState({
        questionaireModalVisible: !isTodayIgnored,
        ignoreQuestionaireDate: date
      })
    }
  }

  handleGoToQuestionaire = isModal => {
    const { id } = this.state.questionaireInfo
    isModal && this.submitIgnoreQuestionaireModal()
    this.setState({
      questionaireModalVisible: false
    }, () => window.open(`/questionaire?id=${id}`))
  }

  handleHideQuestionaireModal = () => {
    this.setState({
      questionaireModalVisible: false
    }, this.submitIgnoreQuestionaireModal)
  }

  submitIgnoreQuestionaireModal = async () => {
    const data = {
      value: moment(new Date()).format('YYYY-MM-DD'),
      business: 'smart-questionaire'
    }
    const key = `ignoreQuestionaireDate-${utils.getUserId()}`
    const url = utils.genUrl(data, `/api/common-config/key/${key}`)
    const res = await rest.put(url, data)
    if (res.code !== 0) {
      message.error({
        text: '存储失败',
        msg: res.msg
      })
    }
  }

  renderQuestionaireModal = () => {
    const { questionaireInfo, ignoreQuestionaireDate, dateReady, questionaireModalVisible } = this.state
    const today = moment(new Date()).format('YYYY-MM-DD')
    if (dateReady && questionaireModalVisible && !_.isEmpty(questionaireInfo) && (questionaireInfo.from === 'socket' || today !== ignoreQuestionaireDate)) {
      return (<div className='questionaire-modal-container'>
        <div className='mask' onClick={this.handleHideQuestionaireModal} />
        <div className='questionaire-modal'>
          <span onClick={() => this.handleGoToQuestionaire(true)} />
        </div>
      </div>)
    }
  }

  renderQuestionaireSideLink = () => {
    const { questionaireInfo, questionaireModalVisible } = this.state
    if (!_.isEmpty(questionaireInfo) && !questionaireModalVisible) {
      return (
        <div
          onClick={this.handleGoToQuestionaire}
          className='questionaire-link'
        />
      )
    }
  }

  render () {
    const { match } = this.props
    // path中的可选参数不再是(/:xxx)形式，而是直接在参数后加一个问号
    return <div className='cont'>
      <Switch>
        {routes.map(route => {
          return (
            <Route
              exact={route.isExact}
              key={`${match.path}/${route.path}`}
              path={`${match.path}/${route.path}`}
              render={
                props => (
                  <ErrorBoundary>
                    <route.component
                      {...props}
                      params={props.match.params}
                    />
                  </ErrorBoundary>
                )
              }
            />
          )
        })
        }
      </Switch>
      {this.renderQuestionaireModal()}
      {this.renderQuestionaireSideLink()}
    </div>
  }
}
