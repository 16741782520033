import constants from 'common/constants'

export function trackSensor (sensorName, data) {
  if (global.sa) {
    global.sa.track(sensorName, data)
  }
}

export function trackStudyTaskSensor (item) {
  // 统一处理线下/在线学生端学员开始一个status为1的任务的时候，要发送一个神策事件
  const exerciseType = constants.TASK_TYPES_CHN[item.type || 'unknown']
  const data = {
    page_title: item.name,
    page_url: location.href,
    exercise_id: item.id,
    exercise_type: exerciseType
  }
  trackSensor('start_study_task', data)
}

export function trackVideoSensor (props, type, endTime?) {
  let sensorName = ''
  const data: any = {
    page_title: props.slice.name || '',
    page_url: location.href,
    slice_id: +props.slice.id,
    slice_name: props.slice.name || '',
    url: props.organization.name,
    slice_length: props.slice.duration || 0,
    is_already_finished: props.slice.complete || false
  }
  if (type === 'open') {
    data.start_time = props.slice.time || 0
    sensorName = 'start_watch_course'
  } else {
    // 通过播放器获取到的时间是小数，向下取整一下
    data.end_time = Math.floor(endTime) || 0
    sensorName = 'finish_watch_course'
  }
  trackSensor(sensorName, data)
}
