import * as utils from 'common/utils'

import * as rest from 'common/rest'

// 自动获取
export function fetch () {
  return async (dispatch) => {
    dispatch({
      type: 'USER.FETCHING',
      completed: false
    })

    const userInfo = {
      organizationId: utils.getCookie('organizationId'),
      permissionModules: utils.getLocalStorage('permissionModules'),
      permissionOperations: utils.getLocalStorage('permissionOperations'),
      visibleOrganizationIds: utils.getLocalStorage('visibleOrganizations')
    }

    dispatch({
      type: 'USER.FETCHING',
      completed: true,
      data: userInfo
    })
  }
}

export function clear () {
  return {
    type: 'USER.CLEAR'
  }
}

export const signInOrSignUpBySsUser = ssUser => async dispatch => {
  if (global.IS_INSIDE) return
  const res = await rest.post('/api/user/ss-user', ssUser)
  if (res.code) return
  dispatch({
    type: 'USER.FETCHING',
    completed: true,
    data: { organizationId: utils.getOrganizationId() }
  })
}
