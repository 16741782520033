import * as React from 'react'
const createReactClass = require('create-react-class')
// 组件
const Loading = createReactClass({
  // 渲染
  render () {
    return (
      <div className='common-loading'>
        <div className='bounce1' />
        <div className='bounce2' />
        <div className='bounce3' />
        <div className='bounce4' />
      </div>
    )
  }
})

export default Loading
