
// 模块以及操作
// 模块名、操作名不能包含“-”

// orgType: 说明可使用该模块的组织类型，枚举值数组 （1：学习中心，2: 在线VIP, 3： 总部；）
// inside: if inside: true, 意思是此模块属于 inside 独有模块，在C线配置角色权限时不会显示
// 对应组织类型下的管理员可访问组织可访问的所有模块及操作
const permisions = {
  role: {
    name: '身份选择',
    orgType: [1, 2],
    operation: {
      isXiaozhang: '校长',
      isPT: '服务PT',
      isPTM: 'isPTM',
      isJXPT: '教学PT',
      isJXPTM: '教学PTM'
    }
  },
  dashboard: {
    name: 'dashboard',
    orgType: [1, 2],
    operation: {
      topArea: '顶部区',
      classOverView: '班课概要区',
      classTrendArea: '班课趋势区',
      studyTask: '学习任务统计',
      rank: '排名统计'
    }
  },
  myHomepage: {
    name: '我的主页',
    orgType: [1, 2],
    operation: {
      courses: '我的学员-面授课表',
      ptDailies: '我的学员-PT日报',
      studentSign: '我的学员-学员签到',
      schedule: '我的课表',
      messages: '我的通知'
    }
  },

  // 学员管理
  myStudents: {
    name: '我的学员',
    orgType: [1],
    operation: {}
  },
  // studentList: {
  //   name: '学员列表',
  //   orgType: [2],
  //   operation: {
  //     view: '查看',
  //     refuse: '驳回',
  //     manage: '管理',
  //     complete: '结课',
  //     cancelComplete: '取消结课'
  //   }
  // },
  studentTimeOnline: {
    name: '学员时间管理',
    orgType: [2],
    operation: {
      update: '编辑'
    }
  },

  evaluation: {
    name: '测评管理',
    orgType: [1, 2, 3],
    operation: {
      invitation: '邀请测评',
      reportAdd: '添加人工报告',
      delete: '删除'
    }
  },

  studyTask: {
    name: '学习任务',
    orgType: [2],
    operation: {
      edit: '编辑'
    }
  },
  teachingPlans: {
    name: '教学计划',
    orgType: [1, 3],
    operation: {
      create: '创建教学计划',
      view: '查看',
      edit: '编辑',
      export: '导出',
      delete: '删除',
      saveTemplate: '保存模板'
    }
  },
  studyHistory: {
    name: '学习历史',
    orgType: [2, 3]
  },
  weekly: {
    name: '学习周报',
    orgType: [2, 3]
  },

  // 转案管理
  transfer: {
    name: '转案管理',
    orgType: [1, 2, 3],
    operation: {
      view: '查看',
      assignServicePT: '分配服务PT',
      assignTeachingPT: '分配教学PT',
      refuse: '驳回',
      complete: '结课',
      cancelComplete: '取消结课'
    }
  },
  pigaiAuthorizationManage: {
    name: '批改权限管理',
    orgType: [1],
    operation: {
      update: '编辑'
    }
  },
  studentList: {
    name: '学员列表',
    orgType: [1, 2, 3],
    operation: {
      freeze: '冻结/解冻',
      add: '添加学员',
      downloadTemplate: '下载导入模板',
      batchedImport: '批量导入'
    }
  },
  studentArchives: {
    name: '学员档案',
    orgType: [1, 2, 3],
    operation: {
      updateBaseInfo: '编辑学员基础信息',
      updatePhone: '编辑学员手机号',
      resetPassword: '重置密码',
      updateCourseProductInfo: '编辑课程商品信息',
      viewBeikaoInfo: '查看备考信息',
      updateBeikaoInfo: '编辑备考信息',
      submitBeikaoInfo: '提交成绩',
      deleteBeikaoInfo: '删除备考信息',
      updateArrangeInfo: '编辑学排课说明',
      updateServicePT: '编辑分配服务PT',
      updateCoursePT: '编辑分配教学PT',
      updateTime: '编辑学员可用时间',
      updateCoursePTAllTime: '编辑分配教学PT总课时(总部层)',
      addProduct: '添加商品'
    }
  },
  studentScore: {
    name: '学员成绩',
    orgType: [1, 2, 3],
    operation: {
      view: '查看',
      edit: '编辑',
      filed: '归档',
      cancel: '作废',
      submit: '提交'
    }
  },
  studentTime: {
    name: '学员时间管理',
    orgType: [1, 3],
    operation: {
      update: '编辑'
    }
  },

  // 课表管理
  teachingReq: {
    name: '面授申请',
    orgType: [1, 2],
    operation: {
      view: '查看',
      create: '添加',
      arrange: '排课'
    }
  },
  changingReq: {
    name: '调课申请',
    orgType: [1, 2],
    operation: {
      view: '查看',
      create: '添加',
      receive: '接收',
      refuse: '驳回',
      arrange: '调课'
    }
  },
  trialCourseRequest: {
    name: '试听课申请',
    orgType: [1, 2],
    operation: {
      add: '添加',
      view: '查看',
      handle: '处理',
      cancel: '作废'
    }
  },
  newGroupCourseManage: {
    name: '班课管理',
    orgType: [1, 2],
    operation: {
      apply: '报名'
    }
  },
  banke: {
    name: '班课管理－新',
    orgType: [1, 2],
    operation: {
      arrangeServicePt: '分配服务PT',
      arrangeTeachPt: '分配教学PT',
      detailReadOnly: '查看班级',
      disband: '解散班级',
      editBaseInfo: '管理班级-编辑基础信息',
      editServicePt: '管理班级-编辑服务PT',
      editTeachPt: '管理班级-编辑教学PT',
      editStudyTask: '管理班级-编辑学习任务',
      editDaily: '管理班级-编辑日报',
      editWeekly: '管理班级-编辑周报',
      add: '添加班课',
      addStudent: '添加学员',
      dropCourse: '退班/恢复',
      editSchedule: '编辑课表',
      submitSchedule: '提交',
      cancelEditSchedule: '退出编辑',
      clearSchedule: '清空课表'
    }
  },
  dayTask: {
    name: '学习任务',
    orgType: [1, 3],
    operation: {
      edit: '编辑'
    }
  },
  ptDaily: {
    name: '各中心PT日报',
    orgType: [1, 2],
    operation: {
      // ...
    }
  },
  AITestPaperGenerator: {
    name: 'AI组卷',
    orgType: [1, 2],
    operation: {
      // ...
    }
  },
  studentSchedule: {
    name: '学员课表',
    orgType: [1, 2],
    operation: {
      export: '导出课表'
    }
  },
  teacherSchedule: {
    name: '教师课表',
    orgType: [1, 2],
    operation: {
      export: '导出课表',
      update: '编辑课表',
      reset: '重置状态'
    }
  },
  // 教学管理
  teacher: {
    name: '教师管理',
    orgType: [1, 2, 3],
    operation: {
      view: '查看',
      create: '添加',
      update: '编辑',
      delete: '删除'
    }
  },
  performance: {
    name: '教师绩效系数',
    orgType: [3]
    // operation: {
    //   view: '查看',
    //   create: '添加',
    //   update: '编辑',
    //   delete: '删除'
    // }
  },
  teacherTime: {
    name: '教师时间管理',
    orgType: [1, 2, 3],
    operation: {
      update: '编辑'
    }
  },
  template: {
    name: '模板管理',
    orgType: [1, 3],
    operation: {
      create: '创建模板',
      view: '查看',
      update: '编辑',
      delete: '删除'
    }
  },
  document: {
    name: '资料管理',
    orgType: [1, 2, 3],
    operation: {
      distribute: '分发',
      upload: '上传',
      download: '下载',
      delete: '删除'
    }
  },

  question: {
    name: '答疑管理',
    orgType: [1, 2, 3],
    operation: {
      view: '查看',
      answer: '答复'
    }
  },
  comment: {
    name: '评价管理',
    orgType: [1, 3],
    operation: {}
  },
  // 学校管理
  structure: {
    name: '组织管理',
    orgType: [1, 2, 3],
    operation: {
      create: '添加',
      update: '编辑',
      delete: '删除'
    }
  },
  staff: {
    name: '员工管理',
    orgType: [1, 2, 3],
    operation: {
      view: '查看',
      create: '添加',
      update: '编辑',
      freeze: '冻结',
      phoneEdit: '员工手机号编辑',
      roleEdit: '角色编辑',
      resetPassword: '重置密码'
    }
  },
  classroom: {
    name: '教室管理',
    orgType: [1, 2, 3],
    operation: {
      view: '查看',
      create: '添加',
      update: '编辑',
      freeze: '冻结'
    }
  }, // 添加、查看、编辑、冻结、解冻
  product: {
    name: '商品管理',
    inside: true,
    orgType: [1],
    operation: {
      add: '添加',
      view: '查看',
      edit: '编辑',
      freeze: '冻结/解冻'
    }
  },
  // 直播间管理
  studio: {
    name: '直播间管理',
    orgType: [2],
    operation: {
      create: '添加',
      // update: '编辑',
      freeze: '冻结/解冻'
    }
  },
  // 教务管理
  arrangement: {
    name: '待排课程',
    orgType: [3],
    operation: {
      view: '查看',
      receive: '接收',
      refuse: '驳回',
      arrange: '排课'
    }
  },
  changing: {
    name: '待调课程(总部)',
    orgType: [3],
    operation: {
      view: '查看',
      receive: '接收',
      refuse: '驳回',
      arrange: '调课'
    }
  },
  newGroupCourseManageAdmin: {
    name: '班课管理',
    orgType: [3],
    operation: {
      create: '添加',
      delete: '删除'
    }
  },
  bankeNew: {
    name: '班课管理－新',
    orgType: [3],
    operation: {
      view: '查看班级详情',
      create: '生成课表',
      edit: '编辑课表',
      disband: '解散班级'
    }
  },
  trialCourseManage: {
    name: '试听课管理（总部层）',
    orgType: [3],
    operation: {}
  },
  exchangeCourseManage: {
    name: '退换课管理',
    orgType: [3],
    operation: {
      view: '查看',
      pass: '通过',
      reject: '驳回'
    }
  },
  allStudentSchedule: {
    name: '学员课表',
    orgType: [3],
    operation: {
      export: '导出课表'
    }
  },
  allTeacherSchedule: {
    name: '教师课表',
    orgType: [3],
    operation: {
      export: '导出课表'
    }
  },
  teacherStatSchool: {
    name: '面授管理-教师饱和度',
    orgType: [1, 2],
    operation: {
      viewSchedule: '查看课表'
    }
  },
  teacherStat: {
    name: '教师饱和度',
    orgType: [3],
    operation: {
      viewSchedule: '查看课表'
    }
  },
  classroomStatSchool: {
    name: '面授管理-教室饱和度',
    orgType: [1, 2],
    operation: {}
  },
  classroomStat: {
    name: '教室饱和度',
    orgType: [3],
    operation: {}
  },
  qualityManage: {
    name: '质检管理',
    orgType: [3],
    operation: {
      resend: '重新推送（总部层）'
    }
  },
  ptDailyAdmin: {
    name: 'PT日报',
    orgType: [3],
    operation: {
      // ...
    }
  },
  classroomSchedule: {
    name: '教务管理-教室使用情况',
    orgType: [3],
    operation: {
      export: '导出课表'
    }
  },
  classroomScheduleSchool: {
    name: '面授管理-教室使用情况',
    orgType: [1, 2],
    operation: {
      export: '导出课表'
    }
  },
  studentCourseHours: {
    name: '学员课时统计',
    orgType: [1, 2, 3],
    operation: {}
  },
  teacherCourseHours: {
    name: '教师课时统计',
    orgType: [1, 2, 3],
    operation: {}
  },
  onlineTeacherCourseHours: {
    name: '教师课时统计',
    orgType: [2, 3],
    operation: {}
  },
  teacherReward: {
    name: '教师绩效',
    orgType: [1, 2, 3],
    operation: {
      add: '添加'
    }
  },
  expendedTeacherCourseHours: {
    name: '教师课消',
    orgType: [1, 2, 3],
    operation: {}
  },
  studentSign: {
    name: '学员签到',
    orgType: [1, 3],
    operation: {
      add: '签到/签退',
      export: '导出excel',
      clear: '清除'
    }
  },
  mokaoStatistics: {
    name: '测评统计',
    orgType: [1, 2, 3],
    operation: {}
  },
  studyTaskStatistics: {
    name: '学习任务统计',
    orgType: [1, 2, 3],
    operation: {}
  },
  myMessages: {
    name: '我的通知',
    orgType: [1, 2, 3]
  }

}

export default permisions
