import * as React from 'react'
import { Popover } from 'antd'

const Ellipsis = (maxWidth, text) => (
  <div className='common-ellipsis' style={{ maxWidth }} >
    <Popover content={text}>
      {text}
    </Popover>
    {/* {
      isFree && showFreeLogo ? <span className={`free-logo ${isActive ? 'red' : ''}`}/> : ''
    } */}
  </div>
)

export default Ellipsis
