import * as React from 'react'

export default class MobileModal extends React.Component<any, any> {
  render () {
    return (
      <div className='mobile-modal-mask'>
        <div className='mobile-modal'>
          <div className='mobile-modal-content'>亲爱的同学，为了获得更好的体验，请在电脑上使用</div>
          <div className='mobile-modal-button' onClick={this.props.handleOk}>确认</div>
        </div>
      </div>
    )
  }
}
