import * as _ from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import { Cascader } from 'antd'
import { withRouter } from 'react-router-dom'

class SubjectPicker extends React.Component<any, any> {
  onChange = values => {
    this.props.onChange && this.props.onChange(values[1])
  }

  render () {
    const options = this.getOptions()
    const { subjectMap, value } = this.props
    const subjectId = value
    const examinationId = _.get(subjectMap, [subjectId, 'examinationId'])
    return (
      <div className='subject-picker'>
        <Cascader
          options={options}
          onChange={this.onChange}
          placeholder='选择科目'
          value={[examinationId, subjectId]}
        />
      </div>
    )
  }

  getOptions = () => {
    const { examinations, subjects } = this.props
    const examId2Subjects = _.groupBy(subjects, 'examinationId')
    return examinations.filter(el => el.visible).map(exam => ({
      label: exam.name,
      value: exam.id,
      children: (examId2Subjects[exam.id] || []).filter(el => el.visible).map(subject => ({
        label: subject.name,
        value: subject.id
      }))
    }))
  }
}

export default connect(state => ({
  examinations: state.config.exams.list.filter(i => i.visible == 1) || [],
  subjects: state.config.subjects.list || [],
  subjectMap: (state.config.subjects.list || []).reduce((res, item) => ({ ...res, [item.id]: item }), {})
}))(withRouter(SubjectPicker))
