/**
 * mount和update公共函数
 */

export default {
  componentDidMount () {
    this.componentDidMountOrUpdate()
  },

  componentDidUpdate () {
    this.componentDidMountOrUpdate()
  }
}
