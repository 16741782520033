import timeManager from '../timeManager'

const ONE_DAY = timeManager.ONE_DAY

const SCOPES = timeManager.SCOPES

const STUDENT_SCOPES_KVS = {
  上午8点: [8 * 60, 9 * 60],
  上午9点: [9 * 60, 10 * 60],
  上午10点: [10 * 60, 11 * 60],
  上午11点: [11 * 60, 12 * 60],
  中午12点: [12 * 60, 13 * 60],
  下午1点: [13 * 60, 14 * 60],
  下午2点: [14 * 60, 15 * 60],
  下午3点: [15 * 60, 16 * 60],
  下午4点: [16 * 60, 17 * 60],
  下午5点: [17 * 60, 18 * 60],
  下午6点: [18 * 60, 19 * 60],
  晚上7点: [19 * 60, 20 * 60],
  晚上8点: [20 * 60, 21 * 60],
  晚上9点: [21 * 60, 22 * 60],
  晚上10点: [22 * 60, 23 * 60],
  晚上11点: [23 * 60, 24 * 60]
}

const STUDENT_SCOPES = []

for (const key in STUDENT_SCOPES_KVS) {
  const scope = {
    label: key,
    slots: [STUDENT_SCOPES_KVS[key]]
  }
  STUDENT_SCOPES.push(scope)
}

// 获取某个时间点，距离当天0时0分0秒，相差多少分钟
function getDiffMintues (timstamp) {
  const date = new Date(timstamp)
  return date.getHours() * 60 + date.getMinutes()
}

function isCoverScope (scope1, scope2) {
  if ((scope1[0] <= scope2[0]) && (scope1[1] >= scope2[1])) {
    return true
  }
  return false
}

function isOccupyScope (scope1, scope2) {
  if ((scope1[0] > scope2[0]) && (scope1[0] < scope2[1])) {
    return true
  }
  if ((scope1[1] > scope2[0]) && (scope1[1] < scope2[1])) {
    return true
  }
  if ((scope1[0] <= scope2[0]) && (scope1[1] >= scope2[1])) {
    return true
  }
  return false
}

const getScopeKeys = (scopes, isCover, slotScopes, userType, dayIndex) => {
  const result = []

  let tmpSCOPES
  switch (userType) {
    case 'teacher':
    case 'student_online':
      tmpSCOPES = slotScopes || SCOPES
      break

    case 'student':
      tmpSCOPES = STUDENT_SCOPES
      break
  }
  const keys = tmpSCOPES.map(scope => scope.label)
  for (const scope of scopes) {
    for (const key of keys) {
      const slotScope = tmpSCOPES.find(scope => scope.label === key).slots[dayIndex]
      if (isCover && isCoverScope(scope, slotScope)) {
        result.push(key)
      } else if (isOccupyScope(scope, slotScope)) {
        result.push(key)
      }
    }
  }
  return result
}

const getDayScopes = (startTime, endTime) => {
  const startZeroTime = (new Date(startTime)).setHours(0, 0, 0, 0)
  const endZeroTime = (new Date(endTime)).setHours(0, 0, 0, 0)

  // 计算结束时间和开始时间相隔的天数
  const diffDays = Math.floor((endZeroTime - startZeroTime) / ONE_DAY)
  const dayScopes = []
  if (diffDays === 0) {
    dayScopes.push({
      date: startZeroTime,
      scopes: [[getDiffMintues(startTime), getDiffMintues(endTime)]]
    })
    return dayScopes
  }

  // 开始时间到当天晚上24点
  dayScopes.push({
    date: startZeroTime,
    scopes: [[getDiffMintues(startTime), 24 * 60]]
  })

  // 结束时间当天0点到结束时间点
  dayScopes.push({
    date: endZeroTime,
    scopes: [[0, getDiffMintues(endTime)]]
  })

  // 开始时间与结束时间中间的每一天
  for (let i = 1; i < diffDays; i++) {
    const date = startZeroTime + ONE_DAY * i
    dayScopes.push({
      date,
      scopes: [[0, 24 * 60]]
    })
  }

  return dayScopes
}

const getScopes = (startTime, endTime, isCover, slotScopes, userType = 'teacher') => {
  const dayTimeSlots = []
  const dayTimeScopes = getDayScopes(startTime, endTime)
  for (const dayTimeScope of dayTimeScopes) {
    const dayIndex = userType === 'student' ? 0 : new Date(startTime).getDay()
    const keys = getScopeKeys(dayTimeScope.scopes, isCover, slotScopes, userType, dayIndex)
    dayTimeSlots.push({
      date: dayTimeScope.date,
      scopeKeys: keys
    })
  }
  return dayTimeSlots
}

// 这个方法是把新的slotsData转换成之前SCOPE的某一天的结构，也就是某一列数据。convert是说是否将周日提到最前面
function convertSlotsDataToSundayFirst (slotsData) {
  for (let i = 0; i < slotsData.length; i++) {
    const sundaySlot = slotsData[i].slots.pop()
    slotsData[i].slots.unshift(sundaySlot)
  }
  return slotsData
}

function getWeekdayScopes (data, index, convert) {
  let _data = data.concat()
  let slotsData = convert ? convertSlotsDataToSundayFirst(_data) : _data
  for (let i = 0; i < slotsData.length; i++) {
    slotsData[i].slots = slotsData[i].slots[index || 0]
  }
  return slotsData
}

export default {
  ONE_DAY,
  SCOPES,
  STUDENT_SCOPES,
  getScopes,
  getWeekdayScopes,
  convertSlotsDataToSundayFirst
}
