import * as utils from 'common/utils'
import { dispatch } from '../common/redux'
import constants from 'common/constants'
import * as messageHelper from 'helpers/message'
import * as staffHelper from 'helpers/staff'
import socket from '../common/socket'

/**
 * 刷新员工的权限信息
 * @return {void}
 */
async function refreshStaffPermission () {
  const userTypeId = utils.getUserTypeId()
  if (userTypeId === constants.USER_TYPE.TEACHER) {
    const staff = await staffHelper.getStaffWithPermission()
    const newPermissionOperations = staff.permissionOperations.length
    const oldPermissionOperations = utils.getLocalStorage('permissionOperations') ? utils.getLocalStorage('permissionOperations').length : 0
    const newPermissionModules = staff.permissionModules.length
    const oldPermissionModules = utils.getLocalStorage('permissionModules') ? utils.getLocalStorage('permissionModules').length : 0
    const oldVisibleOrganizations = utils.getLocalStorage('visibleOrganizations') ? utils.getLocalStorage('visibleOrganizations').join() : ''
    const newVisibleOrganizations = staff.visibleOrganizations.join()
    const oldCrossTeacherVisible = utils.getLocalStorage('crossTeacherVisible') ? utils.getLocalStorage('crossTeacherVisible') : ''
    const oldIsTeacherSupervisor = utils.getLocalStorage('isTeacherSupervisor') ? utils.getLocalStorage('isTeacherSupervisor') : ''
    if (newPermissionOperations != oldPermissionOperations || newPermissionModules != oldPermissionModules) {
      utils.setLocalStorage('permissionOperations', staff.permissionOperations)
      utils.setLocalStorage('permissionModules', staff.permissionModules)
      dispatch({
        type: 'PERMISSION.FETCH',
        permissionModule: staff.permissionModules
      })
    }
    if (newVisibleOrganizations != oldVisibleOrganizations) {
      utils.setLocalStorage('visibleOrganizations', staff.visibleOrganizations)
    }
    if (staff.crossTeacherVisible != oldCrossTeacherVisible) {
      utils.setLocalStorage('crossTeacherVisible', staff.crossTeacherVisible)
    }
    if (staff.isTeacherSupervisor != oldIsTeacherSupervisor) {
      utils.setLocalStorage('isTeacherSupervisor', staff.isTeacherSupervisor)
    }
  }
}

/**
 * 退出登录
 * @return {void}
 */
function logout () {
  utils.logout && utils.logout()
}

/**
 * 刷新未读消息数量
 * @return {void}
 */
async function refreshMessageCount () {
  const userTypeId = utils.getUserTypeId()
  if (userTypeId === constants.USER_TYPE.TEACHER) {
    const count = await messageHelper.getUnreadMessageCount()
    dispatch({
      type: 'MESSAGE.UNREAD',
      count
    })
  }
}

socket.on('ORGANIZATION_EXPIRE', logout)
socket.on('ORGANIZATION_FREEZE', logout)
socket.on('PASSWORD_CHANGE', logout)
socket.on('PERMISSION_CHANGE', refreshStaffPermission)
socket.on('SERVICE_FINISHED', logout)
socket.on('STAFF_FREEZE', logout)
socket.on('MESSAGE_COUNT_CHANGE', refreshMessageCount)
