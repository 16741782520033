import React from 'react'
import {message, Select} from 'antd'

const {Option} = Select

class SelectForRole extends React.Component<any, any> {
  constructor (props) {
    super(props)
    this.state = {
      searchList: []
    }
  }

  searchResult = (value) => {
    const {options} = this.props
    let resultList = []
    Object.keys(options).forEach((key) => {
      if (options[key].includes(value))
        resultList.push({key: key, title: options[key]})
    })
    this.setState({searchList: resultList})
  }

  handleSelect = (value) => {
    const {addSearchValue} = this.props
    addSearchValue(value)
    this.searchResult('')
  }

  handleDeselect = (value) => {
    const {removeSearchValue} = this.props
    removeSearchValue(value)
    this.searchResult('')
  }

  componentDidMount (): void {
    const {options} = this.props
    this.setState({
      searchList: Object.keys(options).map((key) => { return {key: key, title: options[key]}})
    })
  }

  render () {
    const {searchList} = this.state
    return (
      <Select
        showSearch
        mode="multiple"
        style={{width: '100%'}}
        filterOption={false}
        onSearch={this.searchResult}
        onSelect={this.handleSelect}
        onDeselect={this.handleDeselect}
      >
        {searchList.map((item) => {
          return (
            <Option key={item.key} title={item.title} value={item.key}>{item.title}</Option>
          )
        })}
      </Select>
    )
  }
}

export default SelectForRole
