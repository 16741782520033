import * as React from 'react'

import { Button } from 'antd'
import CommonModal from './common_modal'

// 容器
export class VacationModal extends React.Component<any, any> {
  // 渲染
  render () {
    const { isAbsencing, visible, handleOk, handleCancel } = this.props
    return (
      <CommonModal
        title={isAbsencing ? '销假' : '请假'}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        header={isAbsencing ? '销假后可以继续学习，课程有效期将恢复计算' : '请假状态中，您将无法观看课程，提交批改或观看逐题精讲'}
        content={isAbsencing ? '' : '请假后，您的课程有效期计算将暂停。每年最多请假3次，累计90天，超过时间将自动销假'}
        okText={isAbsencing ? '立即销假' : '确定'}
        footer={[
          <Button key='back' size='large' onClick={handleCancel}>取消</Button>,
          <Button key='submit' type='primary' size='large' onClick={handleOk}>
            {isAbsencing ? '立即销假' : '确定'}
          </Button>
        ]}
      />
    )
  }
}

export default VacationModal
