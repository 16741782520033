import * as utils from 'common/utils'

// 数组去重
export function avoidRepeat (arr) {
  return arr.reduce((pre, next) => pre.includes(Number(next)) ? pre : pre.concat(Number(next)), [])
}

// 存储用户的select缓存
export function setSelectCache (key, cache) {
  let selectCache = utils.getLocalStorage('select_cache') || {}
  selectCache[key] = cache
  utils.setLocalStorage('select_cache', selectCache)
}

// 获取用户select缓存
export function getSelectCache (key) {
  let selectCache = utils.getLocalStorage('select_cache') || {}
  if (selectCache.hasOwnProperty(key)) {
    return selectCache[key]
  }
  return []
}

/**
 * 最多只缓存用户所选择的前十项
 * @param oldValues 缓存中的旧值
 * @param length 所读取的缓存的长度
 */
export function getLatestSelectData (oldValues = [], length = 10) {
  let newValues = avoidRepeat(oldValues)
  if (newValues.length > length) {
    return newValues.slice(0, length)
  }
  return newValues
}

/**
 * 平台的缓存
 * @param cache 用户点击缓存
 * @param pool 数据源池子
 * @return 缓存的详情数据 去除缓存数据的数据源
 */
export function getPlatformData (cache = [], pool = []) {
  let tempPool = pool.slice()
  let tempCache = cache.slice()
  let usefulCache = cache.reduce((pre, next, cIndex) => {
    let index = tempPool.findIndex(item => next == item)
    if (index > -1) {
      let detailInfo = tempPool[index]
      tempPool.splice(index, 1)
      return pre.concat(detailInfo)
    }
    tempCache.splice(cIndex, 1)
    return pre
  }, [])
  return {
    usefulCache,
    otherTemp: tempPool
  }
}

/**
 * 老师和学生选择器组件
 * 获取缓存的详情数据 和去除缓存数据的原始数据
 * @param cache localStorage 缓存
 * @param pool 数据源池子 缓存数据也包括在内
 * @return 缓存的详情数据 去除缓存数据的数据源
 */
export function getEducationData (cache = [], pool = []) {
  let otherTemp = pool.slice()
  const usefulCache = otherTemp.filter(el => cache.includes(el.id))
  otherTemp = otherTemp.filter(el => !cache.includes(el.id))
  return { usefulCache, otherTemp }
}

export function getUsefulData (cache = [], pool = [], key) {
  if (key === 'platform') {
    return getPlatformData(cache, pool)
  }
  return getEducationData(cache, pool)
}

/**
 * 往缓存中存储用户所选择的最新的选择的项，并去重
 * @param key 对应于localStorage缓存中的key
 * @param selectData 用户所点击的项
 * @param length 所读取的缓存的长度
 */
export function setLatestSelectData (key, selectData, length = 10) {
  let currentCache = getSelectCache(key)
  currentCache.unshift(selectData)
  let newCache = getLatestSelectData(currentCache, length)
  setSelectCache(key, newCache)
}

/**
 * 合并缓存和数据源中的数据，将缓存数据放置在队列前面
 * @param key localstorage中的key
 * @param dataSource 所有的数据源
 */
export function getOrderData (key, dataSource) {
  let currentCache = getSelectCache(key)
  let { usefulCache, otherTemp } = getUsefulData(currentCache, dataSource, key)
  otherTemp.unshift(...usefulCache)
  return otherTemp
}

/**
 * 切换页签时，获取缓存中的最新id。
 * @param siteId router链接中的id,不为0，则获取缓存的id。只判断0,0为不限。
 * @param useDataSource 是否使用缓存数据
 * siteId为0 代表所选中的option为不限
 */
export function getLatestOrganizationId (siteId?: string | number, useDataSource?) {
  if (siteId || siteId == 0) {
    return siteId
  }
  let latestChooseOrganization = getSelectCache('organization')[0]
  let isHeadquarter = utils.isHeadquarter()
  if (useDataSource) {
    return latestChooseOrganization || 0
  }
  if (isHeadquarter) {
    return 0
  } else {
    return utils.getOrganizationId()
  }
}

/**
 * 获取最新的
 * @param dataSource 学生下拉数据源
 * @param studentId 学生的id
 * @return 最新的缓存中的学生 没有则返回空串
 */
export function getLatestStudentId (dataSource, studentId?) {
  if (studentId) {
    return studentId
  }
  let latestChooseStudentArray = getSelectCache('student')
  let latestChooseStudent: any = ''
  latestChooseStudentArray.some(ele => {
    let hasFind = dataSource.find(item => item.id == ele)
    if (hasFind) {
      latestChooseStudent = ele
    }
    return hasFind
  })
  if (!latestChooseStudent) {
    latestChooseStudent = dataSource[0] ? dataSource[0].id : ''
  }
  return latestChooseStudent
}

/**
 * 获取最新的缓存中的老师id，没有返回 undefined
 */
export function getLatestTeacherId () {
  let latestChooseTeacherId = getSelectCache('teacher')[0]
  return latestChooseTeacherId
}
