import config from 'common/config'
import * as React from 'react'

export default class SideQuestionnaireLink extends React.Component<any, any> {
  render () {
    const { isShowNewVersion } = this.props
    return (
      <div className='side-link-container'>
        {isShowNewVersion && <a href={config.questionnaireUrl} className='link questionnaire-link' rel='noopener noreferrer' target='_blank' />}
      </div>
    )
  }
}
