import React from 'react'
import { questionScoreDescription } from 'common/questionaireConfig'
import QuestionaireInput from './questionaire-input'

const teacherKeys = ['abilities', 'atmosphere', 'timeliness']

class Question extends React.Component<any, any> {
  renderDescription = () => {
    return questionScoreDescription.map(desc =>
      <p className='teacher-description'>{desc}</p>
    )
  }

  renderTextarea = (type, text, disabled) => {
    return (
      <textarea
        onChange={event => this.handleChangeScore(event.target.value, type)}
        disabled={disabled}
      >
        {text}
      </textarea>
    )
  }

  handleChangeScore = (value, type, subjectId?: number) => {
    let data = {}
    if (['advice', 'favoriteTeacher'].includes(type)) {
      data = {
        [type]: value
      }
    } else if (subjectId) {
      data = {
        [type]: {
          [subjectId]: value
        }
      }
    } else {
      data = {
        ptScore: {
          [type]: value
        }
      }
    }
    return this.props.onChange(data)
  }

  renderInput = () => {
    const { type, teachers, score, mode, subjects } = this.props
    if (teacherKeys.includes(type)) {
      return (
        <div className='teachers-scores'>
          <p className='teacher-score-title'>
            请根据对应等级给予具体打分
          </p>
          <div className='teachers-wrapper'>
            {
              teachers.map(teacher => {
                const scoreProp = mode === 'view' ? { score: score[teacher.subjectId] || '', mode } : { mode }
                return (<p className='teacher-score-input'>
                  <span className='subject'>
                    {subjects.find(subject => subject.id === teacher.subjectId).name}
                    老师
                  </span>
                  <span className='name'>
                    {teacher.name}
                  </span>
                  <QuestionaireInput
                    onBlur={(score) => this.handleChangeScore(score, type, teacher.subjectId)}
                    {...scoreProp}
                  />
                </p>)
              })
            }
          </div>
        </div>
      )
    } else {
      const scoreProp = mode === 'view' ? { score: score || '', mode } : { mode }
      return (
        <p className='pt-score-title'>
          请根据对应等级给予具体打分
          <QuestionaireInput
            onBlur={(score) => this.handleChangeScore(score, type)}
            {...scoreProp}
          />
        </p>
      )
    }
  }

  render () {
    const { index, title, type, text, mode } = this.props
    const isTextarea = ['favoriteTeacher', 'advice'].includes(type)
    const isInput = !isTextarea
    return (
      <div className='question-item-container'>
        <div className='title'>
          <span className='index'>
            {index}
          </span>
          <p className='text'>
            {title}
            {isInput && <span className='red-star'>*</span>}
          </p>
        </div>
        <div className='description'>
          {isInput && (this.renderDescription())}
          {isInput && this.renderInput()}
          {isTextarea && this.renderTextarea(type, text, mode === 'view')}
        </div>
      </div>
    )
  }
}

export default Question
