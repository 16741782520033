import * as React from 'react'
import { Modal, Form, Input, message } from 'antd'
import Ticking from './ticking'

const FormItem = Form.Item

export default class PasswordModal extends React.Component<any, any> {
  handleSubmit () {
    const { isPhoneLogin } = this.props
    this.props.form.validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return message.error('所有字段均是必填项')
      }
      if (values.newPassword !== values.confirmPassword) {
        return message.error('两次密码不一致')
      }
      if (values.newPassword.length < 6) {
        return message.error('密码长度需要大于6位')
      }
      this.props.modifyPassword(values, isPhoneLogin)
    })
  }

  handleCloseModal () {
    this.props.closeModal()
    this.props.form.resetFields()
  }

  handleGetCaptcha () {
    this.props.getCaptcha()
  }

  render () {
    const { visible, title, ticking, isPhoneLogin, userAccount } = this.props
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    }
    return (
      <Modal
        visible={visible}
        onOk={() => this.handleSubmit()}
        onCancel={() => this.handleCloseModal()}
        title={title}
        wrapClassName='password-reset-modal'
      >
        <Form>
          <FormItem
            {...formItemLayout}
            label='原密码'
          >
            {getFieldDecorator('password', {
              rules: [{ required: true, message: '请输入原密码' }]
            })(
              <Input type='password' autoComplete='new-password' placeholder='原密码' />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label='新密码'
          >
            {getFieldDecorator('newPassword', {
              rules: [{ required: true, message: '请输入新密码' }]
            })(
              <Input type='password' autoComplete='new-password' placeholder='新密码' />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label='确认密码'
          >
            {getFieldDecorator('confirmPassword', {
              rules: [{ required: true, message: '请确认密码' }]
            })(
              <Input type='password' autoComplete='new-password' placeholder='确认密码' />
            )}
          </FormItem>
          { isPhoneLogin && !global.IS_INSIDE ? <FormItem
            {...formItemLayout}
            label='验证码'
          >
            {getFieldDecorator('captcha', {
              rules: [{ required: true, message: '请输入验证码' }]
            })(
              <Input type='text' placeholder='验证码' addonAfter={ticking ? <Ticking resendCaptcha={this.props.resendCaptcha} maxSeconds={60} /> : <span className='get-captcha' onClick={() => this.handleGetCaptcha()}>获取验证码</span>} />
            )}
          </FormItem> : null}
          { isPhoneLogin && ticking ? <p className='captcha-notice'>{`验证码已发送至${userAccount}，请注意查收`}</p> : null }
        </Form>
      </Modal>
    )
  }
}
