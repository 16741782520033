import * as React from 'react'
import { message, Icon } from 'antd'
const createReactClass = require('create-react-class')
const Modal = createReactClass({
  getInitialState () {
    return {
      opacity: 0,
      marginTop: -50 + (this.props.top || 0),
      maskOpacity: 0,
      confirmFunc () {}
    }
  },

  componentDidMount () {
    let self = this

    setTimeout(function () {
      self.setState({
        opacity: 1,
        marginTop: 0 + (self.props.top || 0),
        maskOpacity: 0.5
      })
    }, 50)
  },

  render () {
    return (
      <div className='component-common-modal'>
        <div className='wrapper'>
          <div
            className='content'
            style={{ opacity: this.state.opacity, marginTop: this.state.marginTop || 0 }}
          >
            <div className='title'>{this.props.title}<Icon onClick={this.cancel} type='close' /></div>

            {React.cloneElement(this.props.child, {
              setConfirm: this.setConfirm(),
              cancel: this.cancel
            })}

            <div
              className='buttons'
              style={{ display: this.props.hideButtons ? 'none' : 'block' }}
            >
              <span className='cancel' onClick={this.cancel}>取消</span>
              <span className='confirm' onClick={this.confirm}>
                {this.state.confirming ? '处理中' : '确认'}
              </span>
            </div>
          </div>

          <div className='mask' onClick={this.cancel} style={{ opacity: this.state.maskOpacity }} />
        </div>
      </div>
    )
  },

  cancel () {
    this.setState({
      opacity: 0,
      marginTop: -50 + (this.props.top || 0),
      maskOpacity: 0
    }, this.props.cancel)
  },

  confirm () {
    if (this.state.confirming) return

    this.setState({ confirming: true })

    this.state.confirmFunc(this.onResult())
  },

  setConfirm () {
    let self = this

    return function (func) {
      self.setState({ confirmFunc: func })
    }
  },

  onResult () {
    let self = this

    return function (err, res) {
      if (err) {
        return self.setState({ confirming: false }, () => {
          message.error(err)
        })
      }

      self.cancel()
      message.success('操作成功')

      if (self.props.onResult) {
        self.props.onResult(res)
      }
    }
  }
})

export default Modal
