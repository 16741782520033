import * as React from 'react'
import Quizzing from './basics/quizzing'
import ListItem from './basics/list_item'

class Notes extends React.Component<any, any> {
  state = {
    disable: this.props.disable
  }

  async UNSAFE_componentWillReceiveProps/* eslint-disable-line camelcase */ (nextProps) {
    this.setState({
      disable: nextProps.disable
    })
  }

  handleSubmit = async data => {
    const url = '/api/note'
    this.props.onSubmit(data, url, 'notes')
  }

  handleDelete = async (id, type) => {
    const url = '/api/note'
    this.props.onDelete(id, url, 'notes')
  }

  handleEdit = async (content, id) => {
    this.props.onEdit(content, id)
  }

  handleVacation = () => this.props.onVacation();

  render () {
    const { notes, isAbsencing } = this.props
    return (
      <div className='notes-list'>
        <Quizzing
          type='notes'
          onSubmit={this.handleSubmit}
          onPlayerPause={this.props.onPlayerPause}
          onInput={this.props.onInput}
          currentTime={this.props.currentTime}
          onVacation={this.handleVacation}
          isAbsencing={isAbsencing}
          disable={this.state.disable}
          isOnline={this.props.isOnline}
          onCheckIsAbsencing={this.props.onCheckIsAbsencing}
        />
        <div className='notes-list-outer-container'>
          <div className='notes-list-container'>
            {
              notes.map(item => <ListItem
                key={item.id}
                data={item}
                type='notes'
                onDelete={this.handleDelete}
                onEdit={this.handleEdit}
                onSetTime={this.props.onSetTime}
                isOnline={this.props.isOnline}
                onVacation={this.handleVacation}
                isAbsencing={isAbsencing}
                onCheckIsAbsencing={this.props.onCheckIsAbsencing}
              />
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Notes
