import moment from 'moment-timezone'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Icon } from 'antd'
const ONE_HOUR_SECONDS = 3600

class SetIntervalUpdate extends React.Component<any, any> {
  interval: any

  state = {
    now: new Date().getTime()
  }

  constructor (props) {
    super(props)
    this.interval = null
    try {
      clearInterval(this.interval)
    } catch (e) {}
    this.interval = setInterval(async () => {
      this.setState({
        now: new Date().getTime()
      })
    }, 1000)
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  setInterv = () => {
    const time = Object.keys(this.props.version).length > 0 ? Math.floor(moment(this.props.version.forceUpdateTime).toDate().getTime() / 1000) : 0
    const leftTime = time * 1000 - this.state.now
    const leftsecond = Math.floor(leftTime / 1000)
    if (leftsecond < 0) {
      clearInterval(this.interval)
      window.location.reload()
      return ''
    }
    const day1 = Math.floor(leftsecond / (60 * 60 * 24))
    const hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / ONE_HOUR_SECONDS)
    const minute = Math.floor((leftsecond - day1 * 24 * 60 * 60 - hour * ONE_HOUR_SECONDS) / 60)
    const second = Math.floor(leftsecond - day1 * 24 * 60 * 60 - hour * ONE_HOUR_SECONDS - minute * 60)
    if (leftsecond > 24 * 60 * 60) {
      return <span>{`${day1}天`}</span>
    } else if (leftsecond < 24 * 60 * 60 && leftsecond > ONE_HOUR_SECONDS) {
      return <span>{`${hour}小时${minute}分${second}秒`}</span>
    } else if (leftsecond < ONE_HOUR_SECONDS && leftsecond > 60) {
      return <span>{`${minute}分${second}秒`}</span>
    }
    return <span>{`${second}秒`}</span>
  }

  render () {
    // const { version } = this.props
    const time = Object.keys(this.props.version).length > 0 ? Math.floor(moment(this.props.version.forceUpdateTime).toDate().getTime() / 1000) : 0
    return (
      <div>{
        time > 0 ? <div className='edition update2' style={{ display: !this.props.toggleShow ? 'none' : '' }}>
          <div className='update-header' onClick={this.props.styleChange}>
            <span><Icon type='close' /></span>
          </div>
          <dl className='update-content'>
            <dt><span /></dt>
            <dd>
              系统已发布新版本，将在<span>{this.setInterv()}</span>后进行自动更新，届时请注意保存工作数据，避免丢失（您也可通过刷新浏览器手动更新）
            </dd>
          </dl>
        </div> : ''
      }</div>
    )
  }
}

export default connect(state => {
  return {
    version: state.version || {}
  }
})(withRouter(SetIntervalUpdate))
