const config: any = {}

// 加载应用通用配置
const appConfig = require('./config.app')
for (const key in appConfig) {
  if (appConfig.hasOwnProperty(key)) {
    config[key] = appConfig[key]
  }
}

// 加载环境配置
const envConfig = require('./config.env')
for (const key in envConfig) {
  if (envConfig.hasOwnProperty(key)) {
    config[key] = envConfig[key]
  }
}

// 加载本地自定义配置
if (process.env.NODE_ENV === 'development') {
  try {
    const newConfig = require('./config.overwrite')
    for (const key in newConfig) {
      if (newConfig.hasOwnProperty(key)) {
        config[key] = newConfig[key]
      }
    }
  } catch (error) {
    // do nothing
  }
}

export default config
