import * as React from 'react'
import TaskStudyPlan from 'components/school/task_study_plan'

export class StudyPlan extends React.Component<any, any> {
  render () {
    const { studentId, studentName } = this.props.params
    return (
      <div className='study-plan-container'>
        <TaskStudyPlan
          studentId={studentId}
          studentName={studentName}
          isStudentPlan
        />
      </div>
    )
  }
}

export default StudyPlan
