import * as utils from 'common/utils'
import { checkVersionUpdate } from '../actions/version'

/**
 * 检查接口返回值
 * @param {object} res 响应
 * @return {object} 和参数相同
 */
async function check (res) {
  const userCheckFailCode = 'APOL_2812'
  const paramsCheckFailCode = 'APOL_10'
  const jsonData = await res.json()
  if (jsonData && jsonData.code && jsonData.code === userCheckFailCode) {
    console.log('user check fail')
    setImmediate(() => utils.logout && utils.logout())
  }
  if (jsonData && jsonData.code && jsonData.code === paramsCheckFailCode) {
    console.log('params check fail')
    setImmediate(() => global.Raven.captureException(JSON.stringify(jsonData)))
  }
  const versionInfo = {
    version: res.headers.get('X-APOL-VERSION'),
    buildtime: res.headers.get('X-APOL-BUILD-TIME')
  }
  checkVersionUpdate(versionInfo)
  return jsonData
}

export const get = async (url, option: any = {}) => {
  option = Object.assign({
    credentials: 'include',
    method: 'get'
  }, option)
  const apollonToken = sessionStorage.getItem('apollon_token')
  option.headers = option.headers || {}
  if (!option.headers.Authorization && apollonToken) {
    option.headers.Authorization = `Bearer ${apollonToken}`
  }
  const res = await fetch(url, option)
  return check(res)
}

export const post = async (url, data = {}, option: any = {}) => {
  option = Object.assign({
    credentials: 'include',
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data)
  }, option)
  const res = await fetch(url, option)
  return check(res)
}

export const postFormData = async (url, data = {}, option: any = {}) => {
  var formData = new FormData()
  for (var key in data) {
    let value = data[key] === null ? '' : data[key]
    formData.append(key, value)
  }
  option = Object.assign({
    credentials: 'include',
    method: 'post',
    body: formData
  }, option)
  const res = await fetch(url, option)
  return check(res)
}

export const postFormUrlencodedData = async (url, data, option: any = {}) => {
  var formBody: any = []
  for (var key in data) {
    var encodedKey = encodeURIComponent(key)
    var encodedValue = encodeURIComponent(data[key])
    formBody.push(`${encodedKey}=${encodedValue}`)
  }
  formBody = formBody.join('&')
  option = Object.assign({
    credentials: 'include',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    body: formBody
  }, option)
  const res = await fetch(url, option)
  return check(res)
}

export const put = async (url, data = {}, option: any = {}) => {
  option = Object.assign({
    credentials: 'include',
    method: 'put',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(data)
  }, option)
  const res = await fetch(url, option)
  return check(res)
}

export const putFormData = async (url, data = {}, option: any = {}) => {
  var formData = new FormData()
  for (var key in data) {
    let value = data[key] === null ? '' : data[key]
    formData.append(key, value)
  }
  option = Object.assign({
    credentials: 'include',
    method: 'put',
    body: formData
  }, option)
  const res = await fetch(url, option)
  return check(res)
}

export const postWithFile = async (url, data, option: any = {}) => {
  option = Object.assign({
    credentials: 'include',
    method: 'post'
  }, option)
  let formData = new FormData()
  for (var key in data) {
    if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
      formData.append(key, JSON.stringify(data[key]))
    } else {
      let value = data[key] === null ? '' : data[key]
      formData.append(key, value)
    }
  }
  option.body = formData
  const res = await fetch(url, option)
  return check(res)
}

export const del = async (url, option: any = {}) => {
  option = Object.assign({
    credentials: 'include',
    method: 'delete',
    headers: { 'content-type': 'application/json' }
  }, option)
  const res = await fetch(url, option)
  return check(res)
}

export const batchGet = async (urls, num, option: any = {}) => {
  try {
    if (!num || urls.length <= num) {
      return await Promise.all(urls.map(url => get(url, option)))
    }
    const arr = []
    while (urls.length > num) arr.push(urls.splice(0, 5))
    arr.push(urls)
    let allRes = []
    for (let i = 0; i < arr.length; i++) {
      const res = await Promise.all(arr[i].map(url => get(url, option)))
      allRes = allRes.concat(res)
    }
    return allRes
  } catch (err) {
    return { err }
  }
}
