import * as utils from 'common/utils'
import * as rest from 'common/rest'
import { message } from 'antd'

/**
 * 获取可用教室列表
 * @param {number} organizationId 机构ID
 * @param {date} date 日期
 * @param {number} timeSlot 时间段
 * @param {number} usage 用途
 */
export async function getAvailableClassrooms (organizationId: number, date: Date, timeSlot: number, usage: number, capacity?: number) {
  const url = utils.genUrl({ organizationId, date, timeSlot, usage, capacity }, '/api/classrooms/enable-rooms')
  const res = await rest.get(url)
  if (res.code) return message.error({ text: '可用教室查询失败', msg: res.msg })
  return res.data
}

/**
 * 获取指定机构中教室可用时间信息
 * @param {number} organizationId 机构ID
 * @param {date} startTime 开始时间
 * @param {date} endTime 结束时间
 */
export async function getClassroomEnableTimeSlots (organizationId, startTime, endTime) {
  const url = utils.genUrl({ startTime, endTime }, `/api/classrooms/enable-time-slots/${organizationId}`)
  const res = await rest.get(url)
  if (res.code !== 0) {
    return message.error({ text: '获取教室可用时间失败', msg: res.msg })
  }
  return res.data
}
