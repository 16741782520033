import * as utils from 'common/utils'
import * as React from 'react'

export function renderAvatarByGender (genderId) {
  return <img
    src={require(!genderId ? 'images/nopic.jpg' : genderId === 1 ? 'images/boy.png' : 'images/girl.png')}
    style={{ borderRadius: '100%', width: 28, height: 28 }}
  />
}

export function getAvatar () {
  const userType = utils.getUserTypeId()
  let avatarImg = utils.getCookie('avatar') || ''

  if (avatarImg.includes('http') && !avatarImg.includes('https')) {
    avatarImg = avatarImg.replace('http:', '')
  }
  if (avatarImg.includes('https')) {
    avatarImg = avatarImg.replace('https:', '')
  }
  if (avatarImg.includes('nopic.jpg')) {
    avatarImg = userType === 1
      ? require('images/nopic.jpg') // student
      : require('images/teacherImgDefault.png') // taecher
  }
  return avatarImg
}
