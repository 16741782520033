import * as utils from 'common/utils'
import * as React from 'react'
import { Icon } from 'antd'
import ModalQuestionAnswer from 'components/school/modal_question_answer'

const cursorStyle = {
  cursor: 'pointer'
}

class ListItem extends React.Component<any, any> {
  state = {
    isAbsencing: this.props.isAbsencing || false
  }
  isOnline = this.props.isOnline

  handleEdit = async () => {
    // 编辑逻辑
    const { data, onCheckIsAbsencing } = this.props
    if (this.isOnline) {
      await onCheckIsAbsencing()
      const { isAbsencing } = this.props
      if (isAbsencing) {
        return this.props.onVacation()
      }
    }
    this.props.onEdit(data.content, data.id)
  }

  handleDelete = async () => {
    const { type, data, onCheckIsAbsencing } = this.props
    if (this.isOnline) {
      await onCheckIsAbsencing()
      const { isAbsencing } = this.props
      if (isAbsencing) {
        return this.props.onVacation()
      }
    }
    this.props.onDelete(data.id, type)
  }

  handleVacation = () => {
    this.props.onVacation()
  }

  handleReply = async () => {
    const { data, callback, onCheckIsAbsencing } = this.props
    if (this.isOnline) {
      await onCheckIsAbsencing()
      const { isAbsencing } = this.props
      if (isAbsencing) {
        return this.props.onVacation()
      }
    }
    utils.createModal({
      title: '我的提问',
      child: <ModalQuestionAnswer id={data.id} refresh={callback} />,
      hideButtons: true
    })
  }

  render () {
    const { type, data } = this.props
    return (
      <div className='list-item-container'>
        <p className='content'>
          {data.content}
        </p>
        <div className='bottom'>
          {data.time
            ? <span className='time' onClick={() => this.props.onSetTime(data.timeOrigin)}>
              <Icon style={cursorStyle} type='play-circle' />
              <span className='timestamp'>{data.time}</span>
            </span>
            : null
          }
          <span className='operations'>
            {
              type === 'notes'
                ? <Icon className='edit' style={cursorStyle} onClick={this.handleEdit} type='edit' />
                : <span
                  className='reply'
                  onClick={this.handleReply}
                  style={cursorStyle}
                >
                  回复
                  {`(${data.answerCount})`}
                </span>
            }
            <Icon className='delete' style={cursorStyle} onClick={this.handleDelete} type='delete' />
          </span>
        </div>
      </div>
    )
  }
}

export default ListItem
