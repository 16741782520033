import * as _ from 'lodash'
import * as utils from 'common/utils'
import config from 'common/config'
import * as React from 'react'
import constants from 'common/constants'
import ModalQuestionAnswer from 'components/school/modal_question_answer'
import * as vacationHelper from 'helpers/vacation'
import Header from 'components/common/online_player/header'
import Player from 'components/common/online_player/player'
import VideoMask from 'components/common/online_player/video_mask'
import Menu from 'components/common/online_player/menu'
import Questions from 'components/common/online_player/questions'
import Notes from 'components/common/online_player/notes'
import Feedback from 'components/common/online_player/feedback'
import Loading from 'components/common/loading'
import CommonModal from 'components/common/common_modal'
import VacationModal from 'components/common/vacation_modal'
import VideoHasPermission from 'components/student/video_haspermission'
import * as rest from 'common/rest'
import BScroll from 'better-scroll'
import { Tabs, Icon, message, Modal, Input } from 'antd'
import * as studentHelper from 'helpers/student'

const videoPermissionStyle = { marginTop: 0 }
const { TextArea } = Input
const { ONE_DAY } = constants

const errorTypes = {
  1: 'quiz错误',
  2: '字幕错误',
  3: '视频内容缺失',
  4: '老师讲解错误',
  5: '其它'
}

const USER_TYPE = {
  STUDENT: 1,
  TEACHER: 2
}

const TabPane = Tabs.TabPane
const getType = (userType, query) => {
  if (userType === 1) {
    if (query.date) return 'student'
    if (query.practiceId) return 'multiCtqa'
    if (query.servicePackId) return 'servicePack'
    if (query.courseId) return 'course'
    if (query.taskId) return 'task'
    if (query.sliceId) return 'slice'
  } else if (userType === 2) {
    if (query.courseId) return 'course'
    if (query.sliceId && !query.courseId) return 'slice'
  }
  return 'illegal'
}

const getSelectedCourses = data => {
  let selectedCourseIds = []
  data.orderCoursePackages.forEach(i => {
    selectedCourseIds = selectedCourseIds.concat(i.courses)
  })
  return selectedCourseIds.map(i => data.courses[i])
}
const getSections = async data => {
  let sections = data.outline || []
  let sliceIds = []
  sections.forEach(section => { sliceIds = sliceIds.concat(section.section.map(i => i.id)) })
  const slices = await utils.getSlices(sliceIds)
  sections.forEach(i => {
    i.section.forEach(j => {
      j.duration = slices[j.id].duration
    })
  })
  return sections
}
let scrollTimeout = null
let scroller = null
const delayScroll = (type, actions = []) => {
  try {
    clearTimeout(scrollTimeout)
  } catch (e) {}

  scrollTimeout = setTimeout(() => {
    const scrollAnchor = document.getElementById('scroll-anchor')
    if (!scrollAnchor) return
    const parent = scrollAnchor.parentNode
    scroller = scroller || new BScroll(parent as any) // TODO: 类型强转，检查有没有问题
    let height = -86
    if (type === 'student') {
      height += 38
      let dom = document.getElementById('scroll-anchor-target')
      height += dom.offsetTop
    } else if (type === 'servicePack' || type === 'trial') {
      while (actions.length) {
        height += 38
        let action = actions.shift()
        let id = action.type === 'slice'
          ? 'scroll-anchor-target'
          : `menu-item-${action.type}-${action.key}`
        let dom = document.getElementById(id)
        height += dom.offsetTop
      }
    }
    scroller.scrollTo(0, height)
  }, 3000)
}

// 由于编辑器提示package是保留字, 故变量名使用package_

export class OnlinePlayer extends React.Component<any, any> {
  player: any
  timer: any
  taskId: any

  userType = utils.getUserTypeId()
  type = getType(this.userType, this.props.location.query)
  title = this.props.location.query.title ? decodeURIComponent(utils.base64.decode(this.props.location.query.title)) : ''
  autoPlayNext_ = localStorage && localStorage.getItem('ss_video_auto_play')
  state = {
    actions: [],
    tab: 'menu',
    dates: [],
    date: '',
    slice: {} as any,
    task: {} as any,
    tasks: {},
    notes: [],
    questions: [],
    editId: null,
    editContent: '',
    editVisible: false,
    courses: [], // servicePack模式下是数组, trial模式下是以courseId为key的object
    courseId: null,
    sections: {},
    sectionIndex: null,
    packages: [],
    packageId: null,
    teachers: {},
    ready: false,
    expand: true,
    maskVisible: false,
    name: '',
    autoPlayNext: this.autoPlayNext_ ? JSON.parse(this.autoPlayNext_) : this.type !== 'slice',
    controlPlayAgain: false,
    feedbackVisible: false,
    vacationModalVisible: false,
    vacationModalAlertVisible: false,
    time: '',
    title: '',
    selectedId: '',
    nextDate: '',
    nextTask: {},
    nextSlice: {} as any,
    resolution: '高清',
    currentTime: 0,
    questionSubmitDisable: true,
    noteSubmitDisable: true,
    isAbsencing: false,
    hasPermission: false,
    hasPermissionText: '',
    hasPermissionType: '',
    absenceTimes: 0,
    versions: null,
    organization: null,
    productId: 0,
    messageShowTime: 5
  }
  orgId = utils.getOrganizationId()
  userId = utils.getUserId()
  token = utils.getToken()
  userAccount = utils.getLocalStorage('userAccount')
  isOnline = utils.isOnline()
  resizeTimeout = null
  serveType = utils.getStudentServeType()
  pauseNum = 0
  messageShowTimer=null

  constructor (props) {
    super(props)

    window.onresize = () => {
      try {
        clearTimeout(this.resizeTimeout)
      } catch (e) {
        // do nothing
      }

      this.resizeTimeout = setTimeout(() => this.forceUpdate(), 100)
    }
  }

  getSection = (sections) => {
    const query = this.props.location.query
    let section = sections[0]
    let sectionIndex = section.index
    let slice = section.section[0]
    if (query.sliceId) {
      for (let i = 0; i < sections.length; i++) {
        for (let j = 0; j < sections[i].section.length; j++) {
          if (sections[i].section[j].id == query.sliceId) {
            section = sections[i]
            slice = section.section[j]
            sectionIndex = section.index
          }
        }
      }
    } else if (query.sectionIndex) {
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].index === query.sectionIndex) {
          section = sections[i]
          slice = section.section[0]
          sectionIndex = section.index
        }
      }
    }
    return { section, slice, sectionIndex }
  }

  async componentDidMount () {
    const query = this.props.location.query
    await this.fetchOrganization()
    await this.fetchResolution()
    await this.handleCheckIsAbsencing()

    let initTime = query.time ? query.time - 0 : null
    let tipId = query.tipId ? query.tipId - 0 : null
    let practiceId = query.practiceId ? +query.practiceId : null
    if (this.type === 'student') {
      await this.fetchDates(initTime)
    } else if (this.type === 'task') {
      await this.fetchTask(initTime)
      await this.onSliceChange(this.state.task.sliceInfo, initTime)
    } else if (this.type === 'servicePack') {
      await this.fetchCourses(initTime)
    } else if (this.type === 'multiCtqa') {
      await this.fetchMultiCtqas(practiceId)
    } else if (this.type === 'slice') {
      await this.fetchSlice(query.sliceId - 0, initTime, tipId)
    } else if (this.type === 'illegal') {
      this.setState({
        hasPermission: false,
        hasPermissionText: '参数不合法'
      })
    } else if (this.type === 'course') {
      await this.fetchCourse(initTime)
    }
    this.setState({ ready: true })
  }

  fetchResolution = async () => {
    const userId = utils.getUserId()
    const key = `playerMode-${userId}`
    const res = await rest.get(`/api/common-config/key/${key}?business=smart-player`)
    if (res.code === 0) {
      this.setState({
        resolution: _.get(res, ['data', 'value']) || '高清'
      })
    }
  }

  hasPermission = async sliceId => {
    if (this.userType === 2) {
      return this.setState({ hasPermission: true })
    }
    let type
    if (['ctqa', 'multi-ctqa'].includes(this.props.location.query.type)) {
      type = 2
    } else {
      type = 1
    }
    const sliceData = await utils.hasPermission(sliceId, type)
    if (sliceData === 'video_permission_error') return
    this.setState({
      hasPermission: sliceData.hasPermission || false,
      hasPermissionText: sliceData.reason,
      hasPermissionType: sliceData.type
    })
  }

  fetchCourse = async (initTime) => {
    const query = this.props.location.query
    const courseId = query.courseId - 0
    const url = `/api/course/${courseId}/videos`
    const res = await rest.get(url)
    if (res.code) return message.error({ text: '获取课程切片失败', msg: res.msg })
    const sections = await getSections(res.data)
    this.setState({
      courseId,
      courses: [Object.assign({}, res.data, { isOpen: true })],
      sections: { [courseId]: sections }
    })

    if (!sections || !sections.length) return
    const { slice, sectionIndex } = this.getSection(sections)
    const originUserId = utils.getOriginUserId()
    const progresses = await utils.fetchSliceProgress(originUserId, slice.id)
    if (progresses[slice.id].time) {
      slice.time = progresses[slice.id].time
    }
    await this.toggleSectionOpen(courseId, sectionIndex, true)
    this.setState({ sectionIndex })
    await this.onSliceChange(slice, initTime)
  }

  fetchMultiCtqas = async practiceId => {
    // 以下代码都是造出的一些数据，不是真正添加了一个新的模式，等同于student模式的功能
    const token = utils.getToken()
    const res = await rest.get(`${config.url.www}/api/product/ctqa/${practiceId}/catalog?token=${token}&productId=&ctqaType=practice`)
    if (res.code !== 0) {
      this.setState({
        hasPermission: false,
        hasPermissionText: '参数不合法'
      })
      return false
    } else {
      const sections = _.get(res, ['data', 'course', 'sections'])
      const videos = sections.reduce((res, item) => res.concat(item.parts || []), []).map(i => {
        return {
          ...i,
          sliceId: i.id,
          taskId: i.id,
          sliceInfo: {
            duration: i.duration,
            id: i.id,
            name: i.name
          }
        }
      })
      const tasks = {
        视频详解: videos
      }
      await this.hasPermission(videos[0].id)
      const date = '视频详解'
      const dates = [{ date: '视频详解', isOpen: true }]
      const versions = await utils.fetchSliceVersions(videos[0].id)
      this.setState({
        task: videos[0],
        versions,
        tasks,
        date,
        dates,
        slice: { ...videos[0] }
      })
      await Promise.all([
        this.fetchNotes(videos[0].id),
        this.fetchQuestions(videos[0].id)
      ])
    }
  }

  fetchCourses = async initTime => {
    const { query } = this.props.location
    const servicePackId = query.servicePackId - 0
    const res = await rest.get(
      `${config.url.www}/api/user/product/${servicePackId}?token=${utils.getToken()}`,
      { credentials: 'omit' }
    )
    if (res.code) return message.error({ text: '获取商品课程信息失败', msg: res.msg })
    if (this.type === 'servicePack') {
      if (!res.data.isChooseTeacher) {
        this.type = 'trial'
        return this.onTrialCourseGet(res.data, initTime)
      }
    }
    let courses = getSelectedCourses(res.data)
    const productId = _.get(res, ['data', 'productId']) || 0
    this.setState({ courses, productId })
    if (!courses.length) return
    let course = courses[0]
    let courseId = course.id
    if (query.courseId) {
      for (let i = 0; i < courses.length; i++) {
        if (courses[i].id == query.courseId) {
          course = courses[i]
          courseId = course.id
          break
        }
      }
    }
    await this.toggleCourseOpen(courseId, true)
    await this.onCourseChange(course)

    const sections = this.state.sections[this.state.courseId]
    if (!sections || !sections.length) return
    const { slice, sectionIndex } = this.getSection(sections)

    await this.toggleSectionOpen(courseId, sectionIndex, true)
    this.setState({ sectionIndex })
    await this.onSliceChange(slice, initTime)
  }

  fetchOrganization = async () => {
    const res = await rest.get(utils.genUrl({ id: this.orgId }, '/api/organization'))
    if (res.code) return message.error({ text: '获取机构信息失败', msg: res.msg })
    const organization = res.data
    this.setState({ organization })
  }

  fetchDates = async initTime => {
    const res = await rest.get('/api/student-task/courses/dates')
    if (res.code) return message.error({ text: '获取课程日期失败', msg: res.msg })
    const dates = res.data.dates.map(i => ({ date: i }))
    this.setState({ dates })
    if (!this.state.dates.length) return

    let date = dates[0]
    const { query } = this.props.location
    query.date && dates.forEach(i => {
      if (i.date === query.date) {
        date = i
      }
    })

    await this.toggleDateOpen(date.date, true)
    this.setState({ date: date.date })
    if (!this.state.tasks[this.state.date].length) return

    let task = this.state.tasks[this.state.date][0]
    query.taskId && this.state.tasks[this.state.date].forEach(i => {
      if (i.taskId == query.taskId) {
        task = i
      }
    })
    await this.onTaskChange(date.date, task, initTime)
  }

  fetchSlice = async (sliceId, initTime, tipId) => {
    sliceId = sliceId || this.props.location.query.sliceId - 0
    await this.hasPermission(sliceId)
    const originUserId = utils.getOriginUserId()
    const [sliceInfo, progress, versions] = await Promise.all([
      utils.fetchSliceInfo(sliceId),
      utils.fetchSliceProgress(originUserId, sliceId),
      utils.fetchSliceVersions(sliceId)
    ])
    let slice = Object.assign({}, sliceInfo, progress[sliceId], { duration: sliceInfo.duration || 0 })
    if (initTime !== null) {
      slice.time = initTime
    }
    if (tipId !== null) {
      slice.tipId = tipId
    }
    this.setState({ slice, versions, currentTime: initTime || 0 })
    await Promise.all([
      this.fetchNotes(sliceId),
      this.fetchQuestions(sliceId)
    ])
  }

  fetchNotes = async (sliceId?: number) => {
    sliceId = sliceId || this.state.slice.id
    const url = `/api/notes?studentId=${this.userId}&sliceId=${sliceId}`
    const res = await rest.get(url)
    if (res.code) return message.error({ text: '获取笔记失败', msg: res.msg })
    this.setState({ notes: res.data.data })
  }

  fetchQuestions = async (sliceId?: number) => {
    sliceId = sliceId || this.state.slice.id
    const url = `/api/questions?studentId=${this.userId}&sliceId=${sliceId}`
    const res = await rest.get(url)
    if (res.code) return message.error({ text: '获取提问失败', msg: res.msg })
    this.setState({ questions: res.data.data })
  }

  patchSections = async course => {
    let sliceIds = []
    let sectionsArr = course.outline.map(i => {
      i.section = i.section.filter(j => j.type - 0 === 1)
      return i
    })
    sectionsArr.forEach(section => { sliceIds = sliceIds.concat(section.section.map(i => i.id)) })
    const originUserId = utils.getOriginUserId()
    const progresses = await utils.fetchSliceProgress(originUserId, sliceIds)
    sectionsArr.forEach(section => section.section.forEach(i => {
      Object.assign(i, progresses[i.id] || {}, { sliceId: i.id - 0, duration: i.duration / 1000 })
    }))
    this.setState(state => {
      state.sections[course.id] = sectionsArr
      return state
    })
  }

  patchTasks = async date => {
    let startTime
    let endTime
    if (date === 'none') {
      startTime = 'none'
      endTime = 'none'
    } else {
      startTime = new Date(date.replace(/\-/g, '/'))
      endTime = new Date(startTime.getTime() + ONE_DAY).toISOString()
      startTime = startTime.toISOString()
    }
    const res = await rest.get(utils.genUrl({
      studentId: this.userId,
      startTime,
      endTime
    }, '/api/student-task/courses'))
    if (res.code) return message.error({ text: '获取单日课程失败', msg: res.msg })
    let tasks = res.data.courses
    let sliceIds = tasks.map(task => task.sliceId)
    sliceIds = sliceIds.filter(sliceId => sliceId != null)
    const originUserId = utils.getOriginUserId()
    const [slices, progresses] = await Promise.all([
      utils.getSlices(sliceIds),
      utils.fetchSliceProgress(originUserId, sliceIds)
    ])
    tasks.forEach(task => {
      const duration = _.get(slices, [+task.sliceId, 'duration']) || 0
      task.sliceInfo = Object.assign({}, slices[task.sliceId], progresses[task.sliceId], { duration })
    })
    this.setState(state => {
      state.tasks[date] = tasks
      return state
    })
  }

  fetchTask = async initTime => {
    const res = await rest.get(`/api/student/task/${this.props.location.query.taskId}`)
    if (res.code !== 0) return message.error({ text: '获取课程信息失败', msg: res.msg })
    let task = res.data
    await this.hasPermission(task.sliceInfo.id)
    this.setState({ task })
  }

  onTrialCourseGet = async (data, initTime) => {
    let packages = data.orderCoursePackages.map(i => {
      i.courseId = i.courses[0]
      return i
    })
    let courses = data.courses
    let sections = {}
    let trialIds = {}
    data.trialIds.forEach(i => { trialIds[i] = true })
    Object.keys(courses).forEach(courseId => {
      const cid = Number(courseId)
      sections[cid] = courses[courseId].outline
      sections[cid].forEach(section => {
        section.section = section.section.filter(slice => trialIds[slice.id] && slice.type - 0 === 1)
        section.section.forEach(slice => {
          slice.duration /= 1000
          slice.sliceId = slice.id
        })
      })
      sections[cid] = sections[cid].filter(i => i.section.length)
    })
    const teachers = data.teachersInfo
    this.setState({ packages, courses, sections, teachers })
    const { query } = this.props.location
    let package_ = packages[0]
    let packageId = packages[0].id
    if (query.packageId) {
      for (let i = 0; i < this.state.packages.length; i++) {
        if (this.state.packages[i].id === query.packageId) {
          package_ = this.state.packages[i]
          packageId = this.state.packages[i].id
          break
        }
      }
    }
    this.setState({ packageId })
    await this.togglePackageOpen(packageId, true)

    let courseId = package_.courses[0] - 0
    if (query.courseId && package_.courses.indexOf(`${query.courseId}`) > -1) {
      courseId = query.courseId - 0
    }
    await this.onPackageTeacherChange(packageId, courseId)

    let section = this.state.sections[courseId][0]
    let sectionIndex = section.index
    if (query.sectionIndex) {
      for (let i = 0; i < this.state.sections[courseId].length; i++) {
        if (this.state.sections[courseId][i].index === query.sectionIndex) {
          sectionIndex = query.sectionIndex
          section = this.state.sections[courseId][i]
          break
        }
      }
    }
    this.setState({ sectionIndex })
    await this.toggleSectionOpen(courseId, sectionIndex, true)

    let slice = section.section[0]
    if (query.sliceId) {
      for (let i = 0; i < section.section.length; i++) {
        if (section.section[i].id === query.sliceId - 0) {
          slice = section.section[i]
          break
        }
      }
    }
    await this.onSliceChange(slice, initTime)
  }

  onTabChange = async tab => {
    this.setState({ tab })
  }

  handleConfirmVacation = async () => {
    vacationHelper.handleConfirmVacation(this.state.isAbsencing, async () => {
      this.setState({ vacationModalVisible: false })
      await this.handleCheckIsAbsencing()
      const sliceId = this.state.slice.id
      await this.hasPermission(sliceId)
    })
  }

  handleHideVacationModal = () => this.setState({ vacationModalVisible: false })

  handleSubmit = async (params, url, type) => {
    let data = Object.assign(
      {},
      params,
      {
        studentId: this.userId,
        sliceId: this.state.slice.id,
        type: this.props.location.query.type || 'course'
      }
    )
    if (this.type === 'task' || this.type === 'student') {
      data.taskId = this.state.task.id
    }
    if (this.props.location.query.type) {
      data.type = this.props.location.query.type === 'multi-ctqa' ? 'ctqa' : this.props.location.query.type
    }
    const res = await rest.post(url, data)
    if (res.code) {
      // const text = type === 'questions' ? '提问失败' : '记录笔记失败';
      return message.error(res.msg)
    }
    if (res.data.id && type === 'questions') {
      this.setState({ questionSubmitDisable: false })
    } else if (res.data.id && type === 'notes') {
      this.setState({ noteSubmitDisable: false })
    }
    if (type === 'questions') {
      message.success('提问成功')
      await this.fetchQuestions()
      this.setState({ questionSubmitDisable: true })
      // 提问神策自定义事件
    } else {
      message.success('记录笔记成功')
      await this.fetchNotes()
      this.setState({ noteSubmitDisable: true })
      // 笔记神策自定义事件
    }
  }

  handleDelete = async (id, url, type) => {
    if (type === 'questions') {
      url = `/api/question/${id}`
    }
    Modal.confirm({
      title: '提示',
      content: `确认删除此${type === 'notes' ? '笔记' : '问题'}？删除后无法撤销`,
      onOk: async () => {
        let res
        if (type === 'questions') {
          res = await rest.del(url)
        } else {
          res = await rest.del(url, { body: JSON.stringify({ id }) })
        }
        if (res.code) {
          return message.error({ text: '删除失败', msg: res.msg })
        }
        message.success('删除成功')
        if (type === 'notes') {
          await this.fetchNotes()
        } else {
          await this.fetchQuestions()
        }
      },
      onCancel () {}
    })
  }

  handleEdit = async (content, id) => {
    this.setState({
      editId: id,
      editContent: content,
      editVisible: true
    })
  }

  handleSubmitEdit = async () => {
    const { editId, editContent } = this.state
    if (editContent.length === 0) {
      return message.error('笔记内容不能为空')
    }
    const maxContentLength = 500
    if (editContent.length > maxContentLength) {
      return message.error(`请输入不超过${maxContentLength}字`)
    }
    const res = await rest.put('/api/note', {
      id: editId,
      content: editContent
    })
    if (res.code) {
      return message.error({ text: '编辑笔记失败', msg: res.msg })
    }
    message.success('编辑笔记成功')
    await this.fetchNotes()
    await this.handleCancel()
  }

  handleCancel = async () => {
    this.setState({
      editId: null,
      editContent: '',
      editVisible: false
    })
  }

  handleVacation = () => {
    this.setState({
      vacationModalVisible: true
    })
  }

  handleReply = id => {
    utils.createModal({
      title: `问题#${id}`,
      child: <ModalQuestionAnswer id={id} onCheckIsAbsencing={this.handleCheckIsAbsencing} refresh={this.fetchQuestions} />,
      hideButtons: true
    })
  }

  toggleExpand = async () => this.setState(state => {
    state.expand = !state.expand
    return state
  })

  handleSubmitFeedback = async (error, detail, contactInfo, time) => {
    const url = `${config.url.www}/api/video/error`
    const { task, slice } = this.state
    const sliceInfo = task.sliceInfo || slice
    const data = {
      token: this.token,
      sliceId: sliceInfo.id,
      time: Math.floor(time),
      type: error,
      detail,
      contactInfo
    }
    const res = await rest.postFormUrlencodedData(url, data, { credentials: 'omit' })
    if (res.code) {
      return message.error({ text: '提交反馈失败', msg: res.msg })
    }
    message.success('提交反馈成功')
    this.setState({
      feedbackVisible: false
    })
  }

  handleCloseFeedback = () => {
    this.setState({
      time: '',
      title: '',
      selectedId: '',
      feedbackVisible: false
    })
  }

  handleAutoPlay = async autoPlayNext => {
    this.setState({ autoPlayNext })
    localStorage && localStorage.setItem('ss_video_auto_play', JSON.stringify(autoPlayNext))
  }

  handleReturn = () => {
    utils.redirect('/')
    location.reload()
  }

  onCourseChange = async course => {
    if (!course) return this.setState({ courseId: null })
    const courseId = course.id
    if (this.state.sections[courseId]) {
      return this.setState({ courseId })
    }
    if (!course.outline.length) {
      return this.setState(state => {
        state.courseId = courseId
        state.sections[courseId] = []
        return state
      })
    }

    if (!this.state.sections[courseId]) {
      await this.patchSections(course)
    }
    this.setState(state => {
      state.courseId = courseId
      return state
    })
  }

  onTaskChange = async (date, task, initTime = 0) => {
    const sliceId = _.get(task, 'sliceInfo.id')
    if (!sliceId) {
      return
    }
    await this.hasPermission(sliceId)
    const versions = await utils.fetchSliceVersions(sliceId)
    if (initTime) {
      task.sliceInfo.time = initTime
    }
    task.taskId = task.taskId || task.id
    this.setState({
      date,
      task,
      slice: task.sliceInfo,
      currentTime: initTime || 0,
      versions,
      maskVisible: false,
      nextTask: {}
    })
    this.fetchQuestions()
    this.fetchNotes()
  }

  onSliceChange = async (slice, initTime = 0, params = {}) => {
    await this.hasPermission(slice.id)
    const versions = await utils.fetchSliceVersions(slice.id)
    if (initTime === 0 || slice.percent > 96 || slice.time > slice.duration - 10) {
      slice.time = 0
      slice.percent = 0
    }
    let currentTime = typeof initTime === 'number' ? initTime : (slice.time || 0)
    let newState = {
      slice,
      currentTime,
      versions,
      maskVisible: false,
      nextSlice: {},
      ...params
    }
    this.setState(newState)
    await Promise.all([
      this.fetchQuestions(),
      this.fetchNotes()
    ])
  }

  toggleSectionOpen = async (courseId, key, isOpen) => {
    this.setState(state => {
      state.sections[courseId].forEach(i => {
        if (i.index === key) {
          i.isOpen = isOpen
        }
      })
      return state
    })
  }

  toggleSectionsOpen = async (courseId, keys) => {
    this.setState(state => {
      state.sections[courseId].forEach(i => {
        i.isOpen = keys.indexOf(i.index) > -1
      })
      return state
    })
  }

  toggleCourseOpen = async (key, isOpen) => {
    let course = null
    this.setState(state => {
      state.courses.forEach(i => {
        if (i.id == key) {
          i.isOpen = isOpen
          course = i
        }
      })
      return state
    })
    if (isOpen && !this.state.sections[key] && course) {
      await this.patchSections(course)
    }
  }

  toggleCoursesOpen = async keys => {
    this.setState(state => {
      state.courses.forEach(i => {
        i.isOpen = keys.indexOf(`${i.id}`) > -1
      })
      return state
    })
    const unpatchedKeys = keys.filter(i => !this.state.sections[i])
    if (unpatchedKeys.length) {
      await Promise.all(unpatchedKeys.map(i => this.patchSections(this.state.courses.find(course => course.id == i))))
    }
  }

  togglePackageOpen = async (packageId, isOpen) => {
    this.setState(state => {
      state.packages.forEach(i => {
        if (i.id == packageId) {
          i.isOpen = isOpen
        }
      })
      return state
    })
  }

  togglePackagesOpen = async keys => {
    this.setState(state => {
      state.packages.forEach(i => {
        i.isOpen = keys.indexOf(`${i.id}`) > -1
      })
      return state
    })
  }

  toggleDateOpen = async (date, isOpen) => {
    this.setState(state => {
      state.dates.forEach(i => {
        if (i.date === date) {
          i.isOpen = isOpen
        }
      })
      return state
    })
    if (isOpen && !this.state.tasks[date]) {
      await this.patchTasks(date)
    }
  }

  toggleDatesOpen = async keys => {
    this.setState(state => {
      state.dates.forEach(i => {
        i.isOpen = keys.indexOf(i.date) > -1
      })
      return state
    })
    const unpatchedKeys = keys.filter(i => !this.state.tasks[i])
    if (unpatchedKeys.length) {
      await Promise.all(unpatchedKeys.map(i => this.patchTasks(i)))
    }
  }

  onPackageTeacherChange = async (packageId, courseId) => {
    this.setState(state => {
      for (let i = 0; i < state.packages.length; i++) {
        if (state.packages[i].id == packageId) {
          state.packages[i].courseId = courseId
        }
      }
      return state
    })
  }

  onFeedback = async () => {
    window['zkwPlayer'].player.pause()
    // await this.isPause() // TODO: 不存在的函数，暂时注释，之后遇到问题再查
    const title = this.state.task.name
    const sliceId = this.state.slice.id
    this.setState({
      title,
      selectedId: sliceId,
      feedbackVisible: true
    })
    // ...
  }

  onShare = async () => {
    // ...
  }

  onInput = async (isPause) => {
    if (isPause) {
      await this.player.pause()
    }
  }

  onUploadTime = async (slice, time, isFinish) => {
    const originUserId = utils.getOriginUserId()
    if (!originUserId) return
    if (window['zkwPlayer'].player.paused()) return false
    this.setState({ currentTime: time })
    let sliceId = this.state.slice.id
    const duration = this.state.slice.duration
    let percent = isFinish ? 100 : Math.ceil(100 * time / duration)
    const studentId = utils.getUserId()
    if (percent > 100) percent = 100
    let params: any = {
      userId: originUserId,
      sliceId,
      time,
      percent
    }

    const courseId = this.props.location.query.courseId - 0
    if (courseId) {
      params.courseId = courseId
    }
    if (this.state.productId) {
      params.productId = this.state.productId
    }
    const res = await rest.put('/api/video/progress', params)
    if (res.code && studentId) return message.error({ text: '上传时间进度失败', msg: res.msg })
  }

  // 当不是从头播放的时候 显示倒计时按钮
  onPlayerInit = async () => {
    let { currentTime } = this.state
    if (this.type === 'course' && currentTime > 1) {
      this.computeMessageTitle()
    }
  }

  onPlayerWillFinish = async () => {
    const taskId = this.taskId || this.props.location.query.taskId
    if (!taskId) return
    const res = await rest.put(`/api/student-task/${taskId}`, { isCompleted: true })
    if (res.code) return message.error({ text: '设置学员任务已完成失败', msg: res.msg })
  }

  onPlayerFinish = async () => {
    if (this.type === 'slice' || !this.state.autoPlayNext) {
      return this.setState({ maskVisible: true, nextTask: {} })
    }
    await this.getNext()
    await this.pendingNext()
  }

  getNext = async () => {
    // servicePack模式 or course模式
    if (this.type === 'servicePack' || this.type === 'course') {
      let flag = false
      for (let i = 0; i < this.state.courses.length; i++) {
        const course = this.state.courses[i]
        if (!this.state.sections[course.id]) {
          await this.patchSections(course)
        }

        const sections = this.state.sections[course.id]
        if (!sections.length) continue
        if (flag) {
          return this.setState(state => {
            state.nextSlice = sections[0].section[0]
            state.actions.push({ type: 'course', key: course.id })
            state.actions.push({ type: 'section', key: sections[0].index })
            state.actions.push({ type: 'slice', key: sections[0].section[0].id })
            return state
          })
        }
        for (let j = 0; j < sections.length; j++) {
          const section = sections[j]
          if (!section.section || !section.section.length) continue
          if (flag) {
            return this.setState(state => {
              state.nextSlice = section.section[0]
              state.actions.push({ type: 'course', key: course.id })
              state.actions.push({ type: 'section', key: section.index })
              state.actions.push({ type: 'slice', key: section.section[0].id })
              return state
            })
          }
          for (let k = 0; k < section.section.length; k++) {
            const slice = section.section[k]
            if (flag) {
              return this.setState(state => {
                state.nextSlice = slice
                state.actions.push({ type: 'course', key: course.id })
                state.actions.push({ type: 'section', key: section.index })
                state.actions.push({ type: 'slice', key: slice.id })
              })
            }
            if (this.state.slice.id === slice.id) flag = true
          }
        }
      }

      return this.setState({ nextSlice: {} })

    // student模式
    } else if (this.type === 'student' || this.type === 'multiCtqa') {
      let flag = false
      for (let i = 0; i < this.state.dates.length; i++) {
        const dateObj = this.state.dates[i]
        if (!this.state.tasks[dateObj.date]) {
          await this.patchTasks(dateObj.date)
        }
        const tasks = this.state.tasks[dateObj.date]
        if (!tasks.length) continue
        if (flag) {
          return this.setState(state => {
            state.nextDate = dateObj.date
            state.nextTask = tasks[0]
            state.nextSlice = tasks[0].sliceInfo
            state.actions.push({ type: 'date', key: dateObj.date })
            return state
          })
        }
        for (let j = 0; j < tasks.length; j++) {
          const task = tasks[j]
          if (flag) {
            return this.setState(state => {
              state.nextDate = dateObj.date
              state.nextTask = task
              state.nextSlice = task.sliceInfo
              state.actions.push({ type: 'date', key: dateObj.date })
              return state
            })
          }
          if (this.state.task.taskId == task.taskId) flag = true
        }
      }

    // trial模式
    } else if (this.type === 'trial') {
      let flag = false
      for (let i = 0; i < this.state.packages.length; i++) {
        const package_ = this.state.packages[i]
        const courseId = package_.courses[0]
        if (package_.courses.length === 1) {
          if (courseId && package_.courses[0] != courseId) return message.error(`${package_.name}已选教师, 指定错误`)
        } else {
          if (courseId && package_.courses.indexOf(`${courseId}`) == -1) return message.error(`${package_.name}没有该教师课程, 指定错误`)
        }
        const course = this.state.courses[courseId]
        if (!course || !course.outline.length) break
        if (!this.state.sections[courseId]) {
          await this.patchSections(course)
        }
        const sections = this.state.sections[courseId]

        if (flag) {
          return this.setState(state => {
            state.nextSlice = sections[0].section[0]
            state.actions.push({ type: 'package', key: package_.id })
            state.actions.push({ type: 'section', key: sections[0].index })
            state.actions.push({ type: 'slice', key: sections[0].section[0].id })
            return state
          })
        }
        for (let j = 0; j < sections.length; j++) {
          if (!sections[j].section || !sections[j].section.length) continue
          if (flag) {
            return this.setState(state => {
              state.nextSlice = sections[j].section[0]
              state.actions.push({ type: 'package', key: package_.id })
              state.actions.push({ type: 'section', key: sections[j].index })
              state.actions.push({ type: 'slice', key: sections[j].section[0].id })
              return state
            })
          }

          for (let k = 0; k < sections[j].section.length; k++) {
            const slice = sections[j].section[k]
            if (flag) {
              return this.setState(state => {
                state.nextSlice = slice
                state.actions.push({ type: 'package', key: package_.id })
                state.actions.push({ type: 'section', key: sections[j].index })
                state.actions.push({ type: 'slice', key: slice.id })
                return state
              })
            }
            if (this.state.slice.id === slice.id) flag = true
          }
        }
      }
    }
  }

  pendingNext = async () => {
    this.setState({ maskVisible: true })
    try {
      clearTimeout(this.timer)
    } catch (e) {}
    this.timer = setTimeout(async () => {
      if (this.type === 'student' || this.type === 'multiCtqa') {
        await this.onTaskChange(this.state.nextDate, this.state.nextTask)
      } else {
        await this.onSliceChange(this.state.nextSlice)
      }
      await this.clearActions()
    }, 5000) // eslint-disable-line no-magic-numbers
  }

  onPlayNext = async () => {
    try {
      clearTimeout(this.timer)
    } catch (e) {}
    if (this.type === 'multiCtqa') {
      await this.onTaskChange('视频详解', this.state.nextTask)
    } else {
      await this.onSliceChange(this.state.nextSlice)
    }
    window['zkwPlayer'].player.play()
  }

  clearActions = async () => {
    this.setState(state => {
      let courseId
      state.actions.forEach(i => {
        if (i.type === 'date') {
          state.dates.forEach(j => {
            if (j.date === i.key) {
              j.isOpen = true
            }
          })
        }
        if (i.type === 'package') {
          state.packageId = i.key
          state.packages.forEach(j => {
            if (j.id === i.key) {
              courseId = j.courseId
              j.isOpen = true
            }
          })
        }
        if (i.type === 'course') {
          state.courseId = i.key
          state.courses.forEach(j => {
            if (j.id == i.key) {
              j.isOpen = true
            }
          })
          courseId = i.key
        }
        if (i.type === 'section') {
          state.sectionIndex = i.key
          if (courseId) {
            state.sections[courseId].forEach(j => {
              if (j.index == i.key) {
                j.isOpen = true
              }
            })
          }
        }
      })
      if (this.type === 'student') {
        delayScroll('student')
      } else if (this.type === 'servicePack') {
        delayScroll('servicePack', JSON.parse(JSON.stringify(state.actions)))
      } else if (this.type === 'trial') {
        delayScroll('trial', JSON.parse(JSON.stringify(state.actions)))
      }

      state.actions = []
      return state
    })
  }

  onReplay = async () => {
    try {
      clearTimeout(this.timer)
    } catch (e) {}
    if (this.type === 'multiCtqa') {
      this.setState({ maskVisible: false })
    } else {
      this.setState({ task: {}, maskVisible: false })
      await this.onSliceChange(this.state.slice)
    }
    window['zkwPlayer'].player.currentTime(0)
    window['zkwPlayer'].player.play()
  }

  onSetTime = time => {
    this.player.setTime(time)
  }

  handleCheckIsAbsencing = async () => {
    await studentHelper.checkAbsence(({ isAbsencing, absenceTimes }) => {
      this.setState({ isAbsencing, absenceTimes })
    })
  }

  handleShowVacationAlert = () => {
    this.setState({
      vacationModalAlertVisible: true
    })
  }

  onGetBonus = () => {
    const { examId } = this.props.location.query
    const query = {
      pid: config.promotionPid,
      userId: utils.getOriginUserId(),
      productCode: `adaptation:${examId}`
    }
    const url = utils.genUrl(query, `${config.url.promotion}/p-sharingForBonus`)
    window.open(url)
  }

  handleReload = () => location.reload()

  computeMessageTitle = () => {
    this.messageShowTimer = setInterval(() => {
      let { messageShowTime } = this.state
      if (messageShowTime === 0) {
        clearInterval(this.messageShowTimer)
        this.messageShowTimer = null
      }
      this.setState({
        messageShowTime: --messageShowTime
      })
    }, 1000)
  }
  renderModals () {
    const { editContent, editVisible, feedbackVisible, isAbsencing, vacationModalAlertVisible } = this.state
    const isMobile = window.innerWidth <= 800 // eslint-disable-line no-magic-numbers
    let playerStyle: any = {}
    let tabsProps: any = {}
    let tabsStyle: any = {}
    if (isMobile) {
      playerStyle.height = window.innerWidth * 0.6 // eslint-disable-line no-magic-numbers
      tabsStyle.top = playerStyle.height
      tabsProps.activeKey = 'menu'
    }
    return <React.Fragment>
      <Modal
        title='编辑笔记'
        visible={editVisible}
        onOk={this.handleSubmitEdit}
        onCancel={this.handleCancel}
        wrapClassName='edit-note-modal'
      >
        <TextArea
          rows={5}
          value={editContent}
          placeholder='请输入你的笔记'
          onChange={e => this.setState({ editContent: e.target.value })}
        />
      </Modal>
      {feedbackVisible
        ? (
          <Feedback
            visible
            title={this.state.slice.name}
            currentTime={this.state.currentTime}
            userAccount={this.userAccount}
            onSubmit={this.handleSubmitFeedback}
            onClose={this.handleCloseFeedback}
          />
        )
        : null
      }
      <VacationModal
        isAbsencing={isAbsencing}
        visible={this.state.vacationModalVisible}
        handleOk={this.handleConfirmVacation}
        handleCancel={this.handleHideVacationModal}
      />
      {vacationModalAlertVisible
        ? (
          <CommonModal
            title='提示'
            visible={this.state.vacationModalAlertVisible}
            handleOk={() => this.setState({ vacationModalAlertVisible: false })}
            handleCancel={() => this.setState({ vacationModalAlertVisible: false })}
            header='您今年已累计请假3次或满90天，不可再请假'
            footer={null}
          />
        )
        : null
      }
    </React.Fragment>
  }

  renderTabs () {
    const { notes, questions, expand, isAbsencing } = this.state
    const isMobile = window.innerWidth <= 800 // eslint-disable-line no-magic-numbers
    let playerStyle: any = {}
    let tabsProps: any = {}
    let tabsStyle: any = {}
    if (isMobile) {
      playerStyle.height = window.innerWidth * 0.6 // eslint-disable-line no-magic-numbers
      tabsStyle.top = playerStyle.height
      tabsProps.activeKey = 'menu'
    }
    return <div style={tabsStyle} className={`tabs ${expand ? 'expand' : ''} ${(this.type === 'slice' && this.userType === USER_TYPE.TEACHER) ? 'no-menu' : ''}`}>
      <span onClick={this.toggleExpand} className='show-btn'><Icon type={`${expand ? 'right' : 'left'}`} /></span>
      <Tabs onChange={this.onTabChange} {...tabsProps} >
        <TabPane tab={<span>目录</span>} key='menu'>
          <Menu
            type={this.type}
            dates={this.state.dates}
            date={this.state.date}
            slice={this.state.slice}
            task={this.state.task}
            tasks={this.state.tasks}
            sectionIndex={this.state.sectionIndex}
            courses={this.state.courses}
            courseId={this.state.courseId}
            sections={this.state.sections}
            packages={this.state.packages}
            packageId={this.state.packageId}
            teachers={this.state.teachers}
            onTaskChange={this.onTaskChange}
            onSliceChange={this.onSliceChange}
            onCourseChange={this.onCourseChange}
            toggleSectionsOpen={this.toggleSectionsOpen}
            toggleCoursesOpen={this.toggleCoursesOpen}
            togglePackagesOpen={this.togglePackagesOpen}
            toggleDatesOpen={this.toggleDatesOpen}
            onPackageTeacherChange={this.onPackageTeacherChange}
            currentTime={this.state.currentTime}
            width={isMobile ? window.innerWidth : 320}
          />
        </TabPane>
        {this.type !== 'course' && (this.serveType === 4 || !this.isOnline)
          ? (
            <TabPane tab={<span>提问</span>} key='questions'>
              <Questions
                onSubmit={this.handleSubmit}
                taskId={this.state.task.taskId}
                currentTime={this.state.currentTime}
                isAbsencing={isAbsencing}
                questions={questions}
                onInput={this.onInput}
                onDelete={this.handleDelete}
                onReply={this.handleReply}
                onCheckIsAbsencing={this.handleCheckIsAbsencing}
                onSetTime={this.onSetTime}
                onVacation={this.handleVacation}
                disable={this.state.questionSubmitDisable}
                callback={this.fetchQuestions}
                isOnline={this.isOnline}
              />
            </TabPane>
          )
          : null
        }
        <TabPane tab={<span>笔记</span>} key='notes'>
          <Notes
            onSubmit={this.handleSubmit}
            taskId={this.state.task.taskId}
            currentTime={this.state.currentTime}
            isAbsencing={isAbsencing}
            notes={notes}
            onVacation={this.handleVacation}
            onInput={this.onInput}
            onDelete={this.handleDelete}
            onCheckIsAbsencing={this.handleCheckIsAbsencing}
            onSetTime={this.onSetTime}
            onEdit={this.handleEdit}
            disable={this.state.noteSubmitDisable}
            isOnline={this.isOnline}
          />
        </TabPane>
      </Tabs>
    </div>
  }

  handleWatchFromStart = async () => {
    await this.onReplay()
    if (this.messageShowTimer) {
      clearInterval(this.messageShowTimer)
      this.messageShowTimer = null
      this.setState({
        messageShowTime: 0
      })
    }
  }

  renderMessage = () => {
    let { messageShowTime, currentTime } = this.state
    // 不显示倒计时重新播放信息 1当前视频播放时间<10s，2计时器已经失效
    let isShow = currentTime < 10 || !this.messageShowTimer
    if (isShow) {
      return null
    }
    return <div className='show-message'>
      <span>已从最近一次处开始续播，点击<a onClick={this.handleWatchFromStart} href='javascript:;'> 重新开始播放 </a>（{`${messageShowTime}`} 秒）后自动消失</span>
    </div>
  }

  handleChangeResolution = async resolution => {
    const userId = utils.getUserId()
    const key = `playerMode-${userId}`
    const data = {
      value: resolution,
      business: 'smart-player'
    }
    const url = utils.genUrl(data, `/api/common-config/key/${key}`)
    const res = await rest.put(url, data)
    if (res.code !== 0) {
      message.error({
        text: '存储播放清晰度信息失败',
        msg: res.msg
      })
    }
    this.setState({ resolution })
  }

  render () {
    if (!this.state.ready) return <Loading />
    const { expand, autoPlayNext, isAbsencing, absenceTimes } = this.state
    const isMobile = window.innerWidth <= 800 // eslint-disable-line no-magic-numbers
    let playerStyle: any = {}
    let tabsProps: any = {}
    let tabsStyle: any = {}
    if (isMobile) {
      playerStyle.height = window.innerWidth * 0.6 // eslint-disable-line no-magic-numbers
      tabsStyle.top = playerStyle.height
      tabsProps.activeKey = 'menu'
    }
    return (
      <div className='container-common-online-player'>
        <Header
          onFeedback={this.onFeedback}
          onShare={this.onShare}
          onChange={this.handleAutoPlay}
          onVacation={this.handleVacation}
          onShowVacationAlert={this.handleShowVacationAlert}
          onReturn={this.handleReturn}
          title={this.state.slice.name}
          autoPlayNext={autoPlayNext}
          autoNextSwitchVisible={this.type !== 'slice'}
          hasPermission={this.state.hasPermission}
          isAbsencing={isAbsencing}
          absenceTimes={absenceTimes}
        />
        {this.state.hasPermission
          ? (
            <div className='body'>
              <div
                style={playerStyle}
                className={`player-video ${!expand ? 'expand' : ''}`}
              >
                <Player
                  ref={node => (this.player = node)}
                  slice={this.state.slice}
                  versions={this.state.versions}
                  organization={this.state.organization}
                  onUploadTime={this.onUploadTime}
                  onPlayerInit={this.onPlayerInit}
                  onPlayerWillFinish={this.onPlayerWillFinish}
                  onPlayerFinish={this.onPlayerFinish}
                  // onPlayerPause={this.onPlayerPause} // TODO: 不存在的函数，暂时注释掉
                  onChangeResolution={this.handleChangeResolution}
                  maskVisible={this.state.maskVisible}
                  resolution={this.state.resolution}
                  onGetBonus={this.onGetBonus}
                  renderVideoTopMessage={this.renderMessage}
                />
                <VideoMask
                  nextName={this.state.nextSlice.name}
                  onReplay={this.onReplay}
                  onPlayNext={this.onPlayNext}
                  visible={this.state.maskVisible}
                  thisName={this.state.slice.name}
                />
              </div>
              {this.renderTabs()}
              {this.type === 'course' && this.renderMessage()}
            </div>
          )
          : (
            <div style={videoPermissionStyle} className='video-hasPermission'>
              <VideoHasPermission txt={this.state.hasPermissionText} />
            </div>
          )
        }
        {this.renderModals()}
      </div>
    )
  }
}

export default OnlinePlayer
