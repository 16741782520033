import * as React from 'react'

const getDeg = percent => {
  if (percent < 50) {
    return percent * 180 / 50 - 180
  }
  return (percent - 50) * 180 / 50
}

// 0 -180
// 25 -90

class PercentRing extends React.Component<any, any> {
  render () {
    if (this.props.percent < 50) {
      return (
        <div className='component-common-percent-ring component-common-percent-ring-low'>
          <div />
          <div style={{ transform: `rotate(${getDeg(this.props.percent)}deg)` }} />
          <div />
        </div>
      )
    }
    return (
      <div className='component-common-percent-ring component-common-percent-ring-high'>
        <div />
        <div style={{ transform: `rotate(${getDeg(this.props.percent)}deg)` }} />
        <div />
      </div>
    )
  }
}

export default PercentRing
