import moment from 'moment-timezone'
import { dispatch } from '../common/redux'
import * as semver from 'semver'

const ONE_MINUTE = 60 * 1000

let lastCheckTime = 0
let needUpdate = false

/**
 * 检查是否有新版本，并更新redux
 * @param {object} versionInfo 版本信息
 * @param {string} versionInfo.version 版本
 * @param {number} versionInfo.buildtime 发布时间
 * @return {Boolean} 是否有新版本
 */
export function checkVersionUpdate (versionInfo: any = {}) {
  // 大多数情况下，版本一致。所以优先判断
  if (versionInfo.version === global.CLIENT_VERSION) {
    return false
  }

  if (needUpdate) return true // 如果已经检测到需要更新版本，则无需再次检测

  // 使用lastCheckTime来避免频繁处理
  const curTime = new Date().getTime()
  if (lastCheckTime + ONE_MINUTE > curTime) {
    return false
  }
  lastCheckTime = curTime
  if (!semver.valid(versionInfo.version) || !moment(versionInfo.buildtime).isValid()) return false
  needUpdate = !semver.valid(global.CLIENT_VERSION) ||
  (
    (
      semver.gt(versionInfo.version, global.CLIENT_VERSION) &&
      ['major', 'minor'].includes(semver.diff(versionInfo.version, global.CLIENT_VERSION))
    ) ||
    versionInfo.buildtime > global.BUILD_TIME
  )
  if (needUpdate) {
    // 设置强制更新时间为10分钟后
    const forceUpdateTime = moment().add(10, 'minutes').toDate()

    // 因为版本的更新只会通过刷新进行，而刷新的时候各种数据都会重置。所以只需要设置needUpdate: true，不需要设置needUpdate: false
    dispatch({
      type: 'VERSION.UPDATE',
      version: {
        needUpdate: true,
        latestVersion: versionInfo.version,
        forceUpdateTime
      }
    })
  }
  return needUpdate
}
