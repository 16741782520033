import moment from 'moment-timezone'
import * as React from 'react'

const createReactClass = require('create-react-class')
// 组件
const Datetime = createReactClass({
  // 状态初始化
  getInitialState () {
    let placeholder = this.props.placeholder || ''
    return {
      placeholder,
      text: placeholder,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate(),
      hour: new Date().getHours(),
      minute: new Date().getMinutes(),
      second: new Date().getSeconds(),
      day: new Date().getDay(),
      isShowed: false
    }
  },
  year: React.createRef<any>(),
  month: React.createRef<any>(),
  hour: React.createRef<any>(),
  minute: React.createRef<any>(),
  second: React.createRef<any>(),

  // 加载后
  componentDidMount () {
    this.update()
  },

  // 更新后
  componentDidUpdate () {
    this.update()
  },

  // 加载或更新后
  update () {
    let defaultValue = ''
    let text = this.state.placeholder
    let date = new Date()
    if (this.props.defaultValue) {
      if (this.props.type.indexOf('date') === -1) {
        date = new Date(`2000/01/01 ${this.props.defaultValue.replace(/-/g, '/')}`)
      } else
      if (this.props.type.indexOf('time') === -1) {
        date = new Date(`${this.props.defaultValue.replace(/-/g, '/')} 00:00:00`)
      } else {
        date = new Date(this.props.defaultValue.replace(/-/g, '/'))
      }

      text = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let theDate = date.getDate()
      let hour = date.getHours()
      let minute = date.getMinutes()
      let second = date.getSeconds()
      if (this.props.type.indexOf('date') !== -1) {
        text += year
        text += '/'
        text += month < 10 ? `0${month}` : month
        text += '/'
        text += theDate < 10 ? `0${theDate}` : theDate
      }
      if (this.props.type.indexOf('datetime') !== -1) {
        text += ' '
      }
      if (this.props.type.indexOf('time') !== -1) {
        text += hour < 10 ? `0${hour}` : hour
        text += ':'
        text += minute < 10 ? `0${minute}` : minute
        text += ':'
        text += second < 10 ? `0${second}` : second
      }

      defaultValue = text
    }

    if (this.state.lastDefaultValue !== defaultValue) {
      this.setState({
        lastDefaultValue: defaultValue,
        text,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        date: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
        day: date.getDay()
      })
    }
  },

  renderDate () {
    let self = this
    let { year, month, date } = this.state
    let dates = this.calcDates(year, month)
    return <div className='date' style={{ display: this.props.type.indexOf('date') === -1 ? 'none' : 'block' }}>
      <div className='header'>
        <span className='fa fa-chevron-circle-left' onClick={self.changeToPrevMonth} />
        {(function () {
          let cntYear = new Date().getFullYear()
          let years = []
          for (let i = -5; i < 5 + (self.props.startYear ? cntYear - self.props.startYear : 0); i++) {
            years.push(<option key={i} value={cntYear - i}>{cntYear - i}年</option>)
          }

          return (
            <select ref={self.year} value={year} onChange={self.changeDate}>
              {years}
            </select>
          )
        }())}

        {(function () {
          let months = []
          for (let i = 0; i < 12; i++) {
            months.push(<option key={i} value={i + 1}>{i + 1}月</option>)
          }

          return (
            <select ref={self.month} value={month} onChange={self.changeDate}>
              {months}
            </select>
          )
        }())}
        <span className='fa fa-chevron-circle-right' onClick={self.changeToNextMonth} />
      </div>
      <div className='body'>
        <div className='row'>
          <span>一</span>
          <span>二</span>
          <span>三</span>
          <span>四</span>
          <span>五</span>
          <span>六</span>
          <span>日</span>
        </div>
        {dates.map(function (row) {
          return (
            <div key={`${year}-${month}-${row[0].date}-${row[0].available}`} className='row'>
              {row.map(function (column) {
                return (
                  <span
                    key={`col-${year}-${month}-${column.date}`}
                    className={
                      (column.available ? '' : 'unavailable') +
                      (column.available && date === column.date ? 'active' : '')
                    }
                    onClick={self.chooseDate(column)}
                  >
                    {column.date}
                  </span>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  },

  renderTime () {
    let self = this
    let { hour, minute, second } = this.state
    return <div className='time' style={{ display: this.props.type.indexOf('time') === -1 ? 'none' : 'block' }}>
      {(function () {
        let hours = []
        for (let i = 0; i < 24; i++) {
          hours.push(<option key={i} value={i}>{i < 10 ? `0${i}` : i}时</option>)
        }

        return (
          <select ref={`${self.props.id}hour`} value={hour} onChange={self.changeTime}>
            {hours}
          </select>
        )
      }())}

      {(function () {
        let minutes = []
        for (let i = 0; i < 60; i++) {
          minutes.push(<option key={i} value={i}>{i < 10 ? `0${i}` : i}分</option>)
        }

        return (
          <select ref={`${self.props.id}minute`} value={minute} onChange={self.changeTime}>
            {minutes}
          </select>
        )
      }())}

      {!this.props.hideSec && (function () {
        let seconds = []
        for (let i = 0; i < 60; i++) {
          seconds.push(<option key={i} value={i}>{i < 10 ? `0${i}` : i}秒</option>)
        }

        return (
          <select ref={`${self.props.id}second`} value={second} onChange={self.changeTime}>
            {seconds}
          </select>
        )
      }())}
    </div>
  },

  // 渲染
  render () {
    let self = this
    let { year, month } = this.state

    const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
    if (this.state.date > daysInMonth) {
      this.setState({ date: daysInMonth })
    }

    return (
      <div className={`component-common-form-datetime component-common-form-datetime-${this.props.style}`}>
        <div className='text' onClick={this.toggle}>
          <div
            className='title'
            style={{ display: this.props.title ? 'inline-block' : 'none' }}
          >
            {this.props.title}
          </div>
          <span className='option'>
            {this.state.text}
            {this.props.week && this.state.text !== this.state.placeholder ? `（周${['日', '一', '二', '三', '四', '五', '六'][self.state.day]}）` : ''}
          </span>
          <span
            className='fa fa-angle-down'
            style={{ display: this.props.readOnly ? 'none' : 'inline-block' }}
          />
        </div>

        <div className='content' style={{ display: this.state.isShowed ? 'block' : 'none' }}>
          {this.renderDate()}
          {this.renderTime()}
          <div className='confirm' onClick={this.confirm}>确定</div>
          <div className='clear' onClick={this.clear} style={{ display: self.props.noClear ? 'none' : 'block' }}>清空</div>
        </div>

        <div
          className='wrapper'
          style={{ display: this.state.isShowed ? 'block' : 'none' }}
          onClick={this.toggle}
        />
      </div>
    )
  },

  // 选择日期
  chooseDate (date) {
    let self = this

    return function () {
      if (!date.available) return
      self.setState({
        date: date.date
      })
    }
  },

  // 改变年月
  changeDate () {
    this.setState({
      year: parseInt(this.year.current.value),
      month: parseInt(this.month.current.value)
    })
  },

  // 改变时间
  changeTime () {
    let hour = this.refs[`${this.props.id}hour`].value
    let minute = this.refs[`${this.props.id}minute`].value
    let secondRef = this.refs[`${this.props.id}second`]
    this.setState({
      hour: parseInt(hour),
      minute: parseInt(minute),
      second: (secondRef && parseInt(secondRef.value)) || '00'
    })
  },

  // 切换到上个月
  changeToPrevMonth () {
    this.setState({
      year: this.state.month === 1 ? this.state.year - 1 : this.state.year,
      month: this.state.month === 1 ? 12 : this.state.month - 1
    })
  },

  // 切换到下个月
  changeToNextMonth () {
    this.setState({
      year: this.state.month === 12 ? this.state.year + 1 : this.state.year,
      month: this.state.month === 12 ? 1 : this.state.month + 1
    })
  },

  // 计算日历表
  calcDates (year, month) {
    let dates = []

    // 获取本月和上月天数
    let totDays = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
    let totDaysLastMonth = moment(`${year}-${month}`, 'YYYY-MM').subtract(1, 'month').daysInMonth()

    // 获取本月第一天是星期几
    let firstDay = new Date(`${year}/${month < 10 ? `0${month}` : month}/01`).getDay()
    firstDay = firstDay - 1 < 0 ? 6 : firstDay - 1

    // 计算每一天的日期
    let cntDay = 0
    let available = true
    for (let i = 0; i < 6; i++) {
      dates.push([])

      if (i === 0) {
        for (let j = firstDay - 1; j >= 0; j--) {
          dates[i].push({
            available: false,
            date: totDaysLastMonth - j
          })
        }

        for (let j = firstDay; j < 7; j++) {
          dates[i].push({
            available: true,
            date: ++cntDay
          })
        }
      } else {
        for (let j = 0; j < 7; j++) {
          dates[i].push({
            available,
            date: ++cntDay
          })

          if (cntDay >= totDays) {
            cntDay = 0
            available = false
          }
        }
      }
    }

    if (this.props.lastDate) {
      for (let i = 0; i < dates.length; i++) {
        for (let j = 0; j < dates[i].length; j++) {
          if (dates[i][j].available && new Date(`${year}/${month}/${dates[i][j].date}`) > this.props.lastDate) {
            dates[i][j].available = false
          }
        }
      }
    }

    // 返回
    return dates
  },

  // 确定选择
  confirm () {
    let text = ''
    if (this.props.type.indexOf('date') !== -1) {
      text += this.state.year
      text += '/'
      text += this.state.month < 10 ? `0${this.state.month}` : this.state.month
      text += '/'
      text += this.state.date < 10 ? `0${this.state.date}` : this.state.date
    }
    if (this.props.type.indexOf('datetime') !== -1) {
      text += ' '
    }
    if (this.props.type.indexOf('time') !== -1) {
      text += this.state.hour < 10 ? `0${this.state.hour}` : this.state.hour
      text += ':'
      text += this.state.minute < 10 ? `0${this.state.minute}` : this.state.minute
      if (!this.props.hideSec) {
        text += ':'
        text += this.state.second < 10 ? `0${this.state.second}` : this.state.second
      }
    }

    this.setState({
      text,
      day: new Date(text).getDay(),
      isShowed: false
    })

    if (this.props.onChange) {
      let self = this
      setTimeout(function () {
        self.props.onChange(text)
      }, 10)
    }
  },

  // 清空
  clear () {
    this.setState({
      text: this.state.placeholder,
      isShowed: false
    })

    if (this.props.onChange) {
      let self = this
      setTimeout(function () {
        self.props.onChange(self.getValue())
      }, 10)
    }
  },

  // 切换显示
  toggle () {
    if (this.state.isShowed) {
      this.setState({ isShowed: false })
    } else {
      this.setState({
        isShowed: true
      })
    }
  },

  // 获取值
  getValue () {
    return this.state.text === this.state.placeholder ? '' : this.state.text
  }
})

export default Datetime
