import * as rest from 'common/rest'

function receiveProfile (profile) {
  return {
    type: 'PROFILE.FETCH',
    profile
  }
}

function fetchProfile (userId) {
  return async (dispatch, getState) => {
    const url = `/api/student?id=${userId}`
    const res = await rest.get(url)
    if (res.code === 0) {
      return dispatch(receiveProfile(res.data))
    }
    return {}
  }
}

export {
  fetchProfile
}
