export default (state = {}, action) => {
  switch (action.type) {
    case 'PROFILE.FETCH':
      return {
        data: action.profile
      }
    default:
      return state
  }
}
