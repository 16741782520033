import * as utils from 'common/utils'
import socket from 'common/socket'
import constants from 'common/constants'

const GROUP_ROOM_PREFIX = {
  1: 'student',
  2: 'staff',
  3: 'admin'
}

export function updateSocketAuthorization (isJoin = true) {
  const data: any = {}
  for (const key of ['userId', 'originUserId', 'organizationId', 'userTypeId']) {
    data[key] = parseInt(utils.getCookie(key), 10)
  }
  const groupRoomPrefix = GROUP_ROOM_PREFIX[data.userTypeId]
  const groupRoom = `${groupRoomPrefix}:${data.userId}`
  const userRoom = `user:${data.originUserId}`
  const studentRoom = `student:${data.userId}`
  const organizationRoom = `organization:${data.organizationId}`
  const msg = constants.WEBSOCKET_MSG[isJoin ? 'join' : 'leave']
  socket.emit(
    msg,
    {
      rooms: [groupRoom, userRoom, studentRoom, organizationRoom]
    },
    (resp) => {
      if (resp && resp.code === 0) {
        utils.setLocalStorage('socket-rooms', resp.data)
      }
    }
  )
}
