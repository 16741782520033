
const initialState = {
  config: {},
  document: {},
  documents: {},
  course: {},
  courses: {},
  courseSections: {},
  organization: {},
  organizations: {},
  examinations: {},
  subjects: {},
  lovs: {},
  tags: {},
  holidays: {},
  products: {},
  crmBranchCompanies: {},
  staffs: {},
  admins: {}
}

export default (state = initialState, action) => {
  if (action.type === 'PLATFORM.AUTO_FETCHING') {
    let newState = Object.assign({}, state)
    newState[action.secondLevel][action.key] = {
      v: Math.random(),
      completed: action.completed,
      data: action.data
    }

    return newState
  }

  return state
}
