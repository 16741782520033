import moment from 'moment-timezone'
import * as _ from 'lodash'
import * as utils from 'common/utils'
import * as React from 'react'
import { Popover, Spin, message } from 'antd'
import * as messageHelper from 'helpers/message'
import * as rest from 'common/rest'
import { Link, withRouter } from 'react-router-dom'

class Messages extends React.Component<any, any> {
  state = {
    messagesList: [],
    messagesListVisible: false,
    visible: false,
    loading: true,
    firstCount: true,
    messageCount: 0
  }

  componentDidMount () {
    this.getMessagesCount()
  }

  async getMessagesCount () {
    const count = await messageHelper.getUnreadMessageCount()
    this.setState({
      messageCount: count,
      firstCount: false
    })
  }

  async generateMessagesList () {
    const url = '/api/messages?status=1&pageSize=10&page=1'
    const res = await rest.get(url)
    if (res.code) return message.error({ text: '获取消息列表失败', msg: res.msg })
    this.setState({
      messagesList: res.data.messages,
      messagesListVisible: true,
      loading: false
    })
  }

  handleMarkAndRedirect (e) {
    if (e.target.getAttribute('data-not-go')) {
      this.setState({
        visible: false
      })
    } else {
      this.props.history.push({ pathname: '/school/messages/my-messages' })
      this.setState({
        visible: false
      })
    }
  }

  getContents () {
    const { messagesList, loading } = this.state
    let arr = []
    if (messagesList.length > 4) {
      arr = messagesList.slice(0, 4)
    } else {
      arr = messagesList
    }
    if (loading) {
      return <Spin />
    }
    if (arr.length === 0) {
      return (<div className='messages-list-outer'>
        <div className='no-messages-container'>
          <p className='no-messages-text'>
            暂无未读消息
          </p>
        </div>
        <div className='messages-out-link'>
          <Link onClick={() => this.setState({ visible: false })} to='/school/messages/my-messages'>查看全部消息提醒</Link>
        </div>
      </div>)
    }
    return (
      <div className='messages-list-outer'>
        <ul className='messages-list'>
          {
            arr.map((item, index) => (
              <li onClick={e => this.handleMarkAndRedirect(e)} className={`message-item ${arr.length === index + 1 ? 'no-border-bottom' : ''}`} key={item.id}>
                <p className='message-type'>
                  <span className='chinese-bracket'>【</span>
                  系统消息
                  <span className='chinese-bracket'>】</span>
                  <span dangerouslySetInnerHTML={{ __html: utils.sliceStringWithEllipsis(item.title, 60) }} />
                </p>
                <p className='message-trigger'>
                  <span>{moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss')}</span>
                  <span style={{ paddingLeft: 5 }}>
                    {item.from === null ? null : `来自${item.from.name}${item.from.account ? `-${item.from.account}` : ''}`}
                  </span>
                </p>
                {
                  item.actions && item.actions.length
                    ? <p className='message-operation'>{item.actions.map((action, index) => (
                      <React.Fragment key={index}>
                        {messageHelper.generateMessageLink(action)}
                      </React.Fragment>
                    ))}</p>
                    : <p className='message-operation' />
                }
              </li>
            ))
          }
        </ul>
        <div className='messages-out-link'>
          <Link to='/school/messages/my-messages' onClick={() => this.setState({ visible: false })}>查看全部消息提醒</Link>
        </div>
      </div>
    )
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }

  generateMessagesCountBlock (count) {
    const text = count.count > 99 ? '99+' : count.count
    if (text === '0') {
      return null
    }
    return <span className='messages-count'>{text}</span>
  }

  render () {
    const { messagesCount } = this.props
    const { messageCount } = this.state
    return (
      <Popover
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        overlayClassName='messages-popover-container'
        content={this.getContents()}
        placement='bottomRight'
        trigger='hover'
      >
        <div className='messages-container'>
          <Link
            style={{ color: '#fff' }}
            to={'/school/messages/my-messages'}
            className='messages-link'
            onMouseEnter={() => this.generateMessagesList()}
          />
          {
            this.generateMessagesCountBlock(_.isEmpty(messagesCount) ? { count: messageCount } : messagesCount)
          }
        </div>
      </Popover>
    )
  }
}

export default withRouter(Messages)
