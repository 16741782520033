import * as React from 'react'
// 容器
class CommonLoading extends React.Component<any, any> {
  // 渲染
  render () {
    return (
      <div className='loading-position'>
        <div className={`line-scale-pulse-out ${this.props.type === 'white' ? 'white-loading' : ''}`}>
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}

export default CommonLoading
