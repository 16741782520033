import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import reducers from '../reducers'

const middlewares = [thunkMiddleware]
if (process.env.NODE_ENV === 'development') {
  console.log('==================')
  console.log('is development')
  console.log('==================')
  middlewares.push(createLogger())
}

export const store = createStore(reducers, undefined, compose(applyMiddleware(...middlewares), window['devToolsExtension'] ? window['devToolsExtension']() as () => void : f => f))
export const { dispatch } = store
