export default (state = null, action) => {
  switch (action.type) {
    case 'PERMISSION.FETCH':
      return {
        permission: action.permissionModule
      }
    default:
      return state
  }
}
